/**
 * @author mrdoob / http://mrdoob.com/
 * This importer is based on the threejs one but have some specific modifications for our use
 * DO NOT REPLACE IT
 */

var SAVANE = SAVANE || {};
SAVANE.BINLoader = (function() {

    // o object_name | g group_name
    var object_pattern = /^[og]\s*(.+)?/;
    // mtllib file_reference
    var material_library_pattern = /^mtllib /;
    // usemtl material_name
    var material_use_pattern = /^usemtl /;

    function ParserState() {

        var state = {
            objects: [],
            object: {},

            vertices: [],
            normals: [],
            colors: [],
            uvs: [],

            materialLibraries: [],

            startObject: function(name, fromDeclaration) {

                // If the current object (initial from reset) is not from a g/o declaration in the parsed
                // file. We need to use it for the first parsed g/o to keep things in sync.
                if (this.object && this.object.fromDeclaration === false) {

                    this.object.name = name;
                    this.object.fromDeclaration = (fromDeclaration !== false);
                    return;

                }

                var previousMaterial = (this.object && typeof this.object.currentMaterial === 'function' ? this.object.currentMaterial() : undefined);

                if (this.object && typeof this.object._finalize === 'function') {

                    this.object._finalize(true);

                }

                this.object = {
                    name: name || '',
                    fromDeclaration: (fromDeclaration !== false),

                    geometry: {
                        vertices: [],
                        normals: [],
                        colors: [],
                        uvs: []
                    },
                    materials: [],
                    smooth: true,

                    startMaterial: function(name, libraries) {

                        var previous = this._finalize(false);

                        // New usemtl declaration overwrites an inherited material, except if faces were declared
                        // after the material, then it must be preserved for proper MultiMaterial continuation.
                        if (previous && (previous.inherited || previous.groupCount <= 0)) {

                            this.materials.splice(previous.index, 1);

                        }

                        var material = {
                            index: this.materials.length,
                            name: name || '',
                            mtllib: (Array.isArray(libraries) && libraries.length > 0 ? libraries[libraries.length - 1] : ''),
                            smooth: (previous !== undefined ? previous.smooth : this.smooth),
                            groupStart: (previous !== undefined ? previous.groupEnd : 0),
                            groupEnd: - 1,
                            groupCount: - 1,
                            inherited: false,

                            clone: function(index) {

                                var cloned = {
                                    index: (typeof index === 'number' ? index : this.index),
                                    name: this.name,
                                    mtllib: this.mtllib,
                                    smooth: this.smooth,
                                    groupStart: 0,
                                    groupEnd: - 1,
                                    groupCount: - 1,
                                    inherited: false
                                };
                                cloned.clone = this.clone.bind(cloned);
                                return cloned;

                            }
                        };

                        this.materials.push(material);

                        return material;

                    },

                    currentMaterial: function() {

                        if (this.materials.length > 0) {

                            return this.materials[this.materials.length - 1];

                        }

                        return undefined;

                    },

                    _finalize: function(end) {

                        var lastMultiMaterial = this.currentMaterial();
                        if (lastMultiMaterial && lastMultiMaterial.groupEnd === - 1) {

                            lastMultiMaterial.groupEnd = this.geometry.vertices.length / 3;
                            lastMultiMaterial.groupCount = lastMultiMaterial.groupEnd - lastMultiMaterial.groupStart;
                            lastMultiMaterial.inherited = false;

                        }

                        // Ignore objects tail materials if no face declarations followed them before a new o/g started.
                        if (end && this.materials.length > 1) {

                            for (var mi = this.materials.length - 1; mi >= 0; mi--) {

                                if (this.materials[mi].groupCount <= 0) {

                                    this.materials.splice(mi, 1);

                                }

                            }

                        }

                        // Guarantee at least one empty material, this makes the creation later more straight forward.
                        if (end && this.materials.length === 0) {

                            this.materials.push({
                                name: '',
                                smooth: this.smooth
                            });

                        }

                        return lastMultiMaterial;

                    }
                };

                // Inherit previous objects material.
                // Spec tells us that a declared material must be set to all objects until a new material is declared.
                // If a usemtl declaration is encountered while this new object is being parsed, it will
                // overwrite the inherited material. Exception being that there was already face declarations
                // to the inherited material, then it will be preserved for proper MultiMaterial continuation.

                if (previousMaterial && previousMaterial.name && typeof previousMaterial.clone === 'function') {

                    var declared = previousMaterial.clone(0);
                    declared.inherited = true;
                    this.object.materials.push(declared);

                }

                this.objects.push(this.object);

            },

            finalize: function() {
                if (this.object && typeof this.object._finalize === 'function') {
                    this.object._finalize(true);
                }
            },

            parseVertexIndex: function(value, len) {
                var index = value;
                return (index >= 0 ? index - 1 : index + len / 3) * 3;
            },

            parseNormalIndex: function(value, len) {
                var index = value;
                return (index >= 0 ? index - 1 : index + len / 3) * 3;
            },

            parseUVIndex: function(value, len) {
                var index = value;
                return (index >= 0 ? index - 1 : index + len / 2) * 2;
            },

            addVertex: function(a, b, c) {
                var src = this.vertices;
                var dst = this.object.geometry.vertices;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);
                dst.push(src[b + 0], src[b + 1], src[b + 2]);
                dst.push(src[c + 0], src[c + 1], src[c + 2]);
            },

            addVertexPoint: function(a) {
                var src = this.vertices;
                var dst = this.object.geometry.vertices;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);
            },

            addVertexLine: function(a) {
                var src = this.vertices;
                var dst = this.object.geometry.vertices;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);
            },

            addNormal: function(a, b, c) {
                var src = this.normals;
                var dst = this.object.geometry.normals;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);
                dst.push(src[b + 0], src[b + 1], src[b + 2]);
                dst.push(src[c + 0], src[c + 1], src[c + 2]);
            },

            addColor: function(a, b, c) {
                var src = this.colors;
                var dst = this.object.geometry.colors;

                dst.push(src[a + 0], src[a + 1], src[a + 2]);
                dst.push(src[b + 0], src[b + 1], src[b + 2]);
                dst.push(src[c + 0], src[c + 1], src[c + 2]);
            },

            addUV: function(a, b, c) {
                var src = this.uvs;
                var dst = this.object.geometry.uvs;

                dst.push(src[a + 0], src[a + 1]);
                dst.push(src[b + 0], src[b + 1]);
                dst.push(src[c + 0], src[c + 1]);
            },

            addUVLine: function(a) {
                var src = this.uvs;
                var dst = this.object.geometry.uvs;

                dst.push(src[a + 0], src[a + 1]);
            },

            addFace: function(a, ua, na, b, ub, nb, c, uc, nc) {
                var vLen = this.vertices.length;

                var ia = this.parseVertexIndex(a, vLen);
                var ib = this.parseVertexIndex(b, vLen);
                var ic = this.parseVertexIndex(c, vLen);

                this.addVertex(ia, ib, ic);

                if (ua !== undefined && ua !== '') {
                    var uvLen = this.uvs.length;
                    ia = this.parseUVIndex(ua, uvLen);
                    ib = this.parseUVIndex(ub, uvLen);
                    ic = this.parseUVIndex(uc, uvLen);
                    this.addUV(ia, ib, ic);
                }
                // INTERNAL FIX BY Nicolas and Eric or this breaks obj that are only partly textured
                else {
                    if (this.uvs.length > 0) {
                        this.addUV(0, 0, 0);
                    }
                }
                // END INTERNAL FIX

                if (na !== undefined && na !== '') {
                    // Normals are many times the same. If so, skip function call and parseInt.
                    var nLen = this.normals.length;
                    ia = this.parseNormalIndex(na, nLen);
                    ib = na === nb ? ia : this.parseNormalIndex(nb, nLen);
                    ic = na === nc ? ia : this.parseNormalIndex(nc, nLen);
                    this.addNormal(ia, ib, ic);
                }

                if (this.colors.length > 0) {
                    this.addColor(ia, ib, ic);
                }
            },

            addPointGeometry: function(vertices) {
                this.object.geometry.type = 'Points';

                var vLen = this.vertices.length;

                for (var vi = 0, l = vertices.length; vi < l; vi++) {
                    this.addVertexPoint(this.parseVertexIndex(vertices[vi], vLen));
                }
            },

            addLineGeometry: function(vertices, uvs) {
                this.object.geometry.type = 'Line';

                var vLen = this.vertices.length;
                var uvLen = this.uvs.length;

                for (var vi = 0, l = vertices.length; vi < l; vi++) {
                    this.addVertexLine(this.parseVertexIndex(vertices[vi], vLen));
                }

                for (var uvi = 0, l = uvs.length; uvi < l; uvi++) {
                    this.addUVLine(this.parseUVIndex(uvs[uvi], uvLen));
                }
            }
        };

        state.startObject('', false);

        return state;
    }

    //

    function BINLoader(manager) {
        this.manager = (manager !== undefined) ? manager : THREE.DefaultLoadingManager;

        this.materials = null;
    }

    BINLoader.prototype = {

        constructor: BINLoader,

        load: function(url, onLoad, onProgress, onError) {
            var scope = this;

            var loader = new THREE.FileLoader(scope.manager);
            loader.setPath(this.path);
            loader.load(url, function(text) {

                onLoad(scope.parse(text));

            }, onProgress, onError);
        },

        setPath: function(value) {
            this.path = value;

            return this;
        },

        setMaterials: function(materials) {
            this.materials = materials;

            return this;
        },

        parse: function(bin) {

            console.time('BINLoader');

            // Usefull functions to get shorts and ints
            function getInt16(lowerbyte, upperbyte) {
                var sign = upperbyte & 0x80;
                var value = (upperbyte & 0x7F) << 8 | (lowerbyte);

                if (sign !== 0) {
                    value = -32768 + value;
                }
                return (value);
            };

            function getUInt16(lowerbyte, upperbyte) {
                var value = (upperbyte & 0xFF) << 8 | (lowerbyte);

                return (value);
            };

            function getInt32(lowerbyte, lowbyte, upbyte, upperbyte) {
                var sign = upperbyte & 0x80;
                var value = (upperbyte & 0x7F) << 24 | (upbyte << 16) | (lowbyte << 8) | (lowerbyte);

                if (sign !== 0) {
                    value = -2147483648 + value;
                }
                return (value);
            };

            function isCompressed(filename) {
                var filenameSplit = filename.split('.');

                if (filenameSplit[filenameSplit.length - 1] === 'dds') {
                    return(true);
                }

                return(false);
            }

            var state = new ParserState();

            var mtlFile = "";
            var imgTable = [];

            var idx = 0;

            // While not EOF
            while (idx < bin.length) {
                // Switch comand
                switch (bin.charCodeAt(idx++) & 0xFF) {
                    // L mat library
                    case 76:
                        // Size of the mat lib name
                        var matLibSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8);
                        state.object.startMaterial(bin.substr(idx, matLibSize), state.materialLibraries);
                        // Skip mat lib name
                        idx += matLibSize;
                        break;

                    // V vertices
                    case 86:
                        // Get vertex data size (2 or 4)
                        var dataSize = (bin.charCodeAt(idx++) & 0xFF);
                        // Get number of following vertices (int value)
                        var nbVertices = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8) | ((bin.charCodeAt(idx++) & 0xFF) << 16) | ((bin.charCodeAt(idx++) & 0xFF) << 24);

                        // Copy all vertices to current object
                        if (dataSize === 2) {
                            for (var v = 0; v < nbVertices; v++) {
                                state.vertices.push(getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)));
                            }
                        }
                        else {
                            for (var v = 0; v < nbVertices; v++) {
                                state.vertices.push(getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)));
                            }
                        }
                        break;

                    // N normales
                    case 78:
                        // Get normal data size (2 or 4)
                        var dataSize = (bin.charCodeAt(idx++) & 0xFF);
                        // Get number of following normals (int value)
                        var nbNormales = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8) | ((bin.charCodeAt(idx++) & 0xFF) << 16) | ((bin.charCodeAt(idx++) & 0xFF) << 24);

                        // Copy all normals to current object
                        if (dataSize === 2) {
                            for (var n = 0; n < nbNormales; n++) {
                                state.normals.push(getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000);
                            }
                        }
                        else {
                            for (var n = 0; n < nbNormales; n++) {
                                state.normals.push(getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000);
                            }
                        }
                        break;

                    // T textures
                    case 84:
                        // Get uv data size (2 or 4)
                        var dataSize = (bin.charCodeAt(idx++) & 0xFF);
                        // Get number of following uv coords (int value)
                        var nbTextureCoords = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8) | ((bin.charCodeAt(idx++) & 0xFF) << 16) | ((bin.charCodeAt(idx++) & 0xFF) << 24);

                        // Copy all uv coords to current object
                        if (dataSize === 2) {
                            for (var t = 0; t < nbTextureCoords; t++) {
                                state.uvs.push(getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000);
                            }
                        }
                        else {
                            for (var t = 0; t < nbTextureCoords; t++) {
                                state.uvs.push(getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000,
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)) / 1000);
                            }
                        }
                        break;

                    // S smooth
                    case 83:
                        // Retrieve smooth value and assign to current material
                        state.object.smooth = ((bin.charCodeAt(idx++) & 0xFF) !== 0);

                        var material = state.object.currentMaterial();
                        if (material) {
                            material.smooth = state.object.smooth;
                        }
                        break;

                    // O and G object start
                    case 71:
                    case 79:
                        // Size of the object name
                        var nameSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8);
                        state.startObject(bin.substr(idx, nameSize));
                        // Skip the object name
                        idx += nameSize;
                        break;

                    // M material
                    case 77:
                        // Size of the material name
                        var nameSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8);
                        state.object.startMaterial(bin.substr(idx, nameSize), state.materialLibraries);
                        // Skip material name
                        idx += nameSize;
                        break;

                    // F face
                    case 70:
                        // Get vertex data size (2 or 4)
                        var dataSize = (bin.charCodeAt(idx++) & 0xFF);
                        // Get number of following faces (int value)
                        var nbFaces = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8) | ((bin.charCodeAt(idx++) & 0xFF) << 16) | ((bin.charCodeAt(idx++) & 0xFF) << 24);

                        // Copy all faces to current object
                        if (dataSize === 2) {
                            for (var f = 0; f < nbFaces; f++) {
                                state.addFace(
                                    getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)), getUInt16((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF))
                                );
                            }
                        }
                        else {
                            for (var f = 0; f < nbFaces; f++) {
                                state.addFace(
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF)),
                                    getInt32((bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF), (bin.charCodeAt(idx++) & 0xFF))
                                );
                            }
                        }
                        break;

                    // E mtl extra file
                    case 69:
                        // Size of the mtl file
                        var mtlSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8);
                        mtlFile = bin.substr(idx, mtlSize);
                        // Skip file mtl file
                        idx += mtlSize;
                        break;

                    // I image
                    case 73:
                        // Size of the filename
                        var nameSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8);
                        var imgName = bin.substr(idx, nameSize);
                        // Skip file name size
                        idx += nameSize;

                        // Get image file size
                        var imgSize = (bin.charCodeAt(idx++) & 0xFF) | ((bin.charCodeAt(idx++) & 0xFF) << 8) | ((bin.charCodeAt(idx++) & 0xFF) << 16) | ((bin.charCodeAt(idx++) & 0xFF) << 24);
                        var data;

                        // If compressed image then create an array buffer to manipulate data later
                        if (isCompressed(imgName)) {
                            var arrayBuffer = new ArrayBuffer(imgSize);
                            var view = new Int8Array(arrayBuffer);

                            for (var i = 0 ; i < imgSize ; i++) {
                                view[i] = bin.charCodeAt(idx++) & 0xFF;
                            }
                            data = arrayBuffer;
                        } else {
                            // Else copy brutally the text with a substring
                            data = bin.substr(idx, imgSize);
                            // Skip image data
                            idx += imgSize;
                        }

                        imgTable.push({ name: imgName, data: data });
                        break;

                    default:
                        console.log('Invalid command at index ' + idx);
                }
            }

            state.finalize();

            var container = new THREE.Group();
            container.materialLibraries = [].concat(state.materialLibraries);

            for (var i = 0, l = state.objects.length; i < l; i++) {

                var object = state.objects[i];
                var geometry = object.geometry;
                var materials = object.materials;
                var isLine = (geometry.type === 'Line');
                var isPoints = (geometry.type === 'Points');
                var hasVertexColors = false;

                // Skip o/g line declarations that did not follow with any faces
                if (geometry.vertices.length === 0) continue;

                var buffergeometry = new THREE.BufferGeometry();

                buffergeometry.setAttribute('position', new THREE.Float32BufferAttribute(geometry.vertices, 3));

                if (geometry.normals.length > 0) {
                    buffergeometry.setAttribute('normal', new THREE.Float32BufferAttribute(geometry.normals, 3));
                } else {
                    buffergeometry.computeVertexNormals();
                }

                if (geometry.colors.length > 0) {
                    hasVertexColors = true;
                    buffergeometry.setAttribute('color', new THREE.Float32BufferAttribute(geometry.colors, 3));
                }

                if (geometry.uvs.length > 0) {
                    buffergeometry.setAttribute('uv', new THREE.Float32BufferAttribute(geometry.uvs, 2));
                }

                // Create materials
                var createdMaterials = [];

                for (var mi = 0, miLen = materials.length; mi < miLen; mi++) {

                    var sourceMaterial = materials[mi];
                    var material = undefined;

                    if (this.materials !== null) {
                        material = this.materials.create(sourceMaterial.name);

                        // mtl etc. loaders probably can't create line materials correctly, copy properties to a line material.
                        if (isLine && material && !(material instanceof THREE.LineBasicMaterial)) {
                            var materialLine = new THREE.LineBasicMaterial();
                            THREE.Material.prototype.copy.call(materialLine, material);
                            materialLine.color.copy(material.color);
                            materialLine.lights = false;
                            material = materialLine;
                        } else if (isPoints && material && !(material instanceof THREE.PointsMaterial)) {
                            var materialPoints = new THREE.PointsMaterial({ size: 10, sizeAttenuation: false });
                            THREE.Material.prototype.copy.call(materialPoints, material);
                            materialPoints.color.copy(material.color);
                            materialPoints.map = material.map;
                            materialPoints.lights = false;
                            material = materialPoints;
                        }
                    }

                    if (!material) {
                        if (isLine) {
                            material = new THREE.LineBasicMaterial();
                        } else if (isPoints) {
                            material = new THREE.PointsMaterial({ size: 1, sizeAttenuation: false });
                        } else {
                            /**BEGIN MODIFICATIONS - MESH_PHYSICAL_MATERIAL*/
                            material = new THREE.MeshPhongMaterial({
                                color: new THREE.Color(0xffffff),
                                dithering: true
                            });
                            /**END MODIFICATIONS */
                        }

                        material.name = sourceMaterial.name;
                    }

                    material.flatShading = sourceMaterial.smooth ? false : true;
                    material.vertexColors = hasVertexColors ? THREE.VertexColors : THREE.NoColors;

                    createdMaterials.push(material);
                }

                // Create mesh

                var mesh;

                if (createdMaterials.length > 1) {
                    for (var mi = 0, miLen = materials.length; mi < miLen; mi++) {
                        var sourceMaterial = materials[mi];
                        buffergeometry.addGroup(sourceMaterial.groupStart, sourceMaterial.groupCount, mi);
                    }

                    if (isLine) {
                        mesh = new THREE.LineSegments(buffergeometry, createdMaterials);
                    } else if (isPoints) {
                        mesh = new THREE.Points(buffergeometry, createdMaterials);
                    } else {
                        mesh = new THREE.Mesh(buffergeometry, createdMaterials);
                    }
                } else {
                    if (isLine) {
                        mesh = new THREE.LineSegments(buffergeometry, createdMaterials[0]);
                    } else if (isPoints) {
                        mesh = new THREE.Points(buffergeometry, createdMaterials[0]);
                    } else {
                        mesh = new THREE.Mesh(buffergeometry, createdMaterials[0]);
                    }
                }

                mesh.name = object.name;

                container.add(mesh);
            }

            console.timeEnd('BINLoader');

            return { object: container, mtl: mtlFile, images: imgTable };
        }
    };

    return BINLoader;

})();
