/**
 * Created by rhinov?? on ??/??/20??.
 *
 * WebGL savane other entity management (!= ArrangementObject since Arrangement objects use furniture), this typically serves to display entities that doesn't have any 3D representation yet
 * Generally those entities are generated directly in the hull
 */

function WebglEntity(entity, scene)
{
    // Keep track of the savane entity
    this.entity       = entity;
    // Create a group for the object
    this.object       = new THREE.Group();
    this.object.userData = { id: this.entity.id };

    this.scene = scene;

    this.update();
};

WebglEntity.prototype =
{
    constructor: WebglEntity,

    update: function()
    {
        // Update THREE matrix based on savane matrix datas
        var localMatrix = this.entity.transform.localMatrix;

        // Convert matrix to three space and apply to object
        var X = new THREE.Vector3().set(localMatrix[0], localMatrix[1], localMatrix[2]).normalize();
        var Y = new THREE.Vector3().set(localMatrix[4], localMatrix[5], localMatrix[6]).normalize();
        var Z = new THREE.Vector3().set(localMatrix[8], localMatrix[9], localMatrix[10]).normalize();

        var basis = new THREE.Matrix4().makeBasis(X, Y, Z);

        var position = new THREE.Vector3();
        var quaternion = new THREE.Quaternion();
        var scale = new THREE.Vector3();
        basis.decompose(position, quaternion, scale);

        this.object.quaternion.copy(quaternion);
        this.object.position.set(localMatrix[12] / 100, localMatrix[13] / 100, localMatrix[14] / 100);
        this.object.scale.copy(scale);
        this.scene.updateEnvs();
    },

    highlight: function(val)
    {
    },

    enableCollision: function(scale) {
    },

    /**
     * Apply back a change performed in the webGL view into the savane entity
     **/
    applyToEntity: function(mode, space)
    {
        if (mode === 'rotate') {
            var array = [];
            this.object.matrix.toArray(array);
            if (space === 'local') {
                array[12] = this.entity.localPosition[0] / 100;
                array[13] = this.entity.localPosition[1] / 100;
                array[14] = this.entity.localPosition[2] / 100;
            }
            var savaneMatrix = Savane.math.mat4.create();
            Savane.math.mat4.set(savaneMatrix,
                array[0], array[1], array[2], array[3],
                array[4], array[5], array[6], array[7],
                array[8], array[9], array[10], array[11],
                array[12] * 100, array[13] * 100, array[14] * 100, array[15]);
            this.entity.transform.localMatrix = savaneMatrix;
        }
        else if (mode === 'translate') {
            var array = [];
            this.object.matrix.toArray(array);
            this.entity.transform.localPosition = [array[12] * 100, array[13] * 100, array[14] * 100];
        } else {
            var scale = this.object.scale;
            var newScale = new THREE.Vector3().copy(scale);
            if (this.entity.stretchability != undefined) {
                if (this.entity.stretchability.x !== undefined && this.entity.stretchability.x.isStretchable && this.previousScale.x !== scale.x) {
                    this.entity.length = Math.min(Math.max(this.entity._originalLength * Math.abs(scale.x), this.entity._originalLength / 4), this.entity._originalLength * 2);
                    newScale.x = this.entity.length / this.entity._originalLength;
                }
                if (this.entity.stretchability.y !== undefined && this.entity.stretchability.y.isStretchable && this.previousScale.y !== scale.y) {
                    this.entity.width = Math.min(Math.max(this.entity._originalWidth * scale.y, this.entity._originalWidth / 4), this.entity._originalWidth * 2);
                    newScale.y = this.entity.width / this.entity._originalWidth;
                }
                if (this.entity.stretchability.z !== undefined && this.entity.stretchability.z.isStretchable && this.previousScale.z !== scale.z) {
                    this.entity.height = Math.min(Math.max(this.entity._originalHeight * scale.z, this.entity._originalHeight / 4), this.entity._originalHeight * 2);
                    newScale.z = this.entity.height / this.entity._originalHeight;
                }
            } else {
                newScale.x = this.entity.transform.localScale[0];
                newScale.y = this.entity.transform.localScale[1];
                newScale.z = this.entity.transform.localScale[2];
            }
            this.previousScale = new THREE.Vector3().copy(this.object.scale);
            this.entity.transform.localScale = [newScale.x, newScale.y, newScale.z];
            var array = [];
            this.object.matrix.toArray(array);
            this.entity.transform.localPosition = [array[12] * 100, array[13] * 100, array[14] * 100];
        }

        if (typeof EntityManager !== 'undefined') {
            var node = EntityManager.getNode(this.entity);
            if (node !== null) {
                node.needRedraw = true;
            }
        }
        this.update();
    },
}
