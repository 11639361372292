class Coating {

    static getColorFromComponent(coating, color) {
        if (coating.colors) {
            if (coating.colors.length > 0) {
                if (coating.colors[0].realColor !== undefined) {
                    return (coating.colors[0].realColor);
                }
                else {
                    if (coating.colors[0].color !== undefined && coating.colors[0].color !== null) {
                        return (coating.colors[0].color.color);
                    }
                }
            }
        }

        return (color);
    }

    static getColorFromAsset(coating, color) {
        if (coating.colors && coating.colors.realColor) {
            return (coating.colors.realColor);
        }

        if (coating.configs !== undefined && coating.configs !== null && coating.configs.length !== 0) {
            if (coating.configs[0].realColor !== undefined) {
                return (coating.configs[0].realColor);
            }
            else {
                if (coating.configs[0].color !== undefined && coating.configs[0].color !== null) {
                    return (coating.configs[0].color.color);
                }
            }
        }

        return (color);
    }

}
