class ReplaceKitchenCoatingCommand extends Savane.Commands.Command {

    constructor(handler, entity, replaceBy, topBottoms, topBottomsConstrained) {
        super();

        this._handler = handler;
        this._entity = entity;
        this._amCoating = replaceBy.coating;
        this._customName = replaceBy.partType;
        this._topBottoms = topBottoms;
        this._topBottomsConstrained = topBottomsConstrained;

        this._commands = [];
    }

    name() {
        return "ReplaceKitchenCoatingCommand";
    }

    assetType() {
        return AssetManagerServices._ASSET_TYPE.COATINGS;
    }

    undo() {
        for (var i = 0; i < this._commands.length; ++i) {
            this._commands[i].undo();
        }

        Savane.eventsManager.dispatch(Savane.Events.SELECTED_ARRANGEMENT, { entity: this._entity, isKitchenEntity: true, point: null });
        Savane.eventsManager.dispatch(Savane.Events.RHINOV_FORMAT_UPDATED, { scene: this._entity.scene, command: null });
    }

    redo() {
        this.execute(true);
    }

    get _toReplace() {
        if (this._commands.length > 0) {
            var item = this._commands[0]._modifiedEntities[0];
            var coatings = item.getComponents(Savane.ComponentConstants.ComponentType.Coating);
            var hangType = Savane.Commands.ChangeKitchenCoatingCommand.getHangType(this._customName);
            for (var i = 0; i < coatings.length; ++i) {
                if (coatings[i].hangType === hangType) {
                    return coatings[i];
                }
            }
        }

        return null;
    }

    get _replaceBy() {
        return { _coatingId: this._amCoating._id };
    }

    execute(redo) {
        var objectTypeList = Savane.SceneConstants.ArrangementType.kitchenCustomizableArrangements
        this._commands.push(new Savane.Commands.ChangeKitchenCoatingCommand([this._entity], this._amCoating, this._customName, objectTypeList, this._topBottoms, this._topBottomsConstrained))

        for (var i = 0; i < this._commands.length; ++i) {
            this._commands[i].execute();
        }
        
        Savane.eventsManager.dispatch(Savane.Events.SELECTED_ARRANGEMENT, { entity: this._entity, isKitchenEntity: true, point: null });
        Savane.eventsManager.dispatch(Savane.Events.RHINOV_FORMAT_UPDATED, { scene: this._entity.scene, command: redo ? null : this });
    }

}
