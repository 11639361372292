class ReplaceCoatingCommand extends Savane.Commands.Command {

    constructor(handler, entity, toReplace, replaceBy, isCredence) {
        super();

        this._handler = handler;
        this._entity = entity;
        this._toReplace = toReplace;
        this._replaceBy = replaceBy;

        this.isCredence = isCredence;

        // copy usemtlName
        this._replaceBy.usemtlName = this._toReplace.usemtlName;

        this._commands = [];
    }

    name() {
        return "ReplaceCoatingCommand";
    }

    assetType() {
        return AssetManagerServices._ASSET_TYPE.COATINGS;
    }

    undo() {
        for (var i = 0; i < this._commands.length; ++i) {
            this._commands[i].undo();
        }

        var child = this._handler.scene.savaneScene.getDeepChild(this._entity.id);
        this._handler.scene.updateHull(child);
        if (this._replaceBy.floorGeneratorSettings || (this._toReplace && this._toReplace.floorGeneratorSettings) || this._entity.isGeometryPrimitiveEntity()) {
            Savane.eventsManager.dispatch(Savane.Events.FLOOR_GENERATOR_CHANGED);
            Savane.eventsManager.dispatch(Savane.Events.PROJECT_REFRESH_HULL);
        }

        Savane.eventsManager.dispatch(Savane.Events.SELECTED_COATING, { entity: this._entity, coating: this._toReplace, isCredence: this.isCredence, point: null });
        Savane.eventsManager.dispatch(Savane.Events.RHINOV_FORMAT_UPDATED, { scene: this._entity.scene, command: null });
    }

    redo() {
        this.execute(true);
    }

    execute(redo) {
        this._commands = [];
        var child = this._handler.scene.savaneScene.getDeepChild(this._entity.id);
        if (this._toReplace.componentType) {
            this._replaceBy.rotation = this._toReplace.rotation;
            this._commands.push(new Savane.Commands.ReplaceComponentCommand(this._toReplace, this._replaceBy, this._entity));
        } else {
            this._commands.push(new Savane.Commands.AddComponentCommand(this._replaceBy, this._entity));
        }

        for (var i = 0; i < this._commands.length; ++i) {
            this._commands[i].execute();
        }

        this._handler.scene.updateHull(child);
        if (this._replaceBy.floorGeneratorSettings || (this._toReplace && this._toReplace.floorGeneratorSettings) || this._entity.isGeometryPrimitiveEntity()) {
            Savane.eventsManager.dispatch(Savane.Events.FLOOR_GENERATOR_CHANGED);
            Savane.eventsManager.dispatch(Savane.Events.PROJECT_REFRESH_HULL);
        }
        Savane.eventsManager.dispatch(Savane.Events.SELECTED_COATING, { entity: this._entity, coating: this._replaceBy, isCredence: this.isCredence, point: null });
        Savane.eventsManager.dispatch(Savane.Events.RHINOV_FORMAT_UPDATED, { scene: this._entity.scene, command: redo ? null : this });
    }

}
