/**
 *    THREE.js main scene, renderer and object manipulations
 *
 **/

THREE.ColorManagement.enabled = false;

const holdoutTexture = new THREE.TextureLoader().load(PLAN_WEBGL_MODULE_PATH + '/medias/holdout.png');
holdoutTexture.wrapS = THREE.RepeatWrapping;
holdoutTexture.wrapT = THREE.RepeatWrapping;
var holdoutMaterial = new THREE.MeshPhongMaterial({ map: holdoutTexture, color: 0xffffff });

function WebglScene(savaneScene, settings, controls, useCameraRatio, leftPanel) {
    this.destroyed = false;
    THREE.Cache.enabled = true;
    this.debugPhysics = false;
    this.physicsEnabled = false;
    // THREE.js scene
    this.threeScene = new THREE.Scene();
    this.physics = new ENABLE3D.AmmoPhysics(this.threeScene, { gravity: { x: 0, y: 0, z: -9.81 }, maxSubSteps: 2, fixedTimeStep: 1 / 60 });
    if (this.debugPhysics) {
        this.physics.debug.enable();
    } else {
        this.physics.debug.disable();
    }
    // Timeout when nothing happen - this is used to updateEnvs
    this._idleTimeout = null;
    this._idleHullTimeouts = [];
    this._idleObjectsTimeouts = [];
    this._visibleRooms = [];
    this.requestID = null;
    this.forceProbes = false;
    this.leftPanel = leftPanel;
    this.hullTransparency = false;
    // THREE.js renderer creation
    var canvas = document.createElement('canvas');
    this.renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        clearColor: 0x000000,
        precision: 'highp',
        powerPreference: 'high-performance',
        stencil: false,
        alpha: true,
        preserveDrawingBuffer: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.debug.checkShaderErrors = true;
    // Set renderer size based on the plan-webgl-rt size for the moment (before setting the right panel size thanks to a navigator cookie)
    var divWebgl = document.getElementById("plan-webgl-rt");
    this.renderer.setSize(divWebgl.clientWidth, divWebgl.clientHeight);

    this.renderer.domElement.id = "webglcanvas";
    divWebgl.appendChild(this.renderer.domElement);
    // 3D Camera list created from Savane Scene
    this.planCameras = [];
    // 3D Entity list created from Savane scene
    this.planEntities = [];
    this.nonInteractivePlanEntities = [];
    this.furnitures = [];

    this.clock = new THREE.Clock();

    // User camera selection and parameters so we can move selected entities
    this.mousePickPosition = null;
    this.lastMouseDownPosition = null;
    this.mouseDragging = false;
    this.gizmoPosition = "center";
    // We want to snap arrangement objects
    this.snapObjects = true;
    this.controls = controls;
    this.useCameraRatio = useCameraRatio;

    // Get current texture quality from rhinov cookie (assign value 3 if cookie doesn't exist i.e. default value)
    this.settings = settings;
    this.meshLevel = this.settings.meshLevel;

    // Create a default camera
    this.defaultCamera = new THREE.PerspectiveCamera(Savane.SceneConstants.CameraPerspectiveOriginalFOV, divWebgl.clientWidth / divWebgl.clientHeight, 1, 700);
    this.defaultCamera.position.z = 250;
    this.defaultCamera.target = new THREE.Vector3();
    this.threeScene.add(this.defaultCamera);
    this.defaultCamera.lookAt(new THREE.Vector3(0, 0, 0));
    this.defaultCameraMoved = false;

    this.keyPressed = {};
    this.dragGizmo = false;
    this.gizmo = new THREE.TransformControls(this.defaultCamera, this.renderer.domElement);
    this.gizmo.enabled = false;
    this.gizmo.visible = false;
    this.threeScene.add(this.gizmo);
    this.selectionGroup = new THREE.Group();
    this.threeScene.add(this.selectionGroup);

    // shift + R -> arrays used to duplicate with offset
    this.previousDuplicatedEntities = [];
    this.currentDuplicatedEntities = [];

    this.displayHeight = false;

    if (this.renderer.capabilities.isWebGL2) {
        var parameters = {
            format: THREE.RGBAFormat,
            stencilBuffer: false
        };
        var size = this.renderer.getDrawingBufferSize(new THREE.Vector2());
        this.renderTarget = new THREE.WebGLRenderTarget(size.width, size.height, parameters);
        this.renderTarget.colorSpace = THREE.LinearSRGBColorSpace;
        var context = this.renderer.getContext();
        var max_samples = context.getParameter(context.MAX_SAMPLES);
        this.renderTarget.samples = 4;
        if (this.renderTarget.samples > max_samples) {
            this.renderTarget.samples = max_samples;
        }
        this.composer = new THREE.EffectComposer(this.renderer, this.renderTarget);
    } else {
        this.composer = new THREE.EffectComposer(this.renderer);
    }
    this.composer.setPixelRatio(window.devicePixelRatio);
    this.composer.setSize(divWebgl.clientWidth, divWebgl.clientHeight);
    //Render pass
    this.renderPass = new THREE.RenderPass(this.threeScene, this.defaultCamera);
    this.composer.addPass(this.renderPass);
    //OutlinePass (selection)
    this.outlinePass = new THREE.OutlinePass({ x: divWebgl.clientWidth, y: divWebgl.clientHeight }, this.threeScene, this.defaultCamera);
    this.outlinePass.visibleEdgeColor = new THREE.Color("#F5D231").convertSRGBToLinear();
    this.outlinePass.hiddenEdgeColor = new THREE.Color("#9b1c00").convertSRGBToLinear();
    this.composer.addPass(this.outlinePass);

    this.celShadingPass = new THREE.CelShadingPass(this.threeScene, this.defaultCamera, { x: divWebgl.clientWidth, y: divWebgl.clientHeight });
    this.composer.addPass(this.celShadingPass);

    //Gamma pass
    this.gammaCorrectionPass = new THREE.ShaderPass(THREE.GammaCorrectionShader);
    this.composer.addPass(this.gammaCorrectionPass);

    //FXAA pass (antialiasing)
    this.fxaaPass = null;
    if (!this.renderer.capabilities.isWebGL2) {
        this.fxaaPass = new THREE.ShaderPass(THREE.FXAAShader);
        this.fxaaPass.material.uniforms["resolution"].value.x = 1.0 / divWebgl.clientWidth;
        this.fxaaPass.material.uniforms["resolution"].value.y = 1.0 / divWebgl.clientHeight;
        this.composer.addPass(this.fxaaPass);
    }

    this.dofPass = new THREE.DepthOfFieldPass(this.threeScene, this.defaultCamera);
    this.composer.addPass(this.dofPass);

    this.updateComposer();

    if (!this.settings.interactiveProject) {
        this.selectionBox = new THREE.SelectionBox(this.defaultCamera, this.threeScene);
        this.selectionBoxHelper = new THREE.SelectionHelper(this.renderer, 'selectBox');
    }

    // Init ambient lightening
    this.ambient = new THREE.AmbientLight(new THREE.Color(0xffffff), 2);
    this.ambient.layers.enableAll();
    this.threeScene.add(this.ambient);

    // Current camera from plan (object and id)
    this.camera = null;
    this.cameraId = -1;
    // If this camera dragged to another position or rotation
    this.cameraDragging = false;

    // 3D Object loader to load furnitures
    this.objLoader = new SAVANE.OBJLoader();
    // 3D Object loader to load furnitures
    this.binLoader = new SAVANE.BINLoader();
    // Scene group containing everything
    this.sceneGroup = null;

    // Hull 3D model returned by smartRiser
    this.staticHull = null;
    this.dynamicHull = null;
    this.floorGeneratorHull = null;
    // Main project scene
    this.updateScene(savaneScene);

    this.stats = new Stats();
    this.stats.dom.style.position = 'absolute';
    this.stats.dom.style.bottom = '0px';
    this.stats.dom.style.removeProperty('top');
    this.stats.setMode(-1);
    divWebgl.appendChild(this.stats.dom);

    this._initialize();
    // Raycaster to detect collision for front view and current camera object selection and collisions to move objects up and down
    this.rayCaster = new THREE.Raycaster();

    // Mouse button up listener
    this.mouseUpListener = Savane.eventsManager.addListener(MOUSE_EVENTS.UP, function(event) {
        if (typeof PlanManager === 'undefined' || this.dragGizmo) {
            return;
        }

        this.updateEnvs();
        var selection = PlanManager.getInstance().selectedEntities.slice();
        selection = selection.filter(function(item) {
            return this.itemSelectionAllowed(item);
        }.bind(this));

        // Is there a selected object in user camera mode
        if (selection.length > 0) {
            switch (event.userData.button) {
                case 0:
                    // If the object wasn't moved, open the tulip of the object in cocos (so we can see its tulip even if hidden by another object, thats the only way to see a tulip of an hidden entity)
                    if (!this.mouseDragging) {
                        var openTulip = true;

                        if (selection.length > 1) {
                            for (var i = 0 ; i < selection.length ; i++) {
                                if (!selection[i].isArrangementObjectEntity() && !selection[i].isArrangementGroupEntity()) {
                                    openTulip = false;
                                }
                            }
                        }
                        PlanManager.getInstance().updateItemsSelected(selection, false, openTulip);
                        for (var i = 0; i < selection.length; ++i) {
                            selection[i].endTemporary();
                        }
                    }
                    else {
                        // Left button release, move the object to its final position, create a command to do that (this will create a copy of the entity)
                        // Execute the command to copy copy into original entity and copy original entity into copy
                        selection = selection.filter(function(item) {
                            // filter item that can be selected but not allowed for interactions
                            return this.interactionWithItemAllowed(item);
                        }.bind(this));
                        if (selection.length > 0) {
                            PlanManager.getInstance().executeCommand(new Savane.Commands.EditEntitiesCommand(selection));
                        }
                    }
                    break;
                case 2:
                    this.detachSelection();
                    // Cancelling its temporary this will replace it at its original position and update it to force its redraw
                    for (var i = 0; i < selection.length; ++i) {
                        selection[i].endTemporary();
                        var item = this.getPlanEntity(selection[i].id);
                        if (item) {
                            item.update();
                        }
                    }
                    break;
            }
        }
    }.bind(this));

    // Mouse move listener
    this.mouseMoveListener = Savane.eventsManager.addListener(MOUSE_EVENTS.MOVE, function(event) {
        if (!this.controls || this.dragGizmo || typeof PlanManager === 'undefined') {
            return;
        }

        this.stopEnvUpdate();
        // Get cursor NDC position
        var vector = event.userData;
        var selection = PlanManager.getInstance().selectedEntities.slice();
        selection = selection.filter(function(item) {
            return this.interactionWithItemAllowed(item);
        }.bind(this));

        switch (event.userData.button) {
            case 0: //left
                var camera = this.getActiveGLCamera();
                var mousePosition = new THREE.Vector2(vector.x, vector.y);
                this.rayCaster.setFromCamera(mousePosition, camera);

                this.hideSelection();
                var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
                intersects = this.removeGizmoFromIntersectionResults(intersects, true);
                this.showSelection();

                var intersection = null;
                if (intersects.length > 0) {
                    intersection = intersects[0];
                }
                else {
                    return;
                }

                if (selection.length === 1) {
                    this.detachSelection();
                    for (var i = 0; i < selection.length; ++i) {
                        var entity = selection[i];
                        var glEntity = this.getPlanEntity(entity.id);
                        if (!glEntity) {
                            continue;
                        }
                        var size = new THREE.Vector2();
                        this.renderer.getSize(size);
                        this.mouseDragging = this.lastMouseDownPosition.distanceTo(mousePosition) * size.x > 20;
                        if (this.mouseDragging == false) {
                            continue;
                        }
                        entity.startTemporary();
                        this.updateEntityPosition(entity, glEntity, intersects[0]);
                        // Check if we need to snap objects
                        if (this.snapObjects && selection.length === 1 &&
                            !intersection.object.name.startsWith('Wall') &&
                            !intersection.object.name.startsWith('axo_Slope')) {
                            // Try to snap the object
                            Savane.planManager.snapArrangement(entity, PlanManager.getInstance().world.currentScene.currentFloor);
                        }

                        // Update the object so it is redrawn
                        this.updateTree(glEntity.entity);
                    }
                    this.attachSelection();
                }
                this.mousePickPosition = intersection.point;
                break;
        }
    }.bind(this));

    // Mouse down listener
    this.mouseDownListener = Savane.eventsManager.addListener(MOUSE_EVENTS.DOWN, function(event) {
        if (this.dragGizmo || typeof PlanManager === 'undefined') {
            return;
        }

        this.stopEnvUpdate();
        var camera = this.getActiveGLCamera();
        // Mouse coordinates
        var vector = event.userData;
        this.lastMouseDownPosition = new THREE.Vector2(vector.x, vector.y);
        // Fire raycaster to see if we have a collision somewhere
        this.rayCaster.setFromCamera(this.lastMouseDownPosition, camera);
        // Get intersection list
        var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
        intersects = this.removeGizmoFromIntersectionResults(intersects, false);

        if (intersects.length === 0 && event.userData.button !== 1 && event.userData.ctrl === false) {
            // Nothing selected in CocosPlan for the moment
            PlanManager.getInstance().updateItemsSelected(null);
        }

        var selection = PlanManager.getInstance().selectedEntities.slice();
        selection = selection.filter(function(item) {
            return this.interactionWithItemAllowed(item);
        }.bind(this));
        //left click
        switch (event.userData.button) {
            case 0:
                // Any object intersected ?
                if (intersects.length > 0) {
                    // Try to get the savane entity id stored into the userdata of the object
                    var id = intersects[0].object.parent.userData.id;

                    // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
                    if (!id) {
                        id = intersects[0].object.userData.id;
                    }

                    if (!id && this.staticHull !== null) {
                        id = StaticHull.getIdFromChild(intersects[0].object);
                    }

                    if (!id && this.dynamicHull !== null) {
                        id = DynamicHull.getIdFromChild(intersects[0].object);
                    }

                    if (!id) {
                        PlanManager.getInstance().updateItemsSelected(null);
                    }

                    // Savane id found ?
                    if (id) {
                        //clone entities
                        if (event.userData.shift && selection.length > 0) {
                            var cloned = [];
                            var clickedCloneItemIndex = 0;
                            for (var i = 0; i < selection.length; ++i) {
                                cloned.push({ entity: Savane.EntityFactory.cloneEntity(selection[i], true), parent: selection[i].parent });
                                if (selection[i].id === id) {
                                    clickedCloneItemIndex = cloned.length - 1;
                                }
                            }
                            PlanManager.getInstance().executeCommand(new Savane.Commands.AddEntitiesCommand(cloned, true));
                            id = cloned[clickedCloneItemIndex].entity.id;
                            selection = PlanManager.getInstance().selectedEntities.slice();
                            selection = selection.filter(function(item) {
                                return this.interactionWithItemAllowed(item);
                            }.bind(this));
                        }
                        // Try to get the 3D node from the 3D entity list
                        var node = this.getPlanEntity(id);

                        if ((node !== null)) {
                            // If arrangement object
                            if (this.itemSelectionAllowed(node.entity)) {
                                this.mousePickPosition = intersects[0].point;
                                this.mouseDragging = false;

                                this.hideSelection();
                                var intersectsUnderNode = this.rayCaster.intersectObjects(this.threeScene.children, true);
                                intersectsUnderNode = this.removeGizmoFromIntersectionResults(intersectsUnderNode, true);
                                this.showSelection();
                                if (intersectsUnderNode.length > 0) {
                                    this.mousePickPosition = intersectsUnderNode[0].point;
                                }

                                // If parent is a group
                                if (node.entity.parent) {
                                    if (node.entity.parent.isArrangementGroupEntity() || node.entity.parent.isArrangementObjectEntity()) {
                                        // Iterate until we find the top arrangementGroup father of current entity
                                        var entity = node.entity;

                                        while (entity.parent && !event.userData.alt && (entity.parent.isArrangementGroupEntity() || entity.parent.isArrangementObjectEntity())) {
                                            entity = entity.parent;
                                        }
                                        // Store the 3D node
                                        node = this.getPlanEntity(entity.id);
                                    }
                                }

                                // And if the node isn't null mark it as selected
                                if (node !== null) {
                                    // Start a temporary on the entity (for command and undo purpose)
                                    if (!event.userData.alt) {
                                        node.entity.startTemporary();
                                    }

                                    if (event.userData.ctrl) {
                                        if (selection.length === 0) {
                                            // Activate filtering of the current object type in the designer filters
                                            Savane.eventsManager.dispatch(Savane.Events.SET_FILTERS, {
                                                entity: node.entity
                                            });
                                        }

                                        for (var i = 0; i < selection.length; i++) {
                                            if (selection[i].id === node.entity.id) {
                                                selection.splice(i, 1);
                                                break;
                                            }
                                        }

                                        selection.push(node.entity);
                                        PlanManager.getInstance().updateItemsSelected(selection, false, false);
                                    } else if (this.camera && event.userData.alt) {
                                        this.camera.entity.startTemporary();
                                        this.excludeObjectFromRender(this.camera, node.entity);
                                        this.hideExcludedObject(this.camera);
                                        PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this.camera.entity));
                                    }
                                    else if (!event.userData.shift) {
                                        // Activate filtering of the current object type in the designer filters
                                        Savane.eventsManager.dispatch(Savane.Events.SET_FILTERS, {
                                            entity: node.entity,
                                        });
                                        // Select entity in CocosPlan without tulip for the moment (we will open the tulip if no movement detected before button release)
                                        PlanManager.getInstance().updateItemsSelected([node.entity], false, false);
                                    }
                                }
                            } else {
                                PlanManager.getInstance().updateItemsSelected(null);
                            }
                        }
                    }
                }
                break;
        }
    }.bind(this));

    // Mouse double click listener
    this.mouseDoubleClickListener = Savane.eventsManager.addListener(MOUSE_EVENTS.DOUBLE_CLICK, function(event) {
        if (typeof PlanManager === 'undefined') {
            return;
        }

        this.stopEnvUpdate();
        var camera = this.getActiveGLCamera();
        // Mouse coordinates
        var vector = event.userData;
        // Fire raycaster to see if we have a collision somewhere
        this.rayCaster.setFromCamera(new THREE.Vector2(vector.x, vector.y), camera);
        // Get intersection list
        var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
        intersects = this.removeGizmoFromIntersectionResults(intersects, true);

        //left click
        switch (event.userData.button) {
            case 0:
                if (intersects.length > 0) {
                    // If not decoration, leave
                    if (PlanManager.getInstance()._state.getAction() !== ActionStateEnum.idleDecoration) {
                        return;
                    }

                    // Try to get the savane entity id stored into the userdata of the object
                    var idEntity = intersects[0].object.parent.userData.id;

                    // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
                    if (idEntity === undefined) {
                        idEntity = intersects[0].object.userData.id;
                    }

                    // Savane id found ?
                    if (idEntity !== undefined) {
                        // Try to get the 3D node from the 3D entity list
                        var node = this.getPlanEntity(idEntity);
                        // For the moment we don't allow moving arrangement group in front view
                        if ((node !== null)) {
                            // If arrangement object
                            if (node.entity.isArrangementObjectEntity()) {
                                // If parent is a group
                                if (node.entity.parent) {
                                    if (node.entity.parent.isArrangementGroupEntity() || node.entity.parent.isArrangementObjectEntity()) {
                                        // Iterate until we find the top arrangementGroup father of current entity
                                        var entity = node.entity;

                                        while (entity.parent && (entity.parent.isArrangementGroupEntity() || entity.parent.isArrangementObjectEntity())) {
                                            entity = entity.parent;
                                        }
                                        // Store the 3D node
                                        node = this.getPlanEntity(entity.id);
                                    }
                                }

                                // And if the node isn't null mark it as selected
                                if (node !== null) {
                                    if (event.userData.ctrl) {
                                        var selection = PlanManager.getInstance().selectedEntities.slice();
                                        selection = selection.filter(function(item) {
                                            return this.interactionWithItemAllowed(item);
                                        }.bind(this));
                                        for (var i = 0; i < selection.length; i++) {
                                            if (selection[i].id === node.entity.id) {
                                                selection.splice(i, 1);
                                                break;
                                            }
                                        }
                                        PlanManager.getInstance().updateItemsSelected(selection, false, false);
                                    }
                                }
                            }
                        }
                    }
                }
                break;
        }

    }.bind(this));

    // Listener of the unselect_webgl_items (sent by cocosplan to inform webgl an item list is unselected)
    this.unselectItemsListener = Savane.eventsManager.addListener(Savane.Events.UNSELECT_WEBGL_ITEMS, function(event) {
        this.toggleSelection(event.userData, false);
    }.bind(this));

    // Listener of the select_webgl_items (sent by cocosplan to inform webgl an item list is selected)
    this.selectItemsListener = Savane.eventsManager.addListener(Savane.Events.SELECT_WEBGL_ITEMS, function(event) {
        this.toggleSelection(event.userData, true);
    }.bind(this));

    this.snapUpdatedListener = Savane.eventsManager.addListener(Savane.Events.SNAP_UPDATED, function() {
        if (typeof PlanManager !== 'undefined') {
            this.snapObjects = PlanManager.getInstance().snapEnabled;
        }
    }.bind(this));

    this.isEntityInRoom = function(entity, id) {
        var room = entity.room;
        if (!room) {
            if (entity.isWallEntity()) {
                if (entity.rooms.length === 0) {
                    room = Savane.roomManager.getRoomAtPosition(entity.center, entity.floor);
                } else {
                    for (var j = 0; j < entity.rooms.length; ++j) {
                        if (entity.rooms[j].id === id) {
                            room = entity.rooms[j];
                            break;
                        }
                    }
                }
            } else if (entity.isJoineryEntity()) {
                if (entity.parent.rooms.length == 0) {
                    room = Savane.roomManager.getRoomAtPosition(entity.position, entity.floor);
                } else {
                    for (var j = 0; j < entity.parent.rooms.length; ++j) {
                        if (entity.parent.rooms[j].id === id) {
                            room = entity.parent.rooms[j];
                            break;
                        }
                    }
                }
            } else if (entity.isTechnicalElementObjectEntity()) {
                room = Savane.roomManager.getRoomAtPosition(entity.position, entity.floor);
            } else if (entity.isWorktopEntity()) {
                var area = entity.getComponent(Savane.ComponentConstants.ComponentType.Area);
                for (var j = 0; j < area.vertices.length; ++j) {
                    room = Savane.roomManager.getRoomAtPosition(area.vertices[j], entity.floor);
                    if (room) break;
                }
            } else if (entity.isFloorEntity()) {
                var area = entity.getComponent(Savane.ComponentConstants.ComponentType.FloorCoatingArea);
                if (area) {
                    for (var j = 0; j < area.vertices.length; ++j) {
                        room = Savane.roomManager.getRoomAtPosition(area.vertices[j], entity.floor);
                        if (room) break;
                    }
                }
            }
        }
        return room;
    }

    this.hideOtherRoomListener = Savane.eventsManager.addListener(Savane.Events.HIDE_OTHER_ROOMS, function(event) {
        this._visibleRooms = [];
        this._visibleRooms.push(event.userData.room.id);
        Savane.eventsManager.dispatch(Savane.Events.PROJECT_DID_LOAD);
    }.bind(this));

    this.showAllRoomListener = Savane.eventsManager.addListener(Savane.Events.SHOW_ALL_ROOMS, function() {
        this._visibleRooms = [];
        var entities = this.planEntities.concat(this.nonInteractivePlanEntities);
        for (var i = 0; i < entities.length; ++i) {
            var planEntity = entities[i];
            this.setLayer(planEntity.object, 0);
        }
        Savane.eventsManager.dispatch(Savane.Events.PROJECT_DID_LOAD);
    }.bind(this));

    this.showThisRoomListener = Savane.eventsManager.addListener(Savane.Events.SHOW_THIS_ROOM, function(event) {
        if (this._visibleRooms.indexOf(event.userData.room.id) === -1) {
            this._visibleRooms.push(event.userData.room.id);
        }
        Savane.eventsManager.dispatch(Savane.Events.PROJECT_DID_LOAD);
    }.bind(this));

    this.gizmo.addEventListener('change', this.render.bind(this));
    this.gizmo.addEventListener('objectChange', function() {
        if (PlanManager.getInstance().magnetRotation) {
            this.gizmo.setRotationSnap(THREE.MathUtils.degToRad(15));
        } else {
            this.gizmo.setRotationSnap(null);
        }
        if (this.keyPressed.ctrl) {
            this.gizmo.setRotationSnap(THREE.MathUtils.degToRad(45));
        }
        var selection = PlanManager.getInstance().selectedEntities.slice();
        selection = selection.filter(function(item) {
            return this.interactionWithItemAllowed(item);
        }.bind(this));
        var nodes = [];
        for (var i = 0; i < selection.length; ++i) {
            var item = selection[i];
            var node = this.getPlanEntity(item.id);
            if (!node) {
                continue;
            }
            nodes.push(node);
        }
        this.preparePhysicsColliders(nodes, this.gizmo.mode === 'scale', false);
        this.updatePhysics();

        var realTimeUpdate = this.gizmo.mode !== 'scale' || this.physicsEnabled;
        if (realTimeUpdate) {
            this.detachSelection();
            for (var i = 0; i < nodes.length; ++i) {
                var node = nodes[i];
                node.applyToEntity(this.gizmo.mode, this.gizmo.space);
            }
            this.attachSelection();
        }
    }.bind(this));
    this.gizmo.addEventListener('mouseDown', function() {
        this.dragGizmo = true;
        if (typeof PlanManager === 'undefined') {
            return;
        }
        if (PlanManager.getInstance().magnetRotation) {
            this.gizmo.setRotationSnap(THREE.MathUtils.degToRad(15));
        } else {
            this.gizmo.setRotationSnap(null);
        }
        if (this.keyPressed.ctrl) {
            this.gizmo.setRotationSnap(THREE.MathUtils.degToRad(45));
        }
        if (this.keyPressed.shift) {
            //duplicate
            var selection = PlanManager.getInstance().selectedEntities.slice();
            selection = selection.filter(function(item) {
                if (item.parent && item.parent.isArrangementGroupEntity() && item.parent.isUnbreakableGroup()) {
                    return(false);
                }
                return this.interactionWithItemAllowed(item);
            }.bind(this));
            this.detachSelection();
            this.duplicateEntities(selection, null);
        }
        var selection = PlanManager.getInstance().selectedEntities.slice();
        selection = selection.filter(function(item) {
            return this.interactionWithItemAllowed(item);
        }.bind(this));
        var nodes = [];
        for (var i = 0; i < selection.length; ++i) {
            selection[i].startTemporary();
            var node = this.getPlanEntity(selection[i].id);
            if (!node) {
                continue;
            }
            node.startManipulation = true;
            nodes.push(node);
        }
        //check initial collisions
        this.preparePhysicsColliders(nodes, false, true);
        this.updatePhysics();
        for (var i = 0; i < nodes.length; ++i) {
            nodes[i].startManipulation = false;
        }
    }.bind(this));
    this.gizmo.addEventListener('mouseUp', function() {
        if (this.dragGizmo === false) {
            return;
        }

        this.dragGizmo = false;
        if (typeof PlanManager !== 'undefined') {
            var selection = PlanManager.getInstance().selectedEntities.slice();
            selection = selection.filter(function(item) {
                return this.interactionWithItemAllowed(item);
            }.bind(this));
            if (selection.length > 0) {
                this.detachSelection();
                for (var i = 0; i < selection.length; ++i) {
                    var item = selection[i];
                    var node = this.getPlanEntity(item.id);
                    if (!node) {
                        continue;
                    }
                    node.applyToEntity(this.gizmo.mode, this.gizmo.space);
                }
                PlanManager.getInstance().executeCommand(new Savane.Commands.EditEntitiesCommand(selection));
            }
        }
    }.bind(this));
}

WebglScene.prototype =
    {
        constructor: WebglScene,

        // Initialise WebGL scene default parameters
        _initialize() {
            // Activate shadow map an set type
            this.renderer.shadowMap.enabled = true;
            this.renderer.shadowMap.type = THREE.PCFShadowMap;
            this.renderer.shadowMap.autoUpdate = true;
            // Activate physical lighting
            this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
            this.renderer.toneMappingExposure = 1;

            this.renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
            // Attach resize event
            this.resizeListener = this._resizeCallback.bind(this);
            // $(window).resize(this.resizeListener);
            window.addEventListener("resize", this.resizeListener);
        },

        duplicateEntitiesOffset() {
            if (!this.currentDuplicatedEntities || this.currentDuplicatedEntities.length === 0) return;
            if (!this.previousDuplicatedEntities || this.previousDuplicatedEntities.length === 0) return;
            this.duplicateEntities(this.currentDuplicatedEntities, this.previousDuplicatedEntities);
        },

        duplicateEntities(entities, previousEntities) {
            if (!entities) return;
            // compute offset vectors if previousObjects is defined
            var offsets = [];
            for (entity in entities) {
                offsets.push(Savane.math.vec3.create());
            }
            if (previousEntities) {
                for (var i = 0; i < previousEntities.length; ++i) {
                    var entity = entities[i];
                    var previous = previousEntities[i];
                    Savane.math.vec3.subtract(offsets[i], entity.position, previous.position);
                }
            }

            var newEntities = [];
            for (var i = 0; i < entities.length; ++i) {
                var item = entities[i];
                if (!item.parent) continue;
                var entity = Savane.EntityFactory.cloneEntity(item, true);
                entity.isAnchorActive = false;
                var position = entity.position;
                Savane.math.vec3.add(position, position, offsets[i]);
                entity.position = position;
                newEntities.push({ entity: entity, parent: item.parent });
                var techElement = item.isTechnicalElementObjectEntity() || item.isStaircaseEntity() || item.isGeometryPrimitiveEntity();
                if (techElement) {
                    var node = this.getPlanEntity(item.id);
                    if (node) {
                        var object = node.object.clone();
                        object.geometry = node.object.geometry.clone();
                        StaticHull.replaceChildNameId(object, entity.id);
                        DynamicHull.replaceChildNameId(object, entity.id);
                        var newNode = new WebglHullEntity(entity, this, object, this.staticHull, true);
                        var objectScale = object.scale;
                        var parentScale = node.object.parent.scale;
                        object.scale.set(1, 1, 1);
                        node.object.parent.scale.set(1, 1, 1);
                        node.object.parent.attach(object);
                        object.scale.set(objectScale.x, objectScale.y, objectScale.z);
                        node.object.parent.scale.set(parentScale.x, parentScale.y, parentScale.z);
                        this.planEntities.push(newNode);
                    }
                }
            }
            PlanManager.getInstance().executeCommand(new Savane.Commands.AddEntitiesCommand(newEntities, true, true, false));
            this.previousDuplicatedEntities = entities;
            this.currentDuplicatedEntities = newEntities.map((item) => item.entity);
        },

        loadBackground(sun, additionalsAfters) {
            var exterior = additionalsAfters ? sun.additionalExterior : sun.exterior;
            this.threeScene.backgroundRotation.z =  Savane.Math.Functions.degree2radian(-sun.decorsRotation);
            if (sun.loadedExterior === exterior) return;
            sun.loadedExterior = exterior;
            new THREE.TextureLoader().load(PLAN_WEBGL_MODULE_PATH + '/medias/decors/' + exterior + '.jpg', function(texture) {
                texture.colorSpace = THREE.SRGBColorSpace;
                if (this.backgroundRenderTarget) {
                    this.backgroundRenderTarget.dispose();
                }
                this.backgroundRenderTarget = CubeMapGenerator.fromEquirectangularTexture(this.renderer, texture, new THREE.Matrix4(), 1024);
                this.threeScene.background = this.backgroundRenderTarget.texture;
                texture.dispose();
                this.toggleSky(false);
            }.bind(this));
        },

        toggleSky(value) {
            if (!value) {
                this.sun.sky.removeFromParent();
            } else {
                this.threeScene.add(this.sun.sky);
            }
        },

        setLayer(object, layer) {
            if (!object) {
                return;
            }

            object.layers.set(layer);
            for (var i = 0; i < object.children.length; ++i) {
                this.setLayer(object.children[i], layer);
            }
        },

        updatePhysics() {
            var delta = this.clock.getDelta() * 1000;
            if (!this.physicsEnabled) return;
            this.physics.update(delta);
            if (this.debugPhysics) {
                this.physics.updateDebugger();
            }
        },

        preparePhysicsColliders(nodes, scale, initial) {
            if (!this.physicsEnabled) return;
            for (var i = 0; i < nodes.length; ++i) {
                var node = nodes[i];
                node.enableCollision(scale);
            }
            var traversing = function(child) {
                if (!child.isMesh) return;
                for (var i = 0; i < nodes.length; ++i) {
                    var node = nodes[i];
                    var box = new THREE.Box3().setFromObject(node.object);
                    box.expandByScalar(0.2);
                    var childBox = new THREE.Box3().setFromObject(child);
                    if (box.intersectsBox(childBox)) {
                        if (child.ignoreCollisions === true && initial === false) {
                            continue;
                        }
                        if (!child.body) {
                            this.physics.add.existing(child, { shape: 'mesh' });
                            child.body.setCollisionFlags(5);
                            this.clock.getDelta();
                        }
                    } else {
                        child.ignoreCollisions = false;
                        if (child.body) {
                            this.physics.destroy(child.body);
                        }
                    }
                }
            }.bind(this);
            if (this.staticHull) {
                this.staticHull.hull.traverse(traversing);
            }
            if (this.dynamicHull) {
                this.dynamicHull.hull.traverse(traversing);
            }
            if (this.floorGeneratorHull) {
                this.floorGeneratorHull.hull.traverse(traversing);
            }
            for (var i = 0; i < this.furnitures.length; ++i) {
                if (this.isInSelectionGroup(this.furnitures[i].object)) continue;
                this.furnitures[i].object.traverse(traversing);
            }
        },

        destroyPhysicsColliders() {
            if (!this.physicsEnabled) return;
            var traversing = function(child) {
                if (child.body) {
                    this.physics.destroy(child.body);
                }
            }.bind(this);
            this.threeScene.traverse(traversing);
        },

        hideSelection() {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            var selection = PlanManager.getInstance().selectedEntities.slice();
            selection = selection.filter(function(item) {
                return this.interactionWithItemAllowed(item);
            }.bind(this));
            for (var i = 0; i < selection.length; ++i) {
                var item = this.getPlanEntity(selection[i].id);
                if (item) {
                    this.setLayer(item.object, 1);
                }
            }
        },

        showSelection() {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            var selection = PlanManager.getInstance().selectedEntities.slice();
            selection = selection.filter(function(item) {
                return this.interactionWithItemAllowed(item);
            }.bind(this));
            for (var i = 0; i < selection.length; ++i) {
                var item = this.getPlanEntity(selection[i].id);
                if (item) {
                    this.setLayer(item.object, 0);
                }
            }
        },

        isGizmo(object) {
            var parent = object.parent;
            while (parent) {
                if (parent === this.gizmo) {
                    return true;
                }
                parent = parent.parent;
            }
        },

        isInSelectionGroup(object) {
            for (var i = 0; i < this.selectionGroup.children.length; ++i) {
                if (this.selectionGroup.children[i].id === object.id) {
                    return true;
                }
            }

            return false;
        },

        removeGizmoFromIntersectionResults(intersections, keepFG) {
            return intersections.filter(function(item) {
                if (item.object === this.gizmo) {
                    return false;
                }

                if (item.object === this.selectionGroup) {
                    return false;
                }

                if (item.object instanceof THREE.Sky === true) {
                    return false;
                }

                if (!keepFG && this.floorGeneratorHull && FloorGeneratorHull.getIdFromChild(item.object)) {
                    var { coatings, config, directCoating, coatingAreaNb } = this.floorGeneratorHull.getCoatingParameters(item.object);
                    if (config) {
                        return false;
                    }
                }

                if (item.object.name.indexOf("Step") !== -1 || item.object.name.indexOf("CounterStep") !== -1 || item.object.name.indexOf("BackStep") !== -1) {
                    return(false);
                }

                if (this.staticHull) {
                    var entity = this.staticHull.getEntityFromChild(item.object);
                    if (entity && item.object.material.opacity === 0.05) {
                        return false;
                    }
                }

                if (this.isGizmo(item.object)) {
                    return false;
                }

                return true;
            }.bind(this));
        },

        interactionWithItemAllowed(item) {
            if (item.ceilingBoxType === Savane.SceneConstants.CeilingBoxType.rollingShutter || !item.parent) {
                return false;
            }
            return item.isIArrangementEntity() || item.isTechnicalElementObjectEntity() || item.isStaircaseEntity() || item.isGeometryPrimitiveEntity();
        },

        itemSelectionAllowed(item) {
            return this.interactionWithItemAllowed(item) || item.isJoineryEntity() || item.isWallEntity() || item.isWorktopEntity();
        },

        createWebglEntity(item) {
            return !(this.interactionWithItemAllowed(item) || this.itemSelectionAllowed(item)) || item.isArrangementGroupEntity();
        },

        updateEntityPosition(entity, glEntity, intersection) {
            if (!this.mousePickPosition) {
                return;
            }

            var entityPosition = Savane.math.vec3.create();
            var offset = 0;
            var box = null; // bbox is undefined when created from drag&drop
            if (glEntity) {
                Savane.math.vec3.set(entityPosition, entity.transform.globalPosition[0], entity.transform.globalPosition[1], entity.transform.globalPosition[2]);
                box = new THREE.Box3().setFromObject(glEntity.object);
                offset = ((box.max.z - box.min.z) * 100) / 2;
                if (entity.isArrangementGroupEntity()) {
                    if (entity.initialFloorHeight === undefined) {
                        entity.initialFloorHeight = entity.floorHeight;
                        if (entity.parent && entity.parent.isRoomEntity()) {
                            entity.initialFloorHeight -= entity.parent.floorHeight;
                        }
                    }

                    if (entity.initialFloorHeight < 0) {
                        offset += entity.initialFloorHeight;
                    }
                }
            } else {
                offset = entity.height / 2;
                if (entity.isArrangementGroupEntity()) {
                    offset += entity.floorHeight;
                    if (entity.initialFloorHeight === undefined) {
                        entity.initialFloorHeight = entity.floorHeight;
                    }
                }
            }

            var rotation = Savane.Transform.extractRotation(entity.transform.localMatrix);
            var normal = intersection.face.normal.clone();

            var X = Savane.math.vec3.fromValues(rotation[0], rotation[1], rotation[2]);
            Savane.math.vec3.normalize(X, X);
            var Y = Savane.math.vec3.fromValues(rotation[4], rotation[5], rotation[6]);
            Savane.math.vec3.normalize(Y, Y);
            var Z = Savane.math.vec3.fromValues(rotation[8], rotation[9], rotation[10]);
            Savane.math.vec3.normalize(Z, Z);

            if (intersection.object.name.startsWith('Wall') && Math.abs(intersection.face.normal.dot(new THREE.Vector3(0, 0, 1))) < 0.1 || intersection.object.name.startsWith('FloorWall') ||
                intersection.object.name.startsWith('CoatingArea')) {
                var position = new THREE.Vector3(entityPosition[0] / 100, entityPosition[1] / 100, entityPosition[2] / 100);
                position.x += (intersection.point.x - this.mousePickPosition.x);
                position.y += (intersection.point.y - this.mousePickPosition.y);
                var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(normal, intersection.point);
                var projection = new THREE.Vector3();
                plane.projectPoint(position, projection);
                entityPosition[0] = projection.x * 100;
                entityPosition[1] = projection.y * 100;
                if (glEntity) {
                    entityPosition[0] += (entity.width * normal.x) / 2;
                    entityPosition[1] += (entity.width * normal.y) / 2;
                } else { // dropping object -> compute offset with entity width and set the orientation
                    entityPosition[0] += (entity.width * normal.x) / 2;
                    entityPosition[1] += (entity.width * normal.y) / 2;
                }
                entityPosition[2] += (intersection.point.z - this.mousePickPosition.z) * 100;
                Y = Savane.math.vec3.fromValues(normal.x, normal.y, normal.z);
                Z = Savane.math.vec3.fromValues(0, 0, 1);
                Savane.math.vec3.cross(X, Y, Z);
                Savane.math.vec3.normalize(X, X);
            } else if (intersection.object.name.startsWith('axo_Slope') || intersection.object.name.startsWith('axo_Ceiling')) {
                if ((entity._anchor && entity._anchor[2] === 1 && entity.isArrangementObjectEntity()) || entity.isArrangementGroupEntity()) {
                    Z = Savane.math.vec3.fromValues(0, 0, 1);
                    Savane.math.vec3.cross(Y, Z, X);
                    offset = entity.height / 2;
                } else if ((entity._anchor && entity._anchor[2] !== 2 && entity.isArrangementObjectEntity()) || entity.isArrangementGroupEntity()) {
                    Y = Savane.math.vec3.fromValues(normal.x, normal.y, normal.z);
                    if (Math.abs(Savane.math.vec3.dot(Z, Y)) > 0.999) {
                        Z = Savane.math.vec3.fromValues(0, 1, 0);
                    }
                    Savane.math.vec3.cross(X, Z, Y);
                    Savane.math.vec3.normalize(X, X);
                    Savane.math.vec3.cross(Z, X, Y);
                    Savane.math.vec3.normalize(Z, Z);
                    offset = entity.width / 2;
                } else {
                    Z = Savane.math.vec3.fromValues(-normal.x, -normal.y, -normal.z);
                    if (Math.abs(Savane.math.vec3.dot(Z, X)) > 0.999) {
                        Savane.math.vec3.cross(X, Y, Z);
                    }
                    if (Math.abs(Savane.math.vec3.dot(Z, Y)) > 0.999) {
                        Savane.math.vec3.cross(Y, Z, X);
                    }
                    Savane.math.vec3.cross(X, Y, Z);
                    Savane.math.vec3.cross(Y, Z, X);
                    offset = entity.height / 2;
                }

                entityPosition[0] += (intersection.point.x - this.mousePickPosition.x) * 100;
                entityPosition[1] += (intersection.point.y - this.mousePickPosition.y) * 100;
                entityPosition[2] = intersection.point.z * 100;

                var toProject = new THREE.Vector3(entityPosition[0] / 100, entityPosition[1] / 100, entityPosition[2] / 100);
                var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(normal, intersection.point);
                if (!glEntity) {
                    toProject.z = intersection.point.z;
                }
                var projection = new THREE.Vector3();
                plane.projectPoint(toProject, projection);
                entityPosition[0] = projection.x * 100 + normal.x * offset;
                entityPosition[1] = projection.y * 100 + normal.y * offset;
                entityPosition[2] = projection.z * 100 + normal.z * offset;
            } else {
                entityPosition[0] += (intersection.point.x - this.mousePickPosition.x) * 100;
                entityPosition[1] += (intersection.point.y - this.mousePickPosition.y) * 100;

                if (entity._anchor && (entity._anchor[2] === 1 || entity._anchor[2] === 2)) {
                    entityPosition[2] = intersection.point.z * 100 - offset;
                }
                else {
                    entityPosition[2] = intersection.point.z * 100 + offset;
                }
            }

            var localMatrix = Savane.math.mat4.fromValues(
                X[0], X[1], X[2], 0,
                Y[0], Y[1], Y[2], 0,
                Z[0], Z[1], Z[2], 0,
                0, 0, 0, 1,
            );

            var scaling = Savane.math.mat4.create();
            Savane.math.mat4.fromScaling(scaling, entity.transform.localScale);

            Savane.math.mat4.multiply(localMatrix, scaling, localMatrix);
            entity.transform.localMatrix = localMatrix;

            entity.position = entityPosition;
            // Remove anchoring is arrangement object or group
            if (entity.isArrangementObjectEntity() || entity.isArrangementGroupEntity()) {
                entity.isAnchorActive = false;
            }
        },

        attachSelection() {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            var selection = PlanManager.getInstance().selectedEntities.slice();
            selection = selection.filter(function(item) {
                return this.interactionWithItemAllowed(item);
            }.bind(this));
            for (var i = 0; i < selection.length; ++i) {
                var entities = this.getPlanEntities(selection[i].id);
                for (var j = 0; j < entities.length; ++j) {
                    var node = entities[j];
                    if (!this.interactionWithItemAllowed(node.entity)) {
                        continue;
                    }

                    this.attachToSelection(node);
                }
            }
        },

        attachToSelection(node) {
            var object = node.object;
            if (!object.parent) {
                return;
            }

            this.selectionGroup.updateWorldMatrix(true, false);
            var toLocal = new THREE.Matrix4().copy(this.selectionGroup.matrixWorld).invert();

            object.updateWorldMatrix(true, false);
            var world = new THREE.Matrix4().copy(object.matrixWorld);
            object.parent.remove(object);
            object.matrix.copy(world);
            object.matrixAutoUpdate = false;
            object.applyMatrix4(toLocal);
            object.matrixAutoUpdate = true;
            object.updateMatrix();
            object.updateWorldMatrix(true, false);
            this.selectionGroup.add(object);
        },

        detachSelection() {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            var attached = this.selectionGroup.children.slice();
            for (var i = 0; i < attached.length; ++i) {
                var object = attached[i];
                var id = object.parent.userData.id;
                if (!id) {
                    id = object.userData.id;
                }

                var entities = null;
                if (id) {
                    entities = this.getPlanEntities(id);
                } else {
                    var entity = null;
                    if (this.staticHull) {
                        entity = this.staticHull.getEntityFromChild(object);
                    }
                    if (!entity && this.dynamicHull) {
                        entity = this.dynamicHull.getEntityFromChild(object);
                    }
                    if (!entity && this.floorGeneratorHull) {
                        entity = this.floorGeneratorHull.getEntityFromChild(object);
                    }
                    if (entity) {
                        entities = this.getPlanEntities(entity.id);
                    }
                }

                if (entities) {
                    for (var j = 0; j < entities.length; ++j) {
                        var node = entities[j];
                        if (!this.interactionWithItemAllowed(node.entity)) {
                            continue;
                        }

                        this.detachFromSelection(node);
                    }
                }
            }
        },

        detachFromSelection(node) {
            var object = node.object;
            var attached = this.isInSelectionGroup(object);

            if (!attached) {
                return false;
            }

            this.selectionGroup.updateWorldMatrix(true, false);
            this.selectionGroup.remove(object);
            var parent = null;
            var toLocal = new THREE.Matrix4();

            if (node.hull) {
                parent = node.hull.group.children[0];
            }
            else if (node.entity.parent) {
                parent = this.threeScene.getObjectByName("" + node.entity.parent.id);
                if (parent) {
                    toLocal.copy(parent.matrixWorld).invert();
                }
            }

            if (!parent) {
                parent = this.threeScene;
            }

            parent.updateWorldMatrix(true, false);
            object.matrixAutoUpdate = false;
            object.applyMatrix4(this.selectionGroup.matrixWorld);
            object.applyMatrix4(toLocal);
            object.matrixAutoUpdate = true;
            object.updateMatrix();
            object.updateWorldMatrix(true, false);
            parent.add(object);
            return true;
        },

        includeObjectFromRender(camera, entity) {
            if (!camera) {
                return;
            }

            if (entity.isArrangementObjectEntity() || entity.isJoineryEntity() || entity.isWorktopEntity() || entity.isTechnicalElementObjectEntity() || entity.isStaircaseEntity() || entity.isGeometryPrimitiveEntity()) {
                var index = camera.entity.excludedObjectIds.indexOf(entity.id);
                if (index !== -1) {
                    camera.entity.excludedObjectIds.splice(index, 1);
                }
            }

            for (var i = 0; i < entity.children.length; ++i) {
                var child = entity.children[i];
                this.includeObjectFromRender(camera, child);
            }
        },

        excludeObjectFromRender(camera, entity) {
            if (!camera) {
                return;
            }

            if (entity.isArrangementObjectEntity() || entity.isJoineryEntity() || entity.isWorktopEntity() ||
                entity.isTechnicalElementObjectEntity() || entity.isStaircaseEntity() || entity.isGeometryPrimitiveEntity() ||
                (entity.isWallEntity() && entity.isSpecialWall)) {
                if (camera.entity.excludedObjectIds.indexOf(entity.id) === -1) {
                    camera.entity.excludedObjectIds.push(entity.id);
                }
            }

            for (var i = 0; i < entity.children.length; ++i) {
                var child = entity.children[i];
                this.excludeObjectFromRender(camera, child);
            }
        },

        toggleSnap() {
            this.snapObjects = !this.snapObjects;
            if (typeof PlanManager !== 'undefined') {
                PlanManager.getInstance().snapEnabled = this.snapObjects;
                Savane.eventsManager.dispatch(Savane.Events.SNAP_UPDATED);
            }
        },

        toggleMagnet() {
            if (typeof PlanManager !== 'undefined') {
                PlanManager.getInstance().magnetRotation = !PlanManager.getInstance().magnetRotation;
                Savane.eventsManager.dispatch(Savane.Events.ANGLE_SNAP_UPDATED);
            }
        },

        computeGizmoPosition(xAxis, yAxis, zAxis, length, width, height) {
            switch (this.gizmoPosition) {
                case "xright":
                    this.selectionGroup.position.addScaledVector(xAxis.negate(), length / 2);
                    break;
                case "xleft":
                    this.selectionGroup.position.addScaledVector(xAxis, length / 2);
                    break;
                case "yright":
                    this.selectionGroup.position.addScaledVector(yAxis.negate(), width / 2);
                    break;
                case "yleft":
                    this.selectionGroup.position.addScaledVector(yAxis, width / 2);
                    break;
                case "top":
                    this.selectionGroup.position.addScaledVector(zAxis, height / 2);
                    break;
                case "bottom":
                    this.selectionGroup.position.addScaledVector(zAxis.negate(), height / 2);
                    break;
            }
        },

        setGizmoPosition(clearOutline) {
            var selection = PlanManager.getInstance().selectedEntities.slice();
            selection = selection.filter(function(item) {
                return this.interactionWithItemAllowed(item);
            }.bind(this));
            if (clearOutline) {
                if (this.outlinePass) {
                    var previousSelectedObjects = this.outlinePass.selectedObjects.slice();
                    this.outlinePass.selectedObjects = [];
                    previousSelectedObjects.forEach(function(object) {
                        object.traverse(function(item) {
                            item.visible = true;
                        });
                    });
                }
                this.hideExcludedObject(this.camera);
            }
            this.gizmo.object = undefined;
            this.detachSelection();
            // Parse all items
            this.selectionGroup.position.set(0, 0, 0);
            this.selectionGroup.scale.set(1, 1, 1);
            this.selectionGroup.quaternion.set(0, 0, 0, 1);
            var nodes = [];
            for (var i = 0; i < selection.length; i++) {
                // Try to get 3D node from plan entities of the THREE.js scene
                var entities = this.getPlanEntities(selection[i].id);

                var bbox = new THREE.Box3();
                for (var j = 0; j < entities.length; ++j) {
                    var node = entities[j];
                    bbox.expandByObject(node.object);
                }

                for (var j = 0; j < entities.length; ++j) {
                    var node = entities[j];
                    nodes.push(node);
                    if (this.outlinePass) {
                        this.outlinePass.selectedObjects.push(node.object);
                    }

                    var p = new THREE.Vector3();
                    bbox.getCenter(p);
                    this.selectionGroup.position.add(p);
                    this.selectionGroup.quaternion.copy(node.object.quaternion);

                    if (selection.length === 1) {
                        var xAxis = new THREE.Vector3(node.object.matrix.elements[0], node.object.matrix.elements[1], node.object.matrix.elements[2]).normalize();
                        var yAxis = new THREE.Vector3(node.object.matrix.elements[4], node.object.matrix.elements[5], node.object.matrix.elements[6]).normalize();
                        var zAxis = new THREE.Vector3(node.object.matrix.elements[8], node.object.matrix.elements[9], node.object.matrix.elements[10]).normalize();
                        var length = node.entity.length;
                        var width = node.entity.width;
                        if (length === undefined) {
                            length = node.entity.diameter;
                        }
                        if (width === undefined) {
                            width = node.entity.diameter;
                        }

                        var transformedBbox = bbox.clone()
                        transformedBbox.applyMatrix4(node.object.matrix);
                        var size = new THREE.Vector3();
                        transformedBbox.getSize(size);
                        this.computeGizmoPosition(xAxis, yAxis, zAxis, length / 100, width / 100, size.z);
                    }
                }
            }
            this.selectionGroup.position.divideScalar(nodes.length);

            if (selection.length > 1) {
                var bbox = new THREE.Box3();
                for (var i = 0; i < nodes.length; ++i) {
                    bbox.expandByObject(nodes[i].object);
                }
                var xAxis = new THREE.Vector3(1, 0, 0);
                var yAxis = new THREE.Vector3(0, 1, 0);
                var zAxis = new THREE.Vector3(0, 0, 1);
                var size = new THREE.Vector3();
                bbox.getSize(size);
                this.computeGizmoPosition(xAxis, yAxis, zAxis, size.x, size.y, size.z);
            }

            this.attachSelection();
            this.gizmo.object = this.selectionGroup;
            this.preparePhysicsColliders(nodes, false, true);
        },

        // Set an entity list to selected or unselected
        toggleSelection(selection, selected) {
            for (var i = 0; i < selection.length; ++i) {
                var item = selection[i];
                if (this.currentDuplicatedEntities.findIndex(x => { return x.id === item.id }) === -1) {
                    this.previousDuplicatedEntities = [];
                    this.currentDuplicatedEntities = [];
                }
            }
            var selection = selection.slice();
            selection = selection.filter(function(item) {
                return this.interactionWithItemAllowed(item);
            }.bind(this))
            if (selected) {
                this.setGizmoPosition(false);
            } else {
                if (this.dragGizmo === false) {
                    this.gizmo.object = undefined;
                }
                for (var i = 0; i < selection.length; i++) {
                    var node = this.getPlanEntity(selection[i].id);
                    if (!node) {
                        continue;
                    }

                    if (this.dragGizmo === false) {
                        this.detachFromSelection(node);
                    }

                    if (this.outlinePass) {
                        var previousSelectedObjects = this.outlinePass.selectedObjects.slice();
                        this.outlinePass.selectedObjects = [];
                        previousSelectedObjects.forEach(function(object) {
                            object.traverse(function(item) {
                                item.visible = true;
                            });
                        });
                    }
                    this.hideExcludedObject(this.camera);
                }
                this.destroyPhysicsColliders();
            }

            this.render();
        },

        setBoxSelection() {
            var selected = this.selectionBox.select();
            var selection = PlanManager.getInstance().selectedEntities.slice();
            selection = selection.filter(function(item) {
                return this.interactionWithItemAllowed(item);
            }.bind(this));
            for (var i = 0; i < selected.length; ++i) {
                var id = null;

                var node = null;
                if (PlanManager.getInstance()._state.isDecorationState() === false) {
                    if (this.staticHull !== null) {
                        id = StaticHull.getIdFromChild(selected[i]);
                    }

                    if (!id && this.dynamicHull !== null) {
                        id = DynamicHull.getIdFromChild(selected[i]);
                    }
                } else {
                    id = selected[i].parent.userData.id;
                    // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
                    if (!id) {
                        id = selected[i].userData.id;
                    }
                }

                if (id) {
                    node = this.getPlanEntity(id);
                }

                // node found ?
                if (node !== null) {
                    if (this.interactionWithItemAllowed(node.entity) == false) continue;

                    if (PlanManager.getInstance()._state.isDecorationState() === false ) {
                        if (node.entity.isArrangementObjectEntity()) continue;
                    }

                    if (node.entity.parent) {
                        if (node.entity.parent.isArrangementGroupEntity() || node.entity.parent.isArrangementObjectEntity()) {
                            // Iterate until we find the top arrangementGroup father of current entity
                            var entity = node.entity;

                            while (entity.parent &&
                                (entity.parent.isArrangementGroupEntity() ||
                                 entity.parent.isArrangementObjectEntity())) {
                                entity = entity.parent;
                            }
                            // Store the 3D node
                            node = this.getPlanEntity(entity.id);
                        }
                    }

                    if (!node.entity.isGhost()) {
                        if (selection.indexOf(node.entity) === -1) {
                            selection.push(node.entity);
                        }
                    }
                }
            }
            if (selection.length === 0) {
                PlanManager.getInstance().updateItemsSelected(null);
            } else {
                PlanManager.getInstance().updateItemsSelected(selection, false, false);
            }
        },

        // Loads the rhinov 3D recursively and creates all objects for it
        _recurSceneRead(entity) {
            // Try to create WebGL 3D object from Savane entity
            var entityObject = this.createFromEntity(entity);

            // Only if object loaded successfully
            if (entityObject !== undefined) {
                // Parse all object children
                for (var i = 0; i < entity.children.length; i++) {
                    // Create a 3D object for each of them (resursivity here)
                    var childObject = this._recurSceneRead(entity.children[i]);

                    // Add it to the scene only if child object loaded successufully too
                    if (childObject !== undefined) {
                        entityObject.add(childObject);
                    }
                }
            }

            // Return loaded entity 3D object
            return entityObject;
        },

        // Create all WebGL entities from Rhinov scene
        createSceneEntities() {
            // If the webGL scene already exist
            if (this.sceneGroup !== null) {
                // Empty scene from all objects
                for (var i = this.sceneGroup.children.length - 1; i >= 0; i--) {
                    this.sceneGroup.remove(this.sceneGroup.children[i]);
                }
            }
            else {
                // Create scene WebGL object and add it to the 3JS scene
                this.sceneGroup = new THREE.Group();
                this.threeScene.add(this.sceneGroup);
            }

            // Parse all Rhinov scene and add all objects using recursive function above
            var sceneObject = this._recurSceneRead(this.savaneScene);

            // And the object containing all WebGL objects to the scene
            this.sceneGroup.add(sceneObject);
            // Update scene matrix
            this.sceneGroup.updateMatrix();
            if (this.camera) {
                this.hideExcludedObject(this.camera);
            }
        },

        getDefaultCameraDistanceFit() {
            var bbox    = this.savaneScene.currentFloor.boundingBox;
            // Get top/left
            var topLeft =  new THREE.Vector3(bbox[0][0] / 100, bbox[0][1] / 100, 0);
            // and bottom/right of the floor
            var botRight = new THREE.Vector3(bbox[3][0] / 100, bbox[3][1] / 100, 0);
            // Compute floor radius
            var radius = topLeft.distanceTo(botRight) / 2;

            const fitOffset = 1.4;
            const fitHeightDistance = radius / (2 * Math.atan(Math.PI * this.defaultCamera.fov / 360));
            const fitWidthDistance = fitHeightDistance / this.defaultCamera.aspect;
            const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);
            return distance;
        },

        fitCamera(entity, index) {
            var activeCamera = this.getActiveGLCamera();

            var camera;
            if (Array.isArray(activeCamera)) {
                camera = activeCamera[index];
            } else {
                camera = activeCamera;
            }

            var fitOffset = 1.1;
            var planEntity = this.getPlanEntity(entity.id);
            const box = new THREE.Box3().setFromObject(planEntity.object);

            const size = box.getSize(new THREE.Vector3());
            const center = box.getCenter(new THREE.Vector3());

            const maxSize = Math.max(size.x, size.y, size.z);
            const fitHeightDistance = maxSize / (2 * Math.atan(Math.PI * camera.fov / 360));
            const fitWidthDistance = fitHeightDistance / camera.aspect;
            const distance = Math.max(2, fitOffset * Math.max(fitHeightDistance, fitWidthDistance));

            const direction = new THREE.Vector3(0, -1, 0)
                .multiplyScalar(distance);

            direction.applyEuler(new THREE.Euler(0.436332, 0, 0, 'XYZ'));
            camera.position.copy(center).sub(direction);
            camera.lookAt(center);
            camera.updateMatrixWorld(true);
            camera.target = center;
        },

        updateCanvasSize() {
            var divWebgl = document.getElementById("plan-webgl-rt");
            if (!divWebgl) return;
            var height = divWebgl.parentNode.getBoundingClientRect().height;
            if (this.leftPanel) {
                height = divWebgl.parentNode.parentNode.getBoundingClientRect().height;
            }

            if (!this.leftPanel) {
                var rightPanel = document.getElementById("right-panel");
                var planFilter = document.getElementById("planFiltersGroupsBar");
                if (rightPanel) {
                    height = rightPanel.clientHeight;
                }

                if (planFilter) {
                    height -= planFilter.clientHeight;
                }
            }
            
            divWebgl.style.height = height + 'px';
            divWebgl.style.width = divWebgl.parentNode.clientWidth + 'px'; 
            this.renderer.setSize(divWebgl.parentNode.clientWidth, height);
        },

        getActiveGLCamera() {
            if (this.camera) {
                return this.camera.object;
            }

            if (this.cameraArray) {
                return this.cameraArray;
            }

            return this.defaultCamera;
        },

        showOtherFloorObjects(floor) {
            for (var i = 0; i < this.furnitures.length; ++i) {
                if (!this.furnitures[i].object) {
                    continue;
                }

                this.setLayer(this.furnitures[i].object, 0);
            }
        },

        hideUpperFloorObjects(floor) {
            for (var i = 0; i < this.furnitures.length; ++i) {
                if (!this.furnitures[i].object) {
                    continue;
                }
                if (!this.furnitures[i].entity.floor) {
                    continue;
                }
                if (this.furnitures[i].entity.floor.height > floor.height) {
                    this.setLayer(this.furnitures[i].object, 1);
                }
            }
        },

        hideCeilingAnchoredObjects(floor) {
            for (var i = 0; i < this.furnitures.length; ++i) {
                if (!this.furnitures[i].object) {
                    continue;
                }
                if (!this.furnitures[i].entity.floor) {
                    continue;
                }
                if (this.furnitures[i].entity.floor.id === floor.id && this.furnitures[i].entity.anchor[2] >= 1) {
                    this.setLayer(this.furnitures[i].object, 1);
                }
            }
        },

        /**
        * Change camera to the matching entity id or if null to the default camera
        *
        * @param {*} id Id of camera or null if you want to go back to defaukt camera
        **/
        updateCamera(id) {
            // Set current camera Id to -1 (if the id passed is null, the camera will remain like this --> Free mode)
            this.cameraId = -1;
            // If there is already an active camera
            if (this.camera !== null) {
                if (this.camera.entity.cameraType === Savane.SceneConstants.CameraType.Axonomic) {
                    if (this.staticHull) {
                        this.staticHull.showUpperFloor(this.camera.entity.floor);
                    }
                    if (this.dynamicHull) {
                        this.dynamicHull.showUpperFloor(this.camera.entity.floor);
                    }
                    if (this.floorGeneratorHull) {
                        this.floorGeneratorHull.showUpperFloor(this.camera.entity.floor);
                    }
                    this.showOtherFloorObjects(this.camera.entity.floor);
                }

                this.showExcludedObject();
            }

            this.updateCanvasSize();

            // Let's check what camera we want to activate (null is for free camera)
            if (!id) {
                // Reset default camera
                this.camera = null;
                this.dofPass.uniforms['focalDepth'].value = 0;
                this.gizmo.camera = this.defaultCamera;
                this.sun.noon = false;
                this.threeScene.background = null;
                this.toggleSky(true);
                this.sun.update();
                if (typeof PlanManager !== 'undefined' && PlanManager.getInstance()._hideAxo === false || this.settings.interactiveProject) {
                    if (this.staticHull) {
                        this.staticHull.showCeiling();
                    }
                    if (this.dynamicHull) {
                        this.dynamicHull.showCeiling();
                    }
                    if (this.floorGeneratorHull) {
                        this.floorGeneratorHull.showCeiling();
                    }
                }
            }
            else {
                // If no camera or if new camera different from current one
                var newCamera = this.camera === null || id !== this.camera.entity.id;
                if (newCamera) {
                    // Get the new camera from camera list build when parsing the savane scene
                    this.camera = this.getPlanCamera(id);
                }
                // Do we have a new camera to activate ?
                if (this.camera !== null) {
                    if (!this.camera.targetInitialized) {
                        this.camera.object.target = new THREE.Vector3(this.camera.entity.position[0] / 100, this.camera.entity.position[1] / 100, this.camera.entity.position[2] / 100);
                    }
                    this.gizmo.camera = this.camera.object;

                    // Set the correct render canvas size respecting the camera ratio
                    var divWebgl = document.getElementById("plan-webgl-rt");
                    if (!divWebgl) return;
                    if (newCamera || !this.camera.targetInitialized) {
                        this.initTarget(0, 0);
                    }

                    var canvas = document.getElementById("webglcanvas");
                    var newWidth = divWebgl.parentNode.clientWidth;
                    var newHeight = divWebgl.clientHeight;
                    if (this.useCameraRatio) {
                        var ratio = this.camera.entity.renderWidth / this.camera.entity.renderHeight;
                        if (ratio < 1) {
                            newWidth = newHeight * ratio;
                        } else {
                            newHeight = newWidth / ratio;
                        }
                        if (newHeight > divWebgl.clientHeight) {
                            newHeight = divWebgl.clientHeight;
                            newWidth = newHeight * ratio;
                        }
                    }
                    canvas.height = newHeight;
                    divWebgl.style.height = newHeight + 'px';
                    canvas.width = newWidth;
                    divWebgl.style.width = newWidth + 'px';

                    // Activate the camera
                    this.camera.activate(canvas.width / canvas.height);

                    // Store the active camera id
                    this.cameraId = id;
                    this.dofPass.uniforms['focalDepth'].value = this.camera.entity.dof / 10;
                    this.dofPass.uniforms['fstop'].value = this.camera.entity.aperture;
                } else {
                    if (this.staticHull) {
                        this.staticHull.showCeiling();
                    }
                    this.sun.noon = false;
                    this.sun.update();
                    if (this.dynamicHull) {
                        this.dynamicHull.showCeiling();
                    }
                    if (this.floorGeneratorHull) {
                        this.floorGeneratorHull.showCeiling();
                    }
                }
            }

            this.dofPass.enabled = this.dofPass.material.uniforms['focalDepth'].value != 0;
            this.dofPass.camera = this.getActiveGLCamera();

            //this.displayCurrentFloor();
            Savane.eventsManager.dispatch(Savane.Events.HIDE_AXO);
            if (this.camera) {
                // Show the ceiling for user camera view
                if (this.staticHull) {
                    if (this.camera.entity.cameraType === Savane.SceneConstants.CameraType.Axonomic) {
                        this.staticHull.hideCeiling();
                        this.staticHull.hideUpperFloor(this.camera.entity.floor);
                        this.sun.noon = true;
                        this.sun.update();
                        if (this.dynamicHull) {
                            this.dynamicHull.hideCeiling();
                            this.dynamicHull.hideUpperFloor(this.camera.entity.floor);
                        }
                        if (this.floorGeneratorHull) {
                            this.floorGeneratorHull.hideCeiling();
                            this.floorGeneratorHull.hideUpperFloor(this.camera.entity.floor);
                        }
                        this.hideUpperFloorObjects(this.camera.entity.floor);
                        this.hideCeilingAnchoredObjects(this.camera.entity.floor);
                    } else {
                        this.staticHull.showCeiling();
                        this.sun.noon = false;
                        this.sun.update();
                        if (this.dynamicHull) {
                            this.dynamicHull.showCeiling();
                        }
                        if (this.floorGeneratorHull) {
                            this.floorGeneratorHull.showCeiling();
                        }
                    }
                }

                this.hideExcludedObject(this.camera);

                Savane.eventsManager.dispatch(Savane.Events.WEBGL_ACTIVE_CAMERA, { entity: this.camera.entity });
            }
            else {
                Savane.eventsManager.dispatch(Savane.Events.WEBGL_FREE_CAMERA, { camera: this.defaultCamera });
            }

            this.resize();
        },

        // Delete an entity from the scene from the webGL view
        deleteSelectedEntities() {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            if (PlanManager.getInstance().selectedEntities.length > 0) {
                var selection = PlanManager.getInstance().selectedEntities.slice();
                selection = selection.filter(function(item) {
                    return this.interactionWithItemAllowed(item);
                }.bind(this));
                PlanManager.getInstance().executeCommand(new Savane.Commands.DeleteEntitiesCommand(selection));
                this.updateEnvs();
            }
        },

        // Undo action from WebGL view, call CocosPlan undo system
        undoAction() {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            PlanManager.getInstance().undoCommand();
            this.updateEnvs();
        },

        // Redo action from WebGL view, call CocosPlan redo system
        redoAction() {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            PlanManager.getInstance().redoCommand();
            this.updateEnvs();
        },

        getIdFromSelectedObject(object) {
            var id = object.userData.id;
            if (id) {
                return id;
            }

            for (var i = 0; i < object.children.length; ++i) {
                id = this.getIdFromSelectedObject(object.children[i]);
                if (id) {
                    return id;
                }
            }
        },

        // Move the free selection to the intersection passed as a parameter
        moveObjectToIntersection(entity, point) {
            this.updateEnvs();
            var node = this.getPlanEntity(entity.id);
            if (!node) {
                return;
            }
            // Deactivate its anchor
            entity.isAnchorActive = false;
            // Move the object to the right position
            entity.floorHeight = (point.z * 100) + (entity.realHeight - entity.height) / 2;
        },

        // Object snap to an upper collision
        snapSelectionUp() {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            var selection = PlanManager.getInstance().selectedEntities.slice();
            selection = selection.filter(function(item) {
                return this.interactionWithItemAllowed(item);
            }.bind(this));
            if (selection.length === 0) {
                return;
            }

            for (var i = 0; i < selection.length; ++i) {
                var entity = selection[i];
                var entityNode = this.getPlanEntity(entity.id);
                if (!entityNode) {
                    continue;
                }
                // Principle : get a point 5cm above the object position, find a collision with someone else above.
                // If found, go 5cm above the collision and go down to find a new collision
                // The goal is to find the above surface of an upper shelve for instance.

                // Raycaster starts from bottom of the object plus 5cm to find next collision point
                var startPoint = new THREE.Vector3(entity._transform.globalPosition[0] / 100,
                    entity._transform.globalPosition[1] / 100,
                    (entity.floorHeight - ((entity.realHeight - entity.height) / 2) + 50) / 100);
                // We go upwards
                var direction = new THREE.Vector3(0, 0, 1);
                // Find the next intersection
                this.rayCaster.set(startPoint, direction);

                this.setLayer(entityNode.object, 1);
                var intersectsUp = this.rayCaster.intersectObjects(this.threeScene.children, true);
                intersectsUp = this.removeGizmoFromIntersectionResults(intersectsUp, true);
                this.setLayer(entityNode.object, 0);

                // If at least one intersection
                for (var j = 0; j < intersectsUp.length; ++j) {
                    // Try to get the savane entity id stored into the userdata of the object
                    var id = intersectsUp[j].object.parent.userData.id;

                    // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
                    if (!id) {
                        id = intersectsUp[j].object.userData.id;
                    }

                    // Object id valid ?
                    if (id) {
                        // Find 3D node
                        var node = this.getPlanEntity(id);
                        if (!node) {
                            break;
                        }

                        // Go 5cm upper than the found collision
                        startPoint.z = intersectsUp[j].point.z + 0.5;
                        // And go down to find a new collision
                        direction.z = -1;
                        this.rayCaster.set(startPoint, direction);

                        this.setLayer(entityNode.object, 1);
                        var intersectsDown = this.rayCaster.intersectObjects(this.threeScene.children, true);
                        intersectsDown = this.removeGizmoFromIntersectionResults(intersectsDown, true);
                        this.setLayer(entityNode.object, 0);

                        var objectMoved = false;
                        for (var k = 0; k < intersectsDown.length; ++k) {
                            // Try to get the savane entity id stored into the userdata of the object
                            id = intersectsDown[k].object.parent.userData.id;

                            // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
                            if (!id) {
                                id = intersectsDown[k].object.userData.id;
                            }

                            // Object id valid ?
                            if (id) {
                                // Find 3D node
                                node = this.getPlanEntity(id);
                                if (!node) {
                                    continue;
                                }

                                // Move the object to the intersection
                                entity.startTemporary();
                                this.moveObjectToIntersection(entity, intersectsDown[k].point);
                                objectMoved = true;
                                break;
                            }
                        }
                        if (objectMoved) {
                            break;
                        }
                    }
                }
            }
            // Add the command that will excahnge the copy and current obect
            PlanManager.getInstance().executeCommand(new Savane.Commands.EditEntitiesCommand(selection));
        },

        // Object snap to an underneath collision
        snapSelectionDown() {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            var selection = PlanManager.getInstance().selectedEntities.slice();
            selection = selection.filter(function(item) {
                return this.interactionWithItemAllowed(item);
            }.bind(this));
            if (selection.length === 0) {
                return;
            }

            for (var i = 0; i < selection.length; ++i) {
                var entity = selection[i];
                var entityNode = this.getPlanEntity(entity.id);
                if (!entityNode) {
                    continue;
                }
                // Raycaster starts from bottom of the object minus 5cm to find next collision point
                var startPoint = new THREE.Vector3(entity._transform.globalPosition[0] / 100,
                    entity._transform.globalPosition[1] / 100,
                    (entity.floorHeight - ((entity.realHeight - entity.height) / 2) - 50) / 100);
                var direction = new THREE.Vector3(0, 0, -1);
                this.rayCaster.set(startPoint, direction);

                this.setLayer(entityNode.object, 1);
                var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
                intersects = this.removeGizmoFromIntersectionResults(intersects, true);
                this.setLayer(entityNode.object, 0);

                for (var j = 0; j < intersects.length; ++j) {
                    // Try to get the savane entity id stored into the userdata of the object
                    var id = intersects[j].object.parent.userData.id;

                    // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
                    if (!id) {
                        id = intersects[j].object.userData.id;
                    }

                    // Valid entity id ?
                    if (id) {
                        // Yes, find the 3D object
                        var node = this.getPlanEntity(id);

                        // Valid node and node id isn't the entity itself (avoind collision against ourselves)
                        if (node !== null) {
                            entity.startTemporary();
                            this.moveObjectToIntersection(entity, intersects[j].point);
                            break;
                        }
                    }

                    // If we intersect with the floor
                    if (intersects[j].object.name.startsWith("Floor")) {
                        // We will also move the object it will snap it on the floor
                        entity.startTemporary();
                        this.moveObjectToIntersection(entity, intersects[j].point);
                        break;
                    }
                }
            }
            // Add the command that will excahnge the copy and current obect
            PlanManager.getInstance().executeCommand(new Savane.Commands.EditEntitiesCommand(selection));
        },

        initTarget(x, y) {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            var camera = this.getActiveGLCamera();
            this.rayCaster.setFromCamera(new THREE.Vector2(x, y), camera);
            // Get intersection list
            var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
            intersects = this.removeGizmoFromIntersectionResults(intersects, true);
            if (intersects.length > 0) {
                var point = intersects[0].point;

                if (this.camera) {
                    var parent = PlanManager.getInstance().world.currentScene.currentFloor;
                    this.camera.object.target.set(point.x, point.y, point.z - parent.height / 100);
                    this.camera.targetInitialized = true;
                } else {
                    this.defaultCamera.target.copy(point);
                }
            }
        },

        _copyCoating(entity, hangType, usemtlName) {
            var result = null;
            var coatings = entity.getComponents(Savane.ComponentConstants.ComponentType.Coating);
            // Parse all coatings and try to find the same hanging type
            for (var i = 0; i < coatings.length; i++) {
                // Found, then save it
                if (coatings[i].hangType === hangType) {
                    if (hangType === Savane.Coating.HangType.usemtl) {
                        if (usemtlName === coatings[i].usemtlName) {
                            result = coatings[i].clone();
                            break;
                        }
                    }
                    else {
                        result = coatings[i].clone();
                        break;
                    }
                }
            }

            return result;
        },

        copyCoating(x, y) {
            var camera = this.getActiveGLCamera();
            this.rayCaster.setFromCamera(new THREE.Vector2(x, y), camera);
            var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
            intersects = this.removeGizmoFromIntersectionResults(intersects, true);
            if (intersects.length === 0) {
                return;
            }

            var object = intersects[0].object;
            var entity = null;
            if (this.staticHull) {
                entity = this.staticHull.getEntityFromChild(object);
            }
            if (!entity && this.dynamicHull) {
                entity = this.dynamicHull.getEntityFromChild(object);
            }
            if (!entity && this.floorGeneratorHull) {
                entity = this.floorGeneratorHull.getEntityFromChild(object);
            }

            if (!entity) {
                var idEntity = object.parent.userData.id;

                // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
                if (idEntity === undefined) {
                    idEntity = object.userData.id;
                }

                if (idEntity) {
                    var tEntity = this.getPlanEntity(idEntity);
                    if (tEntity && tEntity.entity.coatingAllowed) {
                        entity = tEntity.entity;
                    }
                }
            }

            if (!entity) return;

            // We are copying a coating area
            if (object.name.indexOf("CoatingArea") !== -1) {
                var coatingAreaParse = object.name.split('_');
                if (entity.isWallEntity()) {
                    var coatingAreaIndex = coatingAreaParse[2] - 1;
                    var areaCoatings = entity.getComponents(Savane.ComponentConstants.ComponentType.CoatingArea);
                    this.copiedCoating = areaCoatings[coatingAreaIndex].clone();
                } else if (entity.isFloorEntity()) {
                    var coatingAreaIndex;
                    if (object.name.startsWith("axo")) {
                        coatingAreaIndex = coatingAreaParse[3] - 1;
                    }
                    else {
                        coatingAreaIndex = coatingAreaParse[2] - 1;
                    }
                    var areaCoatings = entity.getComponents(Savane.ComponentConstants.ComponentType.FloorCoatingArea);
                    this.copiedCoating = areaCoatings[coatingAreaIndex].clone();
                }

                return;
            }

            var usemtlName;
            var hangType;

            var wallHangType = this._getWallHangType(object, intersects[0].face.materialIndex, entity);
            hangType = wallHangType.hangType;
            usemtlName = wallHangType.usemtlName;

            var coatingHangType = this._getCoatingHangType(object, intersects[0].face.materialIndex, null, entity);
            if (coatingHangType) {
                hangType = coatingHangType.hangType !== undefined ? coatingHangType.hangType : hangType;
                usemtlName = coatingHangType.usemtlName !== undefined ? coatingHangType.usemtlName : usemtlName;

                this.copiedCoating = this._copyCoating(entity, hangType, usemtlName);
            }
        },

        pasteCoating(x, y) {
            if (!this.copiedCoating) return;
            var coating = this.copiedCoating.clone();
            AssetManagerServices.getAsset(AssetManagerServices._ASSET_TYPE.COATINGS, coating.coatingId, null, function(result) {
                var command = this.setCoatingOnDrop(x, y, result, null, true);
                if (command) {
                    if (command instanceof Savane.Commands.ReplaceComponentCommand) {
                        var createdComponent = command._subCommands[1]._addedComponent.clone();
                        coating.vertices = [];
                        command._subCommands[1]._addedComponent = Savane.CoatingArea.prototype.clone.call(coating);
                        command._subCommands[1]._addedComponent.isDirectSide = createdComponent.isDirectSide;
                        command._subCommands[1]._addedComponent.vertices = createdComponent.vertices;
                        command._subCommands[1]._addedComponent.hangType = createdComponent.hangType;
                        command._subCommands[1]._addedComponent.usemtlName = createdComponent.usemtlName;
                    } else if (command instanceof Savane.Commands.ChangeCoatingCommand) {
                        var createdComponent = command._newCoatingComponent.clone();
                        command._newCoatingComponent = Savane.Coating.prototype.clone.call(coating);
                        command._newCoatingComponent.hangType = createdComponent.hangType;
                        command._newCoatingComponent.usemtlName = createdComponent.usemtlName;
                    }
                    PlanManager.getInstance().executeCommand(command);
                }
            }.bind(this));
        },

        // Set the camera target
        targetCamera(x, y) {
            if (this.camera) {
                if (this.camera.entity.cameraType === Savane.SceneConstants.CameraType.PhotoRender) {
                    return;
                }
                if (typeof PlanManager !== 'undefined') {
                    if (PlanManager.getInstance()._state.getAction() !== ActionStateEnum.idleDecoration) {
                        return;
                    }
                }
            }

            var camera = this.getActiveGLCamera();
            this.rayCaster.setFromCamera(new THREE.Vector2(x, y), camera);
            // Get intersection list
            var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
            intersects = this.removeGizmoFromIntersectionResults(intersects, true);
            if (intersects.length > 0) {
                var point = intersects[0].point;
                var position = new THREE.Vector3();
                camera.getWorldPosition(position);
                var forward = new THREE.Vector3().subVectors(point, position).normalize();
                var right = new THREE.Vector3().crossVectors(forward, new THREE.Vector3(0, 0, 1)).normalize();
                var up = new THREE.Vector3().crossVectors(right, forward).normalize();
                right.crossVectors(forward, up).normalize();

                var basis = new THREE.Matrix4().makeBasis(right, up, forward.negate());
                camera.quaternion.setFromRotationMatrix(basis);
                camera.up.copy(up);
                camera.updateMatrix();

                if (this.camera) {
                    var height = 0;
                    if (typeof PlanManager !== 'undefined') {
                        var parent = PlanManager.getInstance().world.currentScene.currentFloor;
                        height = parent.height / 100;
                    }
                    this.camera.object.target.set(point.x, point.y, point.z - height);
                } else {
                    this.defaultCamera.target.copy(point);
                }

                if (this.camera) {
                    this.camera.applyToEntity();
                }
            }
        },

        setCameraDof(x, y) {
            if (!this.camera) return;
            if (this.camera.entity.cameraType === Savane.SceneConstants.CameraType.PhotoRender ||
                this.camera.entity.cameraType === Savane.SceneConstants.CameraType.Perspective ||
                this.camera.entity.cameraType === Savane.SceneConstants.CameraType.Video) {

                var camera = this.getActiveGLCamera();
                this.rayCaster.setFromCamera(new THREE.Vector2(x, y), camera);
                // Get intersection list
                var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
                intersects = this.removeGizmoFromIntersectionResults(intersects, true);
                if (intersects.length > 0) {
                    var point = intersects[0].point;
                    var position = new THREE.Vector3();
                    camera.getWorldPosition(position);
                    var forward = new THREE.Vector3();
                    camera.getWorldDirection(forward);
                    var cameraPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(forward, position);
                    this.camera.entity.dof = cameraPlane.distanceToPoint(point) * 10;
                }
            }
        },

        /**
         * Change the currently selected camera focal using a screen coordinate vector
         * @param {Integer} screenY Screen distance on y axis (vertical) in pixels
         **/
        focalCamera(screenY) {
            if (this.camera !== null && this.camera.entity.isRenderCameraEntity()) {
                if (this.camera.entity.cameraType === Savane.SceneConstants.CameraType.PhotoRender) {
                    return;
                }
                if (typeof PlanManager !== 'undefined') {
                    if (PlanManager.getInstance()._state.getAction() !== ActionStateEnum.idleDecoration) {
                        return;
                    }
                }
                var fov = this.camera.entity.fov;
                this.camera.entity.fov += screenY;
                if (this.camera.entity.fov < 1 || this.camera.entity.fov > 179) {
                    this.camera.entity.fov  = fov;
                }
                var targetIsPosition = false;
                if (this.camera.object.position.distanceTo(this.camera.object.target) < 0.0001) {
                    targetIsPosition = true;
                }
                this.camera.activate();
                if (targetIsPosition) {
                    this.camera.object.target.copy(this.camera.object.position);
                }
            }
            else {
                if (this.camera === null) {
                    var fov = this.defaultCamera.fov;
                    this.defaultCamera.fov += screenY;
                    if (this.defaultCamera.fov < 1 || this.defaultCamera.fov > 179) {
                        this.defaultCamera.fov = fov;
                    }
                    this.defaultCamera.updateProjectionMatrix();
                }
            }
        },

        /**
         * Dolly the currently selected camera using a screen coordinate vector
         * Using a target to have something like a FPS view
         * @param {Integer} screenY Screen distance on y axis (vertical) in pixels
         **/
        dollyCamera(screenY, NDC) {
            if (this.camera) {
                if (this.camera.entity.locked) {
                    return;
                }
                if (typeof PlanManager !== 'undefined') {
                    if (PlanManager.getInstance()._state.getAction() !== ActionStateEnum.idleDecoration) {
                        return;
                    }
                }
            }

            var activeCamera = this.getActiveGLCamera();
            var cameras;
            if (Array.isArray(activeCamera)) {
                cameras = activeCamera;
            } else {
                cameras = [activeCamera];
            }

            for (var i = 0; i < cameras.length; ++i) {
                var camera = cameras[i];
                var forward = new THREE.Vector3(NDC.x, NDC.y, (camera.near + camera.far) / (camera.near - camera.far));
                forward.unproject(camera);
                var worldPosition = new THREE.Vector3();
                camera.getWorldPosition(worldPosition);
                forward.sub(worldPosition).normalize();

                if (Array.isArray(activeCamera)) {
                    camera.getWorldDirection(forward);
                }

                var position = camera.position;

                var minDistance = 1;
                var noTarget = false;
                if (!camera.target) return;
                var target = camera.target.clone();
                var accelerate = Math.abs((position.distanceTo(target) - minDistance) / 60);
                if (position.distanceTo(target) < minDistance / 10) {
                    noTarget = true;
                    accelerate = 0.2;
                }
                if (this.camera && this.camera.entity.cameraType === Savane.SceneConstants.CameraType.Axonomic) {
                    accelerate = 0.8;
                }

                if (!this.camera) {
                    this.defaultCameraMoved = true;
                } else if (this.camera.entity.projection) {
                    noTarget = true;
                    accelerate = 0.01;
                }

                var df = -screenY * accelerate;
                //update position
                position.x += forward.x * df;
                position.y += forward.y * df;
                position.z += forward.z * df;

                if (noTarget) {
                    camera.target.copy(position);
                } else if (position.distanceTo(target) < minDistance) {
                    position.x = target.x - forward.x * minDistance;
                    position.y = target.y - forward.y * minDistance;
                    position.z = target.z - forward.z * minDistance;
                }

                var normal = new THREE.Vector3();
                camera.getWorldDirection(normal);

                var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(normal, target);
                plane.intersectLine(new THREE.Line3(camera.position, camera.position.clone().add(normal.clone().multiplyScalar(camera.far))), camera.target);

                camera.updateMatrix();

                if (this.camera && !this.settings.interactiveProject) {
                    this.camera.applyToEntity();
                }
            }
        },

        /**
         * Pan the currently selected camera using a screen coordinate vector
         **/
        panCamera(x, y, dx, dy) {
            if (this.camera) {
                if (this.camera.entity.locked) {
                    return;
                }
                if (typeof PlanManager !== 'undefined') {
                    if (PlanManager.getInstance()._state.getAction() !== ActionStateEnum.idleDecoration) {
                        return;
                    }
                }
            }

            var activeCamera = this.getActiveGLCamera();
            var cameras;
            if (Array.isArray(activeCamera)) {
                cameras = activeCamera;
            } else {
                cameras = [activeCamera];
            }

            for (var i = 0; i < cameras.length; ++i) {
                var camera = cameras[i];
                if (!camera.target) return;

                var px = x + dx;
                var py = y + dy;

                var size = new THREE.Vector2();
                this.renderer.getSize(size);
                this.rayCaster.setFromCamera(new THREE.Vector2((x / size.x) * 2 - 1, ((size.y - y) / size.y) * 2 - 1), camera);
                var cRay = this.rayCaster.ray.direction.clone();
                this.rayCaster.setFromCamera(new THREE.Vector2((px / size.x) * 2 - 1, ((size.y - py) / size.y) * 2 - 1), camera);
                var pRay = this.rayCaster.ray.direction.clone();

                var position = new THREE.Vector3();
                camera.getWorldPosition(position);
                var r1 = new Savane.Math.Ray(Savane.math.vec3.fromValues(position.x, position.y, position.z), Savane.math.vec3.fromValues(cRay.x, cRay.y, cRay.z));
                var r2 = new Savane.Math.Ray(Savane.math.vec3.fromValues(position.x, position.y, position.z), Savane.math.vec3.fromValues(pRay.x, pRay.y, pRay.z));


                var worldDirection = new THREE.Vector3();
                camera.getWorldDirection(worldDirection);
                var forward = Savane.math.vec3.fromValues(worldDirection.x, worldDirection.y, worldDirection.z);
                var p = Savane.math.vec3.create();
                Savane.math.vec3.add(p, Savane.math.vec3.fromValues(camera.target.x, camera.target.y, camera.target.z), forward);
                var d = Savane.math.vec3.create();
                Savane.math.vec3.negate(d, forward);
                var plane = new Savane.Math.Plane(p, d);
                var i1 = plane.Intersect(r1);
                var i2 = plane.Intersect(r2);
                if (!i1 || !i2) {
                    return;
                }

                Savane.math.vec3.subtract(d, i1, i2);
                Savane.math.vec3.normalize(d, d);

                var targetIsPosition = false;
                var factor = 1;
                if (camera.position.distanceTo(camera.target) < 0.0001) {
                    factor = 25;
                    targetIsPosition = true;
                }

                if (!this.camera) {
                    this.defaultCameraMoved = true;
                } else if (this.camera.entity.projection) {
                    factor = 10;
                }

                //update position
                var axis = new THREE.Vector3(d[0], d[1], d[2]);
                var distance = Savane.math.vec3.distance(i1, i2) * factor;
                camera.position.x += axis.x * distance;
                camera.position.y += axis.y * distance;
                camera.position.z += axis.z * distance;
                //update target
                if (targetIsPosition) {
                    camera.target.x = camera.position.x;
                    camera.target.y = camera.position.y;
                    camera.target.z = camera.position.z;
                } else {
                    camera.target.x += axis.x * distance;
                    camera.target.y += axis.y * distance;
                    camera.target.z += axis.z * distance;
                }
                if (this.camera && !this.settings.interactiveProject) {
                    this.camera.applyToEntity();
                }
            }
        },

        /**
         * Rotate the currently selected camera using a screen coordinate vector
         * Using a target to have something like a FPS view
         * @param {Integer} screenX Screen distance on x axis (horizontal) in pixels
         * @param {Integer} screenY Screen distance on y axis (vertical) in pixels
         * @param {Float} speed Speed rate in pixel per update
         **/
        rotateCamera(screenX, screenY, speed) {
            if (this.camera) {
                if (this.camera.entity.locked) {
                    return;
                }
                if (typeof PlanManager !== 'undefined') {
                    if (PlanManager.getInstance()._state.getAction() !== ActionStateEnum.idleDecoration) {
                        return;
                    }
                }
            }

            var checkGimble = true;
            if (!this.camera) {
                this.defaultCameraMoved = true;
            } else if (this.camera.entity.projection) {
                checkGimble = false;
                speed = 10;
            }

            var activeCamera = this.getActiveGLCamera();
            var cameras;
            if (Array.isArray(activeCamera)) {
                cameras = activeCamera;
            } else {
                cameras = [activeCamera];
            }

            for (var i = 0; i < cameras.length; ++i) {
                var camera = cameras[i];
                var theta = THREE.MathUtils.degToRad(screenX * speed);
                var phi = -THREE.MathUtils.degToRad(screenY * speed);

                var right = new THREE.Vector3();
                var up = new THREE.Vector3();
                var forward = new THREE.Vector3();
                camera.matrix.extractBasis(right, up, forward);

                var normal = new THREE.Vector3();
                camera.getWorldDirection(normal);

                var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(normal, camera.target);
                plane.intersectLine(new THREE.Line3(camera.position, camera.position.clone().add(forward.clone().multiplyScalar(camera.far))), camera.target);

                
                if (this.camera && this.camera.entity.cameraType === Savane.SceneConstants.CameraType.Axonomic) {
                    checkGimble = false;
                    var aRotation = new THREE.Matrix4().makeRotationAxis(forward, -phi);
                    up.applyMatrix4(aRotation);
                    theta = 0;
                    phi = 0;
                }

                var target = new THREE.Vector3().copy(camera.target);
                // gimble lock special case
                if (checkGimble) {
                    if (Math.abs(forward.dot(new THREE.Vector3(0, 0, 1))) < 0.999) {
                        if (up.z < 0) {
                            up = new THREE.Vector3(0, 0, -1);
                        } else {
                            up = new THREE.Vector3(0, 0, 1);
                        }
                    } else {
                        theta = 0;
                    }
                }

                right = new THREE.Vector3().crossVectors(up, forward).normalize();
                var rRotation = new THREE.Matrix4().makeRotationAxis(up, theta);
                var fRotation = new THREE.Matrix4().makeRotationAxis(right, phi);
                var rotation = new THREE.Matrix4().multiplyMatrices(rRotation, fRotation);

                camera.position.sub(target);
                camera.position.applyMatrix4(rotation);
                camera.position.add(target);

                if (target.distanceTo(camera.position) === 0) {
                    forward.applyMatrix4(rotation);
                } else {
                    forward.subVectors(camera.position, target).normalize();
                }

                var newRight = new THREE.Vector3().crossVectors(up, forward).normalize();
                // gimble lock special case
                if (right.dot(newRight) < 0) {
                    newRight.negate();
                }
                up = new THREE.Vector3().crossVectors(forward, newRight).normalize();

                var basis = new THREE.Matrix4().makeBasis(newRight, up, forward);
                camera.quaternion.setFromRotationMatrix(basis);
                camera.up.copy(up);
                camera.updateMatrix();

                if (this.camera && !this.settings.interactiveProject) {
                    this.camera.applyToEntity();
                }
            }
        },

        toggleFreeCamera() {
            if (!this.camera) {
                return;
            }

            this.defaultCamera.position.copy(this.camera.object.position);
            this.defaultCamera.quaternion.copy(this.camera.object.quaternion);
            if (this.camera.object.target) {
                this.defaultCamera.target.copy(this.camera.object.target);
            }
            this.defaultCamera.updateMatrix();
            this.updateCamera(null);
        },

        createCameraFromFreeCamera(deliverable) {
            if (this.camera) {
                return;
            }

            if (typeof PlanManager === 'undefined') {
                return;
            }
            else {
                if (PlanManager.getInstance()._state.getAction() !== ActionStateEnum.idleDecoration) {
                    return;
                }
            }

            var camera = Savane.EntityFactory.createEmptyRenderCamera();

            if (deliverable) {
                if (deliverable.hd_rendering) {
                    camera.hd = true;
                }

                if (deliverable.night_project) {
                    camera.renderType = 3;
                }
            }

            var matrix = Savane.math.mat4.create();
            var array = [];
            this.defaultCamera.matrix.toArray(array);
            Savane.math.mat4.set(matrix,
                array[0], array[1], array[2], array[3],
                -array[8], -array[9], -array[10], array[11],
                array[4], array[5], array[6], array[7],
                array[12] * 100, array[13] * 100, array[14] * 100, array[15]);
            camera.transform.localMatrix = matrix;
            var parent = Savane.roomManager.getRoomAtPosition(camera.position, PlanManager.getInstance().world.currentScene.currentFloor);
            if (!parent) {
                parent = PlanManager.getInstance().world.currentScene.currentFloor;
            }
            PlanManager.getInstance().executeCommand(new Savane.Commands.AddEntityCommand(camera, parent, true));
            camera.updateCameraNb(PlanManager.getInstance().world);
            Savane.eventsManager.dispatch(Savane.Events.COMMAND_EXECUTED, {
                name: Savane.Commands.CommandEnum.EditEntityCommand,
                undo: false,
                datas: {
                    id: camera.id,
                    type: camera.entityType,
                    select: true
                }
            });
            var glCamera = this.getPlanCamera(camera.id);
            if (glCamera) {
                glCamera.object.target.copy(this.defaultCamera.target);
            }
        },

        /**
         * Reset camera pitch to 0
         */
        resetCameraPitch() {
            if (this.camera) {
                this.camera.entity.pitch = 0;
                this.camera.activate();
            }
        },

        clearExcludedObject() {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            if (!this.camera) {
                return;
            }

            this.showExcludedObject();
            this.camera.entity.startTemporary();
            this.camera.entity.excludedObjectIds = [];
            PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this.camera.entity));
        },

        // Set composer pass according to quality
        updateComposer() {
            if (this.fxaaPass !== null) {
                if (this.settings.fxaa) {
                    this.fxaaPass.enabled = true;
                } else {
                    this.fxaaPass.enabled = false;
                }
            } else {
                /*if (this.settings.fxaa) {
                    var context = this.renderer.getContext();
                    var max_samples = context.getParameter(context.MAX_SAMPLES);
                    this.renderTarget.samples = 2;
                    if (this.renderTarget.samples > max_samples) {
                        this.renderTarget.samples = max_samples;
                    }
                } else {
                    this.renderTarget.samples = 1;
                }*/
            }
        },

        // Set the render view Quality
        updateSettings() {
            var reloadRequired = this.meshLevel !== this.settings.meshLevel;
            this.meshLevel = this.settings.meshLevel;
            this.updateEnvs();

            if (this.camera && this.camera.entity.cameraType === Savane.SceneConstants.CameraType.Axonomic) {
                if (this.staticHull) {
                    this.staticHull.hideCeiling();
                }
                if (this.dynamicHull) {
                    this.dynamicHull.hideCeiling();
                }
                if (this.floorGeneratorHull) {
                    this.floorGeneratorHull.hideCeiling();
                }
            } else {
                if (this.staticHull) {
                  if ((typeof PlanManager === 'undefined') || (!PlanManager.getInstance()._hideAxo)) {
                      this.staticHull.showCeiling();
                  }
                }
                if (this.dynamicHull) {
                    if ((typeof PlanManager === 'undefined') || (!PlanManager.getInstance()._hideAxo)) {
                        this.dynamicHull.showCeiling();
                    }
                }
                if (this.floorGeneratorHull) {
                    if ((typeof PlanManager === 'undefined') || (!PlanManager.getInstance()._hideAxo)) {
                        this.floorGeneratorHull.showCeiling();
                    }
                }
                this.hideExcludedObject(this.camera);
            }

            var divWebgl = document.getElementById("plan-webgl-rt");
            if (!divWebgl) return;
            if (this.celShadingPass) {
                this.celShadingPass.setSize(divWebgl.clientWidth, divWebgl.clientHeight);
            }
            this.updateComposer();
            // this update area lights intensities
            if (this.sun) {
                this.sun.update();
            }
            if (reloadRequired) {
                this.updateScene(this.savaneScene);
            }
        },

        // Updates the THREE.js scene and reset the camera (i.e. reloads the scene content in terms of furnitures)
        updateScene(savaneScene) {
            // We will store the camera Id if any to put it back after the scene is rebuilt
            var cameraId = null;

            // If the current THREE.js camera exists
            if (this.camera !== null) {
                // Store its Id
                cameraId = this.camera.entity.id;
            }
            // If a scene already exists, free all its objects
            if (this.savaneScene) {
                this.removeEntityTree(this.savaneScene);
            }

            if (this.settings.interactiveProject) {
                // break all groups
                var groups = savaneScene.arrangementGroups;
                for (var i = 0; i < groups.length; ++i) {
                    (new Savane.Commands.DeleteGroupCommand(groups[i])).execute();
                }
                Savane.eventsManager.dispatch(Savane.Events.RHINOV_FORMAT_UPDATED, { scene: savaneScene });
            }

            // Store the savance scene pointer
            this.savaneScene = savaneScene;

            // Sun
            if (!this.sun) {
                this.sun = new WebGLSun(savaneScene.currentFloor.getSun(), this);
                this.planEntities.push(this.sun);
            } else {
                this.sun.entity = savaneScene.currentFloor.getSun();
                this.sun.update();
            }

            // Set the camera to default camera
            this.updateCamera(null);

            // Empty scene entities array and camera array
            this.planCameras = [];
            this.planEntities = this.planEntities.filter(function(item) {
                return item instanceof WebglHullEntity;
            }.bind(this));
            this.furnitures = [];
            // Create all scene entities
            this.createSceneEntities();

            // Put back the original camera
            if (cameraId !== null) {
                this.updateCamera(cameraId);
            }
            if (this.camera) {
                this.camera.updateProjectionMatrix();
            }
        },

        // Update the entity tree of an entity (itself and children)
        updateTree(savaneEntity) {
            // Get THREE.js entity of current entity and update it if it exists
            var webGLEntity = this.getPlanEntity(savaneEntity.id);
            var webGLParent = savaneEntity.parent ? this.getPlanEntity(savaneEntity.parent.id) : null;
            if (webGLEntity) {
                var wasSelected = this.detachFromSelection(webGLEntity);
                webGLEntity.update();
                if (!webGLEntity.hull && webGLParent) {
                    webGLParent.object.add(webGLEntity.object);
                }
                if (webGLEntity.hull) {
                    webGLEntity.hull.update(webGLEntity.entity);
                }

                if (webGLEntity.entity.isRoomEntity()) {
                    if (this.staticHull) {
                        this.staticHull.update(webGLEntity.entity);
                    }
                }
                if (wasSelected) {
                    this.attachToSelection(webGLEntity);
                }
            }

            // Parse all children and update them all
            for (var i = 0; i < savaneEntity.children.length; i++) {
                this.updateTree(savaneEntity.children[i]);
            }

            this.hideExcludedObject(this.camera);
        },

        /**
         * Add entity to scene
         **/
        addEntity(entity, config, lod, callback) {
            // Create the entity THREE.js object
            var object = this.createFromEntity(entity, config, lod, callback);

            if (object !== undefined && object !== null) {
                // Get parent thanks to the parent id from the savane format
                var parent = (entity.parent ? this.threeScene.getObjectByName("" + entity.parent.id) : null);
                // Add the 3D object to the 3D parent
                if (parent) {
                    parent.add(object);
                } else {
                    this.threeScene.add(object);
                }
            }
        },

        /**
         * Add an entity entity tree to scene (entity + children)
         **/
        addEntityTree(entity, callback) {
            // Add entity itself
            // call callback on top entity added only
            this.addEntity(entity, undefined, undefined, callback);

            // Add children
            for (var i = 0; i < entity.children.length; i++) {
                this.addEntityTree(entity.children[i]);
            }
        },

        /**
         * Remove entity from scene
         **/
        removeEntity(entity) {
            // THREE.js object entity and index into the camera array or furtinure array
            var webglEntity, index;

            // Depending on the object entity type
            switch (entity.entityType) {
                // Render cameras
                case Savane.SceneConstants.EntityType.RenderCamera:
                    // Get the 3D object from the plan camera array
                    webglEntity = this.getPlanCamera(entity.id);
                    if (webglEntity !== null) {
                        // Remove from scene
                        webglEntity.group.parent.remove(webglEntity.group);
                        // Remove from list
                        index = this.planCameras.indexOf(webglEntity);
                        this.planCameras.splice(index, 1);
                        // If the removed camera is the current camera, return to free topview camera
                        if (this.camera !== null && this.camera.entity.id === entity.id) {
                            // Reset to default camera
                            this.updateCamera(null);
                        }
                    }
                    break;

                default:
                    // Get webGL entity thanks to the entity id
                    webglEntity = this.getPlanEntity(entity.id);
                    // If found
                    if (webglEntity !== null) {
                        // Remove from 3JS scene
                        if (webglEntity.object && webglEntity.object.parent !== null) {
                            webglEntity.object.parent.remove(webglEntity.object);
                        }
                        // Remove from list
                        var index = this.planEntities.indexOf(webglEntity);
                        if (index !== -1) {
                            this.planEntities.splice(index, 1);
                        }
                        index = this.furnitures.indexOf(webglEntity);
                        if (index !== -1) {
                            this.furnitures.splice(index, 1);
                        }

                        // Free its memory explicitely
                        if (typeof webglEntity.dispose === "function") {
                            webglEntity.dispose();
                        }

                        // If light then remove its helper and potential target
                        if (webglEntity.entity.isLightEntity()) {
                            webglEntity.lightHelper.parent.remove(webglEntity.lightHelper);
                            if (webglEntity.target !== null) {
                                webglEntity.target.parent.remove(webglEntity.target);
                            }
                            webglEntity.dispose();
                        }
                    }
            }
        },

        /**
         * Remove an entire entity tree from scene (entity + children) from scene
         **/
        removeEntityTree(entity) {
            // Remove all children first
            for (var i = 0; i < entity.children.length; i++) {
                this.removeEntityTree(entity.children[i]);
            }

            // Remove the entity itself
            this.removeEntity(entity);
        },

        cleanUpStaticHull() {
            if (!this.staticHull) {
                return;
            }

            this.detachSelection();
            this.planEntities = this.planEntities.filter(function(item) {
                return item.hull !== this.staticHull;
            }.bind(this));
            this.nonInteractivePlanEntities = this.nonInteractivePlanEntities.filter(function(item) {
                return item.hull !== this.staticHull;
            }.bind(this));
            this.threeScene.remove(this.staticHull.group);
            this.staticHull.dispose();
            this.staticHull = null;
        },

        cleanUpFloorGeneratorHull() {
            if (!this.floorGeneratorHull) {
                return;
            }

            this.detachSelection();
            this.planEntities = this.planEntities.filter(function(item) {
                return item.hull !== this.floorGeneratorHull;
            }.bind(this));
            this.nonInteractivePlanEntities = this.nonInteractivePlanEntities.filter(function(item) {
                return item.hull !== this.floorGeneratorHull;
            }.bind(this));
            this.threeScene.remove(this.floorGeneratorHull.group);
            this.floorGeneratorHull.dispose();
            this.floorGeneratorHull = null;
        },

        cleanUpDynamicHull() {
            if (!this.dynamicHull) {
                return;
            }

            this.detachSelection();
            this.planEntities = this.planEntities.filter(function(item) {
                return item.hull !== this.dynamicHull;
            }.bind(this));
            this.nonInteractivePlanEntities = this.nonInteractivePlanEntities.filter(function(item) {
                return item.hull !== this.dynamicHull;
            }.bind(this));
            this.threeScene.remove(this.dynamicHull.group);
            this.dynamicHull.dispose();
            this.dynamicHull = null;
        },

        /**
         * Cleanup the entire scene when the controller is left to go somewhere else
         **/
        cleanUpScene() {
            // Free the scene
            this.stopEnvUpdate();
            this.removeEntityTree(this.savaneScene);
            this.cleanUpStaticHull();
            this.cleanUpDynamicHull();
            this.cleanUpFloorGeneratorHull();
        },

        // Create a WebGL entity from a Savane entity and add it to entities arrays depending on the type
        createFromEntity(entity, config, lod, callback) {
            // Created object
            var object;

            // Depending on a entity type
            switch (entity.entityType) {
                // Arrangement object, create a WebglFurniture
                case Savane.SceneConstants.EntityType.ArrangementObject:
                    // If the entity isn't a ghost entity (i.e. entity we don't want to display)
                    if (!entity.isGhostForWebGL()) {
                        // Create a WebGL furniture and store it into the plan entity array
                        var item = new WebglFurniture(entity, config, lod, this.objLoader, this.binLoader, this, callback);
                        this.planEntities.push(item);
                        this.furnitures.push(item);
                        if (this.forceProbes) {
                            if (!item.probe) {
                                item.probe = new THREE.LightProbe();
                                var bbox = new THREE.Box3().setFromObject(item.object);
                                bbox.getCenter(item.probe.position);
                            }
                            this.threeScene.add(item.probe);
                        }
                        object = item.object;
                    }
                    break;

                // Light object, create a WebGL light entity
                case Savane.SceneConstants.EntityType.Light:
                    var lightItem = new WebGLLight(entity, this);
                    // Store it into the plan entity array
                    this.planEntities.push(lightItem);
                    object = lightItem.object;
                    // If the light item has a target, add the target to the scene (directional light)
                    if (lightItem.target !== null) {
                        this.sceneGroup.add(lightItem.target);
                    }
                    this.sceneGroup.add(lightItem.lightHelper);
                    break;

                // Render ramera object, create a WebGL camera and store it into the plan camera array
                case Savane.SceneConstants.EntityType.RenderCamera:
                    var webglCamera = new WebglCamera(entity);
                    this.planCameras.push(webglCamera);
                    object = webglCamera.group;
                    break;

                // Nothing for SketchBlock, they are useless for 3D
                case Savane.SceneConstants.EntityType.SketchBlock:
                    break;

                // Other object types will create generic WebGL entities and will be stored into the plan entity array
                default:
                    if (this.createWebglEntity(entity) == true) {
                        var item = new WebglEntity(entity, this);
                        this.planEntities.push(item);
                        object = item.object;
                    }
            }

            // Object not loaded successfully, quit now before trying to assign a name on an undefined object
            if (object === undefined) {
                return object;
            }

            // Add the entity id to the object name for debug purpose
            object.name = "" + entity.id;

            // Return loaded object
            return object;
        },

        // Get the WebGL entity equivalent to a Savane entity using its Id
        getPlanEntity(id) {
            // Parse all plan Camera objects to find the right camera entity Id
            for (var i = 0; i < this.planEntities.length; i++) {
                // Found ? Return it
                if (this.planEntities[i].entity.id === id) {
                    if (this.planEntities[i].hull && this.planEntities[i].hull instanceof FloorGeneratorHull) continue;
                    return this.planEntities[i];
                }
            }
            return null;
        },

        getPlanEntities(id) {
            var result = [];
            for (var i = 0; i < this.planEntities.length; i++) {
                if (this.planEntities[i].entity.id === id) {
                    result.push(this.planEntities[i]);
                }
            }
            return result;
        },

        // Get the WebGL camera equivalent to a Savane render camera using its Id
        getPlanCamera(id) {
            // Parse all plan Camera objects to find the right camera entity Id
            for (var i = 0; i < this.planCameras.length; i++) {
                // Found ? Return it
                if (this.planCameras[i].entity.id === id) {
                    return this.planCameras[i];
                }
            }
            return null;
        },

        resize() {
            var divWebgl = document.getElementById("plan-webgl-rt");
            if (!divWebgl) return;
            //update camera
            if (this.camera !== null) {
                if (this.camera.object.aspect !== undefined) {
                    this.camera.object.aspect = divWebgl.clientWidth / divWebgl.clientHeight;
                    if (!this.camera.entity.projection) {
                        this.camera.object.updateProjectionMatrix();
                    }
                }
                else {
                    this.camera.resize(divWebgl.clientWidth, divWebgl.clientHeight);
                }
            }
            this.defaultCamera.aspect = divWebgl.clientWidth / divWebgl.clientHeight;
            this.defaultCamera.updateProjectionMatrix();
            //Update rendering
            this.renderer.setSize(divWebgl.clientWidth, divWebgl.clientHeight);
            this.composer.setSize(divWebgl.clientWidth, divWebgl.clientHeight);
            if (this.fxaaPass) {
                this.fxaaPass.material.uniforms["resolution"].value.x = 1.0 / divWebgl.clientWidth;
                this.fxaaPass.material.uniforms["resolution"].value.y = 1.0 / divWebgl.clientHeight;
            }
            if (this.outlinePass) {
                this.outlinePass.setSize(divWebgl.clientWidth, divWebgl.clientHeight);
            }
            if (this.celShadingPass) {
                this.celShadingPass.setSize(divWebgl.clientWidth, divWebgl.clientHeight);
            }
            if (this.dof) {
                this.dof.setSize(divWebgl.clientWidth, divWebgl.clientHeight);
            }
            this.render();
        },

        updateCoatingParameters(component) {
            this.detachSelection();
            switch (component.componentType) {
                case Savane.ComponentConstants.ComponentType.Coating:
                case Savane.ComponentConstants.ComponentType.FloorCoatingArea:
                    if (this.staticHull) {
                        this.staticHull.updateCoatingParameters(component.entity);
                    }
                    if (this.dynamicHull) {
                        this.dynamicHull.updateCoatingParameters(component);
                    }
                    if (this.floorGeneratorHull) {
                        this.floorGeneratorHull.updateCoatingParameters(component.entity);
                    }
                    break;
                case Savane.ComponentConstants.ComponentType.Credence:
                case Savane.ComponentConstants.ComponentType.CoatingArea:
                    if (this.dynamicHull) {
                        this.dynamicHull.updateCoatingParameters(component);
                    }
                    break;
            }
            this.attachSelection();
            this.render();
        },

        updateHull(entity) {
            this.stopEnvUpdate();
            if (this.staticHull) {
                this.staticHull.update(entity);
            }
            if (this.dynamicHull) {
                this.dynamicHull.update(entity);
            }
            if (this.floorGeneratorHull) {
                this.floorGeneratorHull.update(entity);
            }
            this.updateSettings();
        },

        restoreRoomVisibility() {
            setTimeout(function() {
                for (var i = 0; i < this._visibleRooms.length; ++i) {
                    if (i === 0) {
                        this.hideOtherRoom(this._visibleRooms[i]);
                    } else {
                        this.showThisRoom(this._visibleRooms[i]);
                    }
                }
            }.bind(this), 500);
        },

        loadStaticHull(obj, savane) {
            if (!obj) {
                console.warning("Tried to load a static hull with null obj ! => cancelled");
                return;
            }
            this.stopEnvUpdate();
            if (this.staticHull) {
                this.threeScene.remove(this.staticHull.group);
                this.staticHull.dispose();
            }
            this.staticHull = StaticHull.create(obj, savane, this);
            this.threeScene.add(this.staticHull.group);
            this.updateSettings();
        },

        loadFloorGeneratorHull(obj, savane) {
            if (!obj) {
                console.warning("Tried to load a floor generator hull with null obj ! => cancelled");
                return;
            }
            this.stopEnvUpdate();
            if (this.floorGeneratorHull) {
                this.threeScene.remove(this.floorGeneratorHull.group);
                this.floorGeneratorHull.dispose();
            }
            this.floorGeneratorHull = FloorGeneratorHull.create(obj, savane, this);
            this.threeScene.add(this.floorGeneratorHull.group);
            this.updateSettings();
        },

        loadDynamicHull(obj, savane) {
            if (!obj) {
                console.warning("Tried to load a dynamic hull with null obj ! => cancelled");
                return;
            }
            this.stopEnvUpdate();
            if (this.dynamicHull) {
                this.threeScene.remove(this.dynamicHull.group);
                this.dynamicHull.dispose();
            }
            this.dynamicHull = DynamicHull.create(obj, savane, this);
            this.threeScene.add(this.dynamicHull.group);
        },

        stopEnvUpdate() {
            if (this.settings.mirrors) {
                Lighting.enableReflectors(this.threeScene);
            }
            if (this._idleTimeout) {
                clearTimeout(this._idleTimeout);
                this._idleTimeout = null;
            }
            // stop updating envs
            for (var i = 0; i < this._idleHullTimeouts.length; ++i) {
                clearTimeout(this._idleHullTimeouts[i]);
            }
            this._idleHullTimeouts = [];

            for (var i = 0; i < this._idleObjectsTimeouts.length; ++i) {
                clearTimeout(this._idleObjectsTimeouts[i]);
            }
            this._idleObjectsTimeouts = [];
        },

        updateEnvs() {
            this.stopEnvUpdate();
            this._idleTimeout = setTimeout(function() {
                Lighting.disableReflectors(this.threeScene);
                Lighting.updateHullEnvs(this, function() {
                    if (this.settings.mirrors) {
                        Lighting.enableReflectors(this.threeScene);
                    }
                    if (!this.settings.interactiveProject) {
                        this.render();
                    }
                }.bind(this));
            }.bind(this), 1000);
        },

        hideExcludedObject(camera) {
            if (!camera) {
                return;
            }

            if (!camera.entity.excludedObjectIds) {
                return;
            }

            for (var i = 0; i < camera.entity.excludedObjectIds.length; ++i) {
                var entities = this.getPlanEntities(camera.entity.excludedObjectIds[i]);
                for (var j = 0; j < entities.length; ++j) {
                    var entity = entities[j];
                    if (!entity) {
                        continue;
                    }

                    this.setLayer(entity.object, 1);
                }
            }
        },

        excludedHullEntities() {
            var result = [];
            for (var i = 0; i < this.planEntities.length; ++i) {
                var item = this.planEntities[i];
                if (item.entity.isJoineryEntity() || item.entity.isWorktopEntity() || item.entity.isTechnicalElementObjectEntity() || item.entity.isStaircaseEntity() || item.entity.isGeometryPrimitiveEntity()) {
                    result.push(item);
                }
            }

            return result;
        },

        showExcludedObject() {
            for (var i = 0; i < this.furnitures.length; ++i) {
                if (!this.furnitures[i].object) {
                    continue;
                }

                this.setLayer(this.furnitures[i].object, 0);
            }
            var excludedHullItems = this.excludedHullEntities();
            for (var i = 0; i < excludedHullItems.length; ++i) {
                if (!excludedHullItems[i].object) {
                    continue;
                }

                this.setLayer(excludedHullItems[i].object, 0);
            }
        },

        hideArrangements() {
            for (var i = 0; i < this.furnitures.length; ++i) {
                var furniture = this.furnitures[i];
                this.setLayer(furniture.object, 1);
            }
        },

        showArrangements(displayCurrentFloor) {
          if (displayCurrentFloor) {
              this.filterArrangements(PlanManager.getInstance().world.currentScene.currentFloor);
          }
          else {
              for (var i = 0; i < this.furnitures.length; ++i) {
                  var furniture = this.furnitures[i];
                  this.setLayer(furniture.object, 0);
              }
          }
        },

        displayErrorHangType() {
            Savane.eventsManager.dispatch(Savane.Events.DISPLAY_ALERT, { message: 'Ce matériau ne peut pas être appliqué sur cette surface' });
        },

        _isCoatingDropOnTechnicalElementAllowed(entity) {
            return entity.objectId === Savane.SceneConstants.TechnicalElementType.radiator ||
                entity.objectId === Savane.SceneConstants.TechnicalElementType.beam ||
                entity.objectId === Savane.SceneConstants.TechnicalElementType.frame ||
                entity.objectId === Savane.SceneConstants.TechnicalElementType.pole ||
                entity.objectId === Savane.SceneConstants.TechnicalElementType.rosette ||
                entity.objectId === Savane.SceneConstants.TechnicalElementType.guardrail ||
                entity.objectId === Savane.SceneConstants.TechnicalElementType.wallDecoration ||
                entity.objectId === Savane.SceneConstants.TechnicalElementType.ceilingBox ||
                entity.objectId === Savane.SceneConstants.TechnicalElementType.airConditioner;
        },

        _getWallHangType(object, materialIndex, entity) {
            var hangType;
            var usemtlName;

            if (entity.isWallEntity()) {
                if (entity.isSpecialWall) {
                    hangType = Savane.Coating.HangType.usemtl;
                    if (Array.isArray(object.material)) {
                        usemtlName = object.material[materialIndex].name;
                    }
                    else {
                        usemtlName = object.material.name;
                    }
                } else if (object.name.indexOf("Slope") !== -1) {
                    if (object.name.indexOf("Direct") === -1) {
                        hangType = Savane.Coating.HangType.slopeUndirect;
                    }
                    else {
                        hangType = Savane.Coating.HangType.slopeDirect;
                    }
                } else if (object.name.indexOf("Plinth") !== -1) {
                    if (object.name.indexOf("Direct") === -1) {
                        hangType = Savane.Coating.HangType.plinthUndirect;
                    }
                    else {
                        hangType = Savane.Coating.HangType.plinthDirect;
                    }
                } else if (object.name.indexOf("Cornice") !== -1) {
                    if (object.name.indexOf("Direct") === -1) {
                        hangType = Savane.Coating.HangType.corniceUndirect;
                    }
                    else {
                        hangType = Savane.Coating.HangType.corniceDirect;
                    }
                } else if (object.name.indexOf("Top") !== -1) {
                    hangType = Savane.Coating.HangType.wallTop;
                } else if (object.name.indexOf("Bottom") !== -1) {
                    hangType = Savane.Coating.HangType.wallBottom;
                } else if (object.name.indexOf("Left") !== -1) {
                    hangType = Savane.Coating.HangType.wallLeftSide;
                } else if (object.name.indexOf("Right") !== -1) {
                    hangType = Savane.Coating.HangType.wallRightSide;
                } else {
                    if (object.name.indexOf("Direct") === -1) {
                        hangType = Savane.Coating.HangType.wallUndirect;
                    } else {
                        hangType = Savane.Coating.HangType.wallDirect;
                    }
                }
            }

            return { hangType, usemtlName };
        },

        _getCoatingHangType(object, materialIndex, coating, entity) {
            var hangType;
            var usemtlName;
            var changeCoatingAllowed = false;

            switch (entity.entityType) {
                case Savane.SceneConstants.EntityType.Staircase:
                    hangType = Savane.Coating.HangType.usemtl;
                    if (Array.isArray(object.material)) {
                        usemtlName = object.material[materialIndex].name;
                    }
                    else {
                        usemtlName = object.material.name;
                    }
                    // We allow only materials 1 and 2 for FloorGenerators
                    if (coating && usemtlName === '3' && coating.isHpFloorGenerator) {
                        Savane.eventsManager.dispatch(Savane.Events.DISPLAY_ALERT, { message: "Les matériaux FloorGenerator sont sur ces parties de l'escalier." });
                        return null;
                    }
                    changeCoatingAllowed = true;
                    break;
                case Savane.SceneConstants.EntityType.Joinery:
                    if (coating && coating.isHpFloorGenerator) {
                        Savane.eventsManager.dispatch(Savane.Events.DISPLAY_ALERT, { message: 'Les matériaux FloorGenerator sont interdits sur les menuiseries.' });
                        return null;
                    }
                    hangType = Savane.Coating.HangType.joinery;
                    changeCoatingAllowed = true;
                    break;
                case Savane.SceneConstants.EntityType.GeometryPrimitive:
                    hangType = Savane.Coating.HangType.technicalElement;
                    changeCoatingAllowed = true;
                    break;
                case Savane.SceneConstants.EntityType.TechnicalElementObject:
                    if (coating && coating.isHpFloorGenerator) {
                        Savane.eventsManager.dispatch(Savane.Events.DISPLAY_ALERT, { message: 'Les matériaux FloorGenerator sont interdits sur les éléments techniques.' });
                        return null;
                    }
                    if (object.name.indexOf("Plinth") !== -1) {
                        hangType = Savane.Coating.HangType.plinthDirect;
                    }
                    else {
                        hangType = Savane.Coating.HangType.usemtl;
                        if (Array.isArray(object.material)) {
                            usemtlName = object.material[materialIndex].name;
                        }
                        else {
                            usemtlName = object.material.name;
                        }
                    }
                    changeCoatingAllowed = true;
                    break;
                case Savane.SceneConstants.EntityType.Wall:
                    if (hangType !== Savane.Coating.HangType.usemtl) {
                        if (coating && !coating.hang.wall && !this.keyPressed.x) {
                            this.displayErrorHangType();
                        }
                        else {
                            changeCoatingAllowed = true;
                        }
                    }
                    else {
                        if (coating && coating.isHpFloorGenerator) {
                            Savane.eventsManager.dispatch(Savane.Events.DISPLAY_ALERT, { message: 'Les matériaux FloorGenerator sont interdits sur les murs spéciaux.' });
                            return null;
                        }
                        changeCoatingAllowed = true;
                    }
                    break;
                case Savane.SceneConstants.EntityType.Room:
                    if (object.name.indexOf("Ceiling") > -1) {
                        if (coating && !coating.hang.ceiling && !this.keyPressed.x) {
                            this.displayErrorHangType();
                        }
                        else {
                            hangType = Savane.Coating.HangType.ceiling;
                            changeCoatingAllowed = true;
                        }
                    } else {
                        if (coating && !coating.hang.floor && !this.keyPressed.x) {
                            this.displayErrorHangType();
                        }
                        else {
                            hangType = Savane.Coating.HangType.floor;
                            changeCoatingAllowed = true;
                        }
                    }
                    break;
                case Savane.SceneConstants.EntityType.ArrangementObject:
                    hangType = Savane.Coating.HangType.arrangementObject;
                    changeCoatingAllowed = entity.coatingAllowed;
                    break;
            }

            return { hangType, usemtlName, changeCoatingAllowed };
        },

        _createNewCoatingAreaFromArea(area, coating, prototype) {
            var urlPreview = coating.url + coating._id + '/medias/maps/diffuse' + coating.choosenIndex + '.jpg';
            urlPreview = urlPreview.replace("assets", "coatings");
            var newArea = null;
            if (coating.colors !== undefined) {
                // New structure with realColor at the root of the AM coating
                newArea = new prototype(coating._id, coating.manufacturer, coating.retailer, coating.hangType, [coating.colors], coating.randomization, coating.isHpFloorGenerator ? coating.hpFloorGeneratorSettings : undefined, urlPreview);
            }
            else {
                // Old structure with colors in configs
                newArea = new prototype(coating._id, coating.manufacturer, coating.retailer, coating.hangType, coating.configs, coating.randomization, coating.isHpFloorGenerator ? coating.hpFloorGeneratorSettings : undefined, urlPreview);
            }

            newArea.vertices = area.vertices.slice();
            newArea.isDirectSide = area.isDirectSide;
            return newArea;
        },

        setCoatingOnDrop(x, y, coating, key, paste) {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            if (PlanManager.getInstance()._state.getAction() !== ActionStateEnum.idleDecoration && PlanManager.getInstance()._state.getAction() !== ActionStateEnum.FrontView && PlanManager.getInstance()._state.getAction() !== ActionStateEnum.FrontViewAddEntity) {
                return;
            }

            var camera = this.getActiveGLCamera();
            this.rayCaster.setFromCamera(new THREE.Vector3(x, y), camera);
            var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
            intersects = this.removeGizmoFromIntersectionResults(intersects, true);
            if (intersects.length === 0) {
                return;
            }

            var object = intersects[0].object;
            var entity = null;
            if (this.staticHull) {
                entity = this.staticHull.getEntityFromChild(object);
            }

            if (!entity && this.floorGeneratorHull) {
                entity = this.floorGeneratorHull.getEntityFromChild(object);
            }

            if (!entity && this.dynamicHull) {
                entity = this.dynamicHull.getEntityFromChild(object);
            }

            if (!entity) {
                //
                var idEntity = object.parent.userData.id;

                // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
                if (idEntity === undefined) {
                    idEntity = object.userData.id;
                }

                if (idEntity) {
                    var tEntity = this.getPlanEntity(idEntity);
                    if (tEntity && tEntity.entity.coatingAllowed) {
                        entity = tEntity.entity;
                    }
                }
            }

            // We are dropping on a coating area
            if (object.name.indexOf("CoatingArea") !== -1) {
                var coatingAreaParse = object.name.split('_');
                var command = null;
                if (entity.isWallEntity()) {
                    var coatingAreaIndex = coatingAreaParse[2] - 1;
                    var areaCoatings = entity.getComponents(Savane.ComponentConstants.ComponentType.CoatingArea);
                    var area = areaCoatings[coatingAreaIndex];
                    if (area) {
                        var newArea = this._createNewCoatingAreaFromArea(area, coating, Savane.CoatingArea);
                        command = new Savane.Commands.ReplaceComponentCommand(area, newArea, entity);
                    }
                } else if (entity.isFloorEntity()) {
                    var coatingAreaIndex;
                    if (object.name.startsWith("axo")) {
                        coatingAreaIndex = coatingAreaParse[3] - 1;
                    }
                    else {
                        coatingAreaIndex = coatingAreaParse[2] - 1;
                    }
                    var areaCoatings = entity.getComponents(Savane.ComponentConstants.ComponentType.FloorCoatingArea);
                    var area = areaCoatings[coatingAreaIndex];
                    if (area) {
                        var newArea = this._createNewCoatingAreaFromArea(area, coating, Savane.FloorCoatingArea);
                        newArea.altitude = area.altitude;
                        newArea.hangType = area.hangType;
                        command = new Savane.Commands.ReplaceComponentCommand(area, newArea, entity);
                    }
                }
                if (command && !paste) {
                    PlanManager.getInstance().executeCommand(command);
                }
                return command;
            }

            // Force custom coating logic on interfloor
            if (object.name.indexOf("InterFloor") !== -1) {
                entity = null;
            }

            if (!entity) {
                var id = object.parent.userData.id;

                // This is for objects that do not have VLP they'll only have the default mesh instead of the vlp itself, do not search for parent then
                if (!id) {
                    id = object.userData.id;
                }

                if (id) {
                    return;
                }
            }

            if (!entity) {
                if (coating.isHpFloorGenerator) {
                    Savane.eventsManager.dispatch(Savane.Events.DISPLAY_ALERT, { message: 'Les matériaux FloorGenerator sont interdits sur les éléments de la coque custom.' });
                    return;
                }
                // This is a custom coating
                var floor = PlanManager.getInstance().world.currentScene.currentFloor;
                var savaneCoating = null;
                if (coating.colors !== undefined) {
                    savaneCoating = new Savane.Coating(coating._id, coating.manufacturer, coating.retailer, Savane.Coating.HangType.custom, [coating.colors], coating.randomization, coating.isHpFloorGenerator ? coating.hpFloorGeneratorSettings : undefined);
                }
                else {
                    // Old structure with colors in configs
                    savaneCoating = new Savane.Coating(coating._id, coating.manufacturer, coating.retailer, Savane.Coating.HangType.custom, coating.configs, coating.randomization, coating.isHpFloorGenerator ? coating.hpFloorGeneratorSettings : undefined);
                }

                var name = object.name.replace(/_[a-fA-F0-9]{24}_\d+/, '');
                var customCoating = new Savane.CustomCoating(name, savaneCoating);
                var found = floor.getCustomCoating(name);
                if (found) {
                    found.coating = savaneCoating;
                } else {
                    floor.addComponent(customCoating);
                }
                if (this.staticHull) {
                    this.staticHull.setCustomCoating(object, customCoating);
                }
                PlanManager.getInstance().saveInCache();
                return;
            }

            var usemtlName;
            var hangType;

            var wallHangType = this._getWallHangType(object, intersects[0].face.materialIndex, entity);
            hangType = wallHangType.hangType;
            usemtlName = wallHangType.usemtlName;

            switch (key) {
                case 82: //r
                    if (entity.isWallEntity() && !entity.isSpecialWall) {
                        if (!coating.hang.wall && !this.keyPressed.x) {
                            this.displayErrorHangType();
                        }
                        else {
                            var parse = object.name.split('_');
                            var id = parse[2] - 0;
                            var room = entity.rooms[0];
                            for (var i = 0; i < entity.rooms.length; ++i) {
                                if (entity.rooms[i].id === id) {
                                    room = entity.rooms[i];
                                }
                            }
                            if (room) {
                                var candidates = [];
                                for (var i = 0; i < room.walls.length; ++i) {
                                    entity = room.walls[i];
                                    if (entity.isSpecialWall) {
                                        continue;
                                    }

                                    var direct = (entity.getHangTypeForRoom(room) === Savane.Coating.HangType.wallDirect);
                                    hangType = direct ? Savane.Coating.HangType.wallDirect : Savane.Coating.HangType.wallUndirect
                                    if (object.name.startsWith("axo_Cornice")) {
                                        hangType = direct ? Savane.Coating.HangType.corniceDirect : Savane.Coating.HangType.corniceUndirect;
                                    } else if (object.name.indexOf("Plinth") !== -1) {
                                        hangType = direct ? Savane.Coating.HangType.plinthDirect : Savane.Coating.HangType.plinthUndirect;
                                    }
                                    candidates.push({entity: entity, hangType: hangType});
                                }
                                for (var i = 0; i < room.nonRoomedWalls.length; ++i) {
                                    entity = room.nonRoomedWalls[i];
                                    if (entity.isSpecialWall) {
                                        continue;
                                    }

                                    var hangTypeDirect = Savane.Coating.HangType.wallDirect;
                                    var hangTypeUndirect = Savane.Coating.HangType.wallUndirect;
                                    if (object.name.startsWith("axo_Cornice")) {
                                        hangTypeDirect = Savane.Coating.HangType.corniceDirect;
                                        hangTypeUndirect = Savane.Coating.HangType.corniceUndirect;
                                    } else if (object.name.indexOf("Plinth") !== -1) {
                                        hangTypeDirect = Savane.Coating.HangType.plinthDirect;
                                        hangTypeUndirect = Savane.Coating.HangType.plinthUndirect;
                                    }
                                    candidates.push({entity: entity, hangType: hangTypeDirect});
                                    candidates.push({entity: entity, hangType: hangTypeUndirect});
                                    candidates.push({entity: entity, hangType: Savane.Coating.HangType.wallLeftSide});
                                    candidates.push({entity: entity, hangType: Savane.Coating.HangType.wallRightSide});
                                }

                                PlanManager.getInstance().executeCommand(new Savane.Commands.ChangeCoatingsCommand(candidates, coating));
                            }
                        }
                    } else if (entity.isTechnicalElementObjectEntity() && this._isCoatingDropOnTechnicalElementAllowed(entity)) {
                        if (coating.isHpFloorGenerator) {
                            Savane.eventsManager.dispatch(Savane.Events.DISPLAY_ALERT, { message: 'Les matériaux FloorGenerator sont interdits sur les éléments techniques.' });
                            return;
                        }
                        hangType = Savane.Coating.HangType.technicalElement;
                        var floor = entity.parent;
                        var room = Savane.roomManager.getRoomAtPosition(entity.position, floor);
                        var technicalElements = floor.technicalElementObjects;
                        var candidates = [];
                        for (var i = 0; i < technicalElements.length; ++i) {
                            var technicalElement = technicalElements[i];
                            if (technicalElement.objectId !== entity.objectId) continue;
                            if (room === Savane.roomManager.getRoomAtPosition(technicalElement.position, floor)) {
                                candidates.push({ entity: technicalElement, hangType: hangType });
                            }
                        }
                        PlanManager.getInstance().executeCommand(new Savane.Commands.ChangeCoatingsCommand(candidates, coating));
                    } else if (entity.isArrangementObjectEntity()) {
                        var coatingHangType = this._getCoatingHangType(object, intersects[0].face.materialIndex, coating, entity);
                        if (coatingHangType) {
                            hangType = coatingHangType.hangType;

                            var floor = entity.floor;
                            var room = Savane.roomManager.getRoomAtPosition(entity.position, floor);
                            var arrangements = floor.arrangementObjectsRec;
                            var candidates = [];

                            if (coatingHangType.changeCoatingAllowed) {
                                for (var i = 0 ; i < arrangements.length ; i++) {
                                    if (room === Savane.roomManager.getRoomAtPosition(arrangements[i].position, floor) && arrangements[i].coatingAllowed) {
                                        candidates.push({ entity: arrangements[i], hangType: hangType });
                                    }
                                }
                            }
                            PlanManager.getInstance().executeCommand(new Savane.Commands.ChangeCoatingsCommand(candidates, coating));
                        }
                    }
                    break;
                case 70: //f
                    var floor = PlanManager.getInstance().world.currentScene.currentFloor;
                    if (entity.isRoomEntity()) {
                        if (!coating.hang.floor && !this.keyPressed.x) {
                            this.displayErrorHangType();
                        }
                        else {
                            var candidates = [];
                            for (var i = 0; i < floor.rooms.length; ++i) {
                                entity = floor.rooms[i];
                                candidates.push({entity: entity, hangType: Savane.Coating.HangType.floor});
                            }
                            PlanManager.getInstance().executeCommand(new Savane.Commands.ChangeCoatingsCommand(candidates, coating));
                        }
                    } else if (entity.isTechnicalElementObjectEntity() && this._isCoatingDropOnTechnicalElementAllowed(entity)) {
                        if (coating.isHpFloorGenerator) {
                            Savane.eventsManager.dispatch(Savane.Events.DISPLAY_ALERT, { message: 'Les matériaux FloorGenerator sont interdits sur les éléments techniques.' });
                            return;
                        }
                        hangType = Savane.Coating.HangType.technicalElement;
                        var floor = entity.parent;
                        var technicalElements = floor.technicalElementObjects;
                        var candidates = [];
                        for (var i = 0; i < technicalElements.length; ++i) {
                            var technicalElement = technicalElements[i];
                            if (technicalElement.objectId !== entity.objectId) continue;
                            candidates.push({ entity: technicalElement, hangType: hangType });
                        }
                        PlanManager.getInstance().executeCommand(new Savane.Commands.ChangeCoatingsCommand(candidates, coating));
                    } else if (entity.isArrangementObjectEntity()) {
                        var coatingHangType = this._getCoatingHangType(object, intersects[0].face.materialIndex, coating, entity);
                        if (coatingHangType) {
                            hangType = coatingHangType.hangType;

                            var floor = entity.floor;
                            var room = Savane.roomManager.getRoomAtPosition(entity.position, floor);
                            var arrangements = floor.arrangementObjectsRec;
                            var candidates = [];

                            if (coatingHangType.changeCoatingAllowed) {
                                for (var i = 0 ; i < arrangements.length ; i++) {
                                    if (arrangements[i].coatingAllowed) {
                                        candidates.push({ entity: arrangements[i], hangType: hangType });
                                    }
                                }
                            }
                            PlanManager.getInstance().executeCommand(new Savane.Commands.ChangeCoatingsCommand(candidates, coating));
                        }
                    } else {
                        if (!coating.hang.wall && !this.keyPressed.x) {
                            this.displayErrorHangType();
                        }
                        else {
                             if (!entity.isSpecialWall)
                             {
                                var hangTypeDirect = Savane.Coating.HangType.wallDirect;
                                var hangTypeUndirect = Savane.Coating.HangType.wallUndirect;
                                if (object.name.startsWith("axo_Cornice")) {
                                    hangTypeDirect = Savane.Coating.HangType.corniceDirect;
                                    hangTypeUndirect = Savane.Coating.HangType.corniceUndirect;
                                } else if (object.name.indexOf("Plinth") !== -1) {
                                    hangTypeDirect = Savane.Coating.HangType.plinthDirect;
                                    hangTypeUndirect = Savane.Coating.HangType.plinthUndirect;
                                }

                                var walls = floor.walls;
                                var candidates = [];
                                for (var i = 0; i < walls.length; ++i) {
                                    entity = walls[i];
                                    if (entity.isSpecialWall) {
                                        continue;
                                    }

                                    candidates.push({entity: entity, hangType: hangTypeDirect});
                                    candidates.push({entity: entity, hangType: hangTypeUndirect});
                                }

                                var nonRoomedWalls = floor.nonRoomedWalls;
                                for (var i = 0; i < nonRoomedWalls.length; ++i) {
                                    entity = nonRoomedWalls[i];
                                    if (entity.isSpecialWall) {
                                        continue;
                                    }

                                    candidates.push({entity: entity, hangType: hangTypeDirect});
                                    candidates.push({entity: entity, hangType: hangTypeUndirect});
                                    candidates.push({entity: entity, hangType: Savane.Coating.HangType.wallLeftSide});
                                    candidates.push({entity: entity, hangType: Savane.Coating.HangType.wallRightSide});
                                }

                                PlanManager.getInstance().executeCommand(new Savane.Commands.ChangeCoatingsCommand(candidates, coating));
                            }
                        }
                    }
                    break;
                default:
                    var coatingHangType = this._getCoatingHangType(object, intersects[0].face.materialIndex, coating, entity);
                    if (coatingHangType) {
                        hangType = coatingHangType.hangType !== undefined ? coatingHangType.hangType : hangType;
                        usemtlName = coatingHangType.usemtlName !== undefined ? coatingHangType.usemtlName : usemtlName;

                        if (coatingHangType.changeCoatingAllowed) {
                            var changeCoatingCommand = new Savane.Commands.ChangeCoatingCommand(entity, coating, hangType, usemtlName);
                            if (!paste) {
                                PlanManager.getInstance().executeCommand(changeCoatingCommand);
                            } else {
                                return changeCoatingCommand;
                            }
                        }
                    }
                    break;
            }
        },

        setEntityOnDrop(x, y, item) {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            Savane.eventsManager.dispatch(Savane.Events.DRAG_OVER_WEBGL);
            if (PlanManager.getInstance()._state.getAction() !== ActionStateEnum.idleDecoration && PlanManager.getInstance()._state.getAction() !== ActionStateEnum.FrontView && PlanManager.getInstance()._state.getAction() !== ActionStateEnum.FrontViewAddEntity) {
                return;
            }

            var camera = this.getActiveGLCamera();
            this.rayCaster.setFromCamera(new THREE.Vector3(x, y), camera);
            var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
            intersects = this.removeGizmoFromIntersectionResults(intersects, true);
            if (intersects.length === 0) {
                return;
            }

            this.mousePickPosition = new THREE.Vector3();
            this.updateEntityPosition(item, null, intersects[0]);

            var entity = null;
            if (this.staticHull !== null) {
                entity = this.staticHull.getEntityFromChild(intersects[0].object)
            }
            if (entity === null && this.dynamicHull !== null) {
                entity = this.dynamicHull.getEntityFromChild(intersects[0].object);
            }
            if (entity === null && this.floorGeneratorHull !== null) {
                entity = this.floorGeneratorHull.getEntityFromChild(intersects[0].object);
            }

            var parent = entity;

            if (entity && entity.floor) {
                parent = entity.floor;
                if (parent.id !== PlanManager.getInstance().world.currentScene.currentFloor.id) {
                    PlanManager.getInstance().setCurrentFloorByIndex(parent.id, false, true);
                }
            }
            if (item.isArrangementObjectEntity() || item.isArrangementGroupEntity()) {
                var itemPosition = item.position;
                switch(item.anchor[2]) {
                    case -1:
                        itemPosition[2] -= ((item.height / 2) - 10);
                        break;

                    case 1:
                    case 2:
                        itemPosition[2] += ((item.height / 2) - 10);
                        break;
                }
                var floor = PlanManager.getInstance().world.currentScene.getFloorAtPosition(itemPosition);
                if (floor) {
                    if (floor.id !== PlanManager.getInstance().world.currentScene.currentFloor.id) {
                        PlanManager.getInstance().setCurrentFloorByIndex(floor.id, false, true);
                    }
                    parent = Savane.roomManager.getRoomAtPosition(item.position, floor);
                }
                else {
                    parent = null;
                }
            }
            if (!parent) {
                parent = PlanManager.getInstance().world.currentScene.currentFloor;
            }
            if (item.isRenderCameraEntity()) {
                item.updateCameraNb(PlanManager.getInstance().world);
            }

            item.isAnchorActive = false;
            PlanManager.getInstance().executeCommand(new Savane.Commands.AddEntityCommand(item, parent, true, true));
        },

        _getArrangementObjects(entity) {
            var result = [];

            if (entity.isArrangementGroupEntity()) {
                for (var i = 0; i < entity.children.length; ++i) {

                }
            }

            return result;
        },

        _isTransparent(object) {
            if (Array.isArray(object.material)) {
                for (var i = 0; i < object.material.length; ++i) {
                    if (object.material[i].transparent) {
                        return true;
                    }
                }
            } else if (object.material) {
                return object.material.transparent;
            }

            return false;
        },

        _getBox3CenterAndCorners(box) {
            var result = [];
            var center = new THREE.Vector3();
            box.getCenter(center);
            result.push(center);

            result.push(box.min);
            result.push(box.max);

            var corner = new THREE.Vector3().copy(box.min);
            corner.x = box.max.x;
            result.push(corner);
            corner = new THREE.Vector3().copy(box.min);
            corner.x = box.max.x;
            corner.y = box.max.y;
            result.push(corner);
            corner = new THREE.Vector3().copy(box.min);
            corner.y = box.max.y;
            result.push(corner);

            corner = new THREE.Vector3().copy(box.max);
            corner.x = box.min.x;
            result.push(corner);
            corner = new THREE.Vector3().copy(box.max);
            corner.x = box.min.x;
            corner.y = box.min.y;
            result.push(corner);
            corner = new THREE.Vector3().copy(box.max);
            corner.y = box.min.y;
            result.push(corner);

            return result;
        },

        computeCameraShoppingList(id) {
            if (!this.staticHull) {
                return;
            }

            // App electron (i.e. designerApp) we do not compute shoping lists for cameras
            if (navigator.userAgent.toLowerCase().indexOf('electron') > -1) {
                return;
            }

            //console.time('CameraSL');

            // Hide all furnitures to prevent furniture being masked by another
            this.furnitures.forEach(furniture => { this.setLayer(furniture.object, 1) });

            // Filter ghost and generic and cuisinella objects
            var filteredFurnitures = this.furnitures.filter(furniture => {
                if (furniture.entity.isGhost() || (furniture.entity.manufacturer && (furniture.entity.manufacturer.name === 'Generic' || furniture.entity.manufacturer.name === 'Cuisinella'))) {
                    return(false);
                }
                return(true);
            });

            for (var i = 0; i < this.planCameras.length; ++i) {
                var camera = this.planCameras[i];

                if (id) {
                    if (camera.entity._id !== id) {
                        continue;
                    }
                }

                // Empty camera shopping list now
                camera.entity.shoppingList = [];
                camera.entity.entityShoppingList = [];

                // Not after of before/after then leave now, 360 and axo don't hve shopping lists
                if (camera.entity.cameraType !== Savane.SceneConstants.CameraType.Perspective && camera.entity.cameraType !== Savane.SceneConstants.CameraType.PhotoRender) {
                    continue;
                }

                camera.updateProjectionMatrix();
                var shoppingList = [];
                var entityShoppingList = [];

                var cameraFloor = camera.entity.floor;

                // Hide floor generator hull
                if (this.floorGeneratorHull) {
                    this.setLayer(this.floorGeneratorHull.group, 1);
                }
                // Hide static hull
                this.staticHull.filterFloor(cameraFloor);
                // Hide dynamic hull
                if (this.dynamicHull) {
                    this.dynamicHull.filterFloor(cameraFloor);
                }

                {
                    // arrangement collect
                    for (var j = 0; j < filteredFurnitures.length; ++j) {
                        var furniture = filteredFurnitures[j];

                        if (cameraFloor.id !== furniture.entity.floor.id) {
                            continue;
                        }
                        if (camera.entity.excludedObjectIds.indexOf(furniture.entity.id) !== -1) {
                            continue;
                        }

                        var bbox = new THREE.Box3().setFromObject(furniture.object);
                        var points = this._getBox3CenterAndCorners(bbox);
                        var found = false;
                        for (var k = 0; k < points.length; ++k) {
                            var center = points[k].clone();
                            var NDC = center.clone();
                            NDC.project(camera.object);
                            if (NDC.x < -1 || NDC.x > 1 || NDC.y < -1 || NDC.y > 1) {
                                continue;
                            }

                            this.setLayer(furniture.object, 0);
                            var cameraDirection = new THREE.Vector3();
                            camera.object.getWorldDirection(cameraDirection);
                            var rayDirection = new THREE.Vector3().subVectors(center, camera.object.position).normalize();
                            if (cameraDirection.dot(rayDirection) < 0) {
                                continue;
                            }
                            this.rayCaster.set(camera.object.position, rayDirection);
                            var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
                            this.setLayer(furniture.object, 1);
                            intersects = this.removeGizmoFromIntersectionResults(intersects, true);
                            var cameraDistance = center.distanceTo(camera.object.position);
                            if (intersects.length === 0) {
                                if (shoppingList.indexOf(furniture.entity.objectId) === -1) {
                                    shoppingList.push(furniture.entity.objectId);
                                    if (furniture.entity.customization && furniture.entity.customization.parts) {
                                        for (var m = 0; m < furniture.entity.customization.parts.length; ++m) {
                                            var part = furniture.entity.customization.parts[m];
                                            if (shoppingList.indexOf(part.id_coating) === -1) {
                                                shoppingList.push(part.id_coating);
                                            }
                                        }
                                    }
                                    found = true;
                                }
                                if (entityShoppingList.indexOf(furniture.entity.id) === -1) {
                                    entityShoppingList.push(furniture.entity.id);
                                }
                            }
                            for (var l = 0; l < intersects.length; ++l) {
                                var intersect = intersects[l];
                                var intersectDistance = intersect.point.distanceTo(camera.object.position);
                                if (furniture.object.getObjectById(intersect.object.id) || intersectDistance > cameraDistance) {
                                    if (shoppingList.indexOf(furniture.entity.objectId) === -1) {
                                        shoppingList.push(furniture.entity.objectId);
                                        if (furniture.entity.customization && furniture.entity.customization.parts) {
                                            for (var m = 0; m < furniture.entity.customization.parts.length; ++m) {
                                                var part = furniture.entity.customization.parts[m];
                                                if (shoppingList.indexOf(part.id_coating) === -1) {
                                                    shoppingList.push(part.id_coating);
                                                }
                                            }
                                        }
                                        found = true;
                                    }
                                    if (entityShoppingList.indexOf(furniture.entity.id) === -1) {
                                        entityShoppingList.push(furniture.entity.id);
                                    }
                                    break;
                                }
                                var entity = this.staticHull.getEntityFromChild(intersect.object);
                                if (entity && entity.isJoineryEntity()) {
                                    if (entity.isExterior) {
                                        break;
                                    }
                                    if (this._isTransparent(intersect.object)) {
                                        continue;
                                    }
                                } else {
                                    break;
                                }
                            }
                            if (found) break;
                        }
                    }
                }

                // Unhide floor generator hull
                if (this.floorGeneratorHull) {
                    this.setLayer(this.floorGeneratorHull.group, 0);
                }

                {   // coating collect
                    var step = 100;
                    if (camera.entity.hd) {
                        step *= 2;
                    }
                    for (var y = 0; y < camera.entity.renderHeight; y += step) {
                        var x = ((y / step) % 2) === 1 ? 0 : (step / 2);
                        for ( ; x < camera.entity.renderWidth; x += step) {
                            var NDC = new THREE.Vector3(
                                ((x / camera.entity.renderWidth) - 0.5) * 2,
                                ((y / camera.entity.renderHeight) - 0.5) * 2,
                                0
                            )
                            NDC.unproject(camera.object);
                            var rayDirection = new THREE.Vector3().subVectors(NDC, camera.object.position).normalize();
                            this.rayCaster.set(camera.object.position, rayDirection);
                            var intersects = this.rayCaster.intersectObjects(this.threeScene.children, true);
                            intersects = this.removeGizmoFromIntersectionResults(intersects, true);
                            for (var l = 0; l < intersects.length; ++l) {
                                var intersect = intersects[l];
                                // hull entity
                                var hullEntity = this.staticHull.getEntityFromChild(intersect.object);
                                if (!hullEntity && this.dynamicHull) {
                                    hullEntity = this.dynamicHull.getEntityFromChild(intersect.object);
                                }
                                if (!hullEntity && this.floorGeneratorHull) {
                                    hullEntity = this.floorGeneratorHull.getEntityFromChild(intersect.object);
                                }

                                if (hullEntity && hullEntity.isJoineryEntity()) {
                                    if (hullEntity.isExterior) {
                                        break;
                                    }
                                    if (this._isTransparent(intersect.object)) {
                                        continue;
                                    }
                                } else {
                                    // coating
                                    if (intersect.object.coatingId) {
                                        if (shoppingList.indexOf(intersect.object.coatingId) === -1) {
                                            shoppingList.push(intersect.object.coatingId);
                                        }
                                    }
                                    break;
                                }
                            }
                        }
                    }
                }

                // Display static hull entirely again
                this.staticHull.filterFloor(null);

                // Display dynamic hull entirely again
                if (this.dynamicHull) {
                    this.dynamicHull.filterFloor(null);
                }
                // Assign shopping list
                camera.entity.shoppingList = shoppingList;
                camera.entity.entityShoppingList = entityShoppingList;
            }

            // Unhide all furnitures
            this.furnitures.forEach(furniture => { this.setLayer(furniture.object, 0) });

            this.displayCurrentFloor();
            Savane.eventsManager.dispatch(Savane.Events.HIDE_ARRANGEMENTS);
            Savane.eventsManager.dispatch(Savane.Events.HIDE_AXO);
            this.hideExcludedObject(this.camera);

            //console.timeEnd('CameraSL');
        },

        displayCurrentFloor(toggled) {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            if (toggled === undefined) {
                toggled = parseInt(Savane.SavaneCookie.getCookie("Rhinov-WebGL-displayCurrentFloor", "0")) === 0 ? false : true;
            }

            Savane.SavaneCookie.setCookie("Rhinov-WebGL-displayCurrentFloor", "" + (toggled ? 1 : 0));
            var floor = toggled ? PlanManager.getInstance().world.currentScene.currentFloor : null;
            if (this.staticHull !== null) {
                this.staticHull.filterFloor(floor);
            }

            if (this.dynamicHull != null) {
                this.dynamicHull.filterFloor(floor);
            }

            if (this.floorGeneratorHull != null) {
                this.floorGeneratorHull.filterFloor(floor);
            }

            this.filterArrangements(floor);
            this.sun.entity = PlanManager.getInstance().world.currentScene.currentFloor.getSun();
            this.sun.update();
            this.render();
        },

        filterArrangements(floor) {
            for (var i = 0; i < this.furnitures.length; ++i) {
                var furniture = this.furnitures[i];
                if (floor !== null) {
                    if (floor.getDeepChild(furniture.entity.id)) {
                        if (!this.camera || !this.camera.entity || this.camera.entity.excludedObjectIds.indexOf(furniture.entity.id) === -1) {
                            this.setLayer(furniture.object, 0);
                        }
                        else {
                            this.setLayer(furniture.object, 1);
                        }
                    } else {
                        this.setLayer(furniture.object, 1);
                    }
                } else {
                    if (!this.camera || !this.camera.entity || this.camera.entity.excludedObjectIds.indexOf(furniture.entity.id) === -1) {
                        this.setLayer(furniture.object, 0);
                    }
                    else {
                        this.setLayer(furniture.object, 1);
                    }
                }
            }
        },

        renderHeightText() {
            if (typeof PlanManager !== 'undefined') {
                var selection = PlanManager.getInstance().selectedEntities.slice();
                var canvas = document.getElementById("plan-webgl-rt-text-canvas");
                if (!canvas) {
                    return;
                }
                canvas.width = canvas.getBoundingClientRect().width;
                canvas.height = canvas.getBoundingClientRect().height;
                var ctx = canvas.getContext('2d');
                ctx.font = "22px sans-serif"
                ctx.fillStyle = 'black';
                ctx.strokeStyle = 'white';
                ctx.textAlign = 'center';
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                for (var i = 0; i < selection.length; ++i) {
                    var glEntity = this.getPlanEntity(selection[i].id);
                    if (!glEntity || !glEntity.entity.parent) {
                        continue;
                    }

                    var parentGlEntity = this.getPlanEntity(glEntity.entity.parent.id);
                    if (!parentGlEntity) {
                        continue;
                    }

                    var toLocal = parentGlEntity.object.matrixWorld.clone().invert();
                    var bbox = new THREE.Box3().setFromObject(glEntity.object);
                    var center = new THREE.Vector3();
                    bbox.getCenter(center);
                    var min = bbox.min.clone().applyMatrix4(toLocal);
                    var max = bbox.max.clone().applyMatrix4(toLocal);
                    var height = max.z - min.z;
                    var WP_UP = new THREE.Vector3(center.x, center.y, center.z + height / 2);
                    var WP_DOWN = new THREE.Vector3(center.x, center.y, center.z - height / 2);
                    var WS_UP = WP_UP.project(this.getActiveGLCamera());
                    WS_UP.addScalar(1); WS_UP.divideScalar(2); WS_UP.y = 1 - WS_UP.y; WS_UP.multiply(new THREE.Vector3(canvas.width, canvas.height, 0));
                    var WS_DOWN = WP_DOWN.project(this.getActiveGLCamera());
                    WS_DOWN.addScalar(1);  WS_DOWN.divideScalar(2); WS_DOWN.y = 1 - WS_DOWN.y; WS_DOWN.multiply(new THREE.Vector3(canvas.width, canvas.height, 0));

                    ctx.fillText((max.z * 10).toFixed(0) + "cm", WS_UP.x, WS_UP.y);
                    ctx.strokeText((max.z *10).toFixed(0) + "cm", WS_UP.x, WS_UP.y);
                    ctx.fillText((min.z * 10).toFixed(0) + "cm", WS_DOWN.x, WS_DOWN.y);
                    ctx.strokeText((min.z * 10).toFixed(0) + "cm", WS_DOWN.x, WS_DOWN.y);
                }
            }
        },

        hideOtherRoom(id) {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            var entities = this.planEntities.concat(this.nonInteractivePlanEntities);
            if (this.staticHull) {
                entities = entities.concat(this.staticHull.mirrors);
            }
            for (var i = 0; i < entities.length; ++i) {
                var planEntity = entities[i];
                var room = this.isEntityInRoom(planEntity.entity, id);
                if (!room || room.id !== id) {
                    this.setLayer(planEntity.object, 1);
                } else {
                    this.setLayer(planEntity.object, 0);
                }
            }
        },

        showThisRoom(id) {
            if (typeof PlanManager === 'undefined') {
                return;
            }

            var entities = this.planEntities.concat(this.nonInteractivePlanEntities);
            if (this.staticHull) {
                entities = entities.concat(this.staticHull.mirrors);
            }
            for (var i = 0; i < entities.length; ++i) {
                var planEntity = entities[i];
                var room = this.isEntityInRoom(planEntity.entity, id);
                if (room && room.id === id) {
                    this.setLayer(planEntity.object, 0);
                }
            }
        },

        frender() {
            if (this.destroyed) {
                return;
            }

            var activeCamera = this.getActiveGLCamera();
            var cameras;
            if (Array.isArray(activeCamera)) {
                cameras = activeCamera;
                this.renderer.autoClear = false;
                this.renderer.clear();
            } else {
                cameras = [activeCamera];
            }

            for (var i = 0; i < cameras.length; ++i) {
                var camera = cameras[i];
                if (Array.isArray(activeCamera)) {
                    this.renderer.setViewport(camera.viewport);
                }
                this.renderPass.camera = camera;
                if (this.celShadingPass) {
                    this.celShadingPass.camera = camera;
                }
                if (this.outlinePass) {
                    this.outlinePass.renderCamera = camera;
                }

                if (!this.settings.interactiveProject) {
                    this.selectionBox.camera = camera;
                }
                this.sun.update();
                if (this.camera) {
                    // apply shift
                    this.camera.updateProjectionMatrix();
                }
                this.composer.render();
            }

            if (this.displayHeight) {
                this.renderHeightText();
            }
            this.stats.update();
        },

        render() {
            if (this.requestID) {
                return;
            }

            this.requestID = window.requestAnimationFrame(function() {
                this.frender();
                this.requestID = null;
            }.bind(this));
        },

        _resizeCallback(event) {
            this.resize();
            event.stopPropagation();
        },

        /**
         * MUST BE CALLED TO UNREGISTER EVENTS
         *
         **/
        destroy() {
            this.destroyed = true;
            window.removeEventListener("resize", this.resizeListener);
            Savane.eventsManager.removeListener(this.mouseDownListener);
            Savane.eventsManager.removeListener(this.mouseDoubleClickListener);
            Savane.eventsManager.removeListener(this.mouseUpListener);
            Savane.eventsManager.removeListener(this.mouseMoveListener);
            Savane.eventsManager.removeListener(this.unselectItemsListener);
            Savane.eventsManager.removeListener(this.selectItemsListener);
            Savane.eventsManager.removeListener(this.snapUpdatedListener);
            Savane.eventsManager.removeListener(this.hideOtherRoomListener);
            Savane.eventsManager.removeListener(this.showAllRoomListener);
            Savane.eventsManager.removeListener(this.showThisRoomListener);

            this.gizmo.dispose();
            this.sun.dispose();
            this.cleanUpScene();
            if (this.renderTarget) {
                this.renderTarget.dispose();
            }

            this.composer.dispose();
            if (this.outlinePass) {
                this.outlinePass.dispose();
            }
            if (this.celShadingPass) {
                this.celShadingPass.dispose();
            }
            if (this.fxaaPass) {
                this.fxaaPass.dispose();
            }
            if (this.gammaCorrectionPass) {
                this.gammaCorrectionPass.dispose();
            }
            if (this.dof) {
                this.dof.dispose();
            }

            holdoutTexture.dispose();
            holdoutMaterial.dispose();

            this.renderer.dispose();
            for (var i = 0; i < this.renderer.info.programs.length; ++i) {
                this.renderer.info.programs[i].destroy();
            }
            this.renderer.forceContextLoss();
            this.renderer.domElement.parentElement.remove();
            this.renderer.context = null;
            this.renderer.domElement = null;
        }
    }
