/**
 * Created by rhinov14e on 26/06/2018.
 *
 * Management of WebGL lights to emulate final rendering
 */

// This will receive a savane entity
function WebGLLight(entity, scene)
{
    // Init all fields, WebGL light object
    this.object     = null;
    // Light entity creating this WebGL object
    this.entity     = entity;
    // Light type to create
    this.lightType  = entity.lightType;
    // Light target (if any since this is only for directional/plane lights)
    this.target     = null;
    // Light helper
    this.lightHelper = null;

    this.scene = scene;

    this.firstUpdate = 10;

    // Create the light object
    this.createLight();
    // And update the object
    this.update();
}

WebGLLight.prototype =
{
    constructor : WebGLLight,

    // Create the light WebGL object
    createLight : function()
    {
        // Extract light color with RGB model in a single color
        var color = new THREE.Color(((this.entity.colorR * 255) << 16) + ((this.entity.colorG * 255) << 8) + (this.entity.colorB * 255)).convertSRGBToLinear();
        // Create the correct light
        switch (this.lightType)
        {
            // Domes and sphere and like bulbs i.e. point lights with no direction
            case Savane.SceneConstants.LightType.Dome:
            case Savane.SceneConstants.LightType.Sphere:
                this.object       = new THREE.PointLight(color, this.entity.intensity * 0.2, 0, 2);
                this.lightHelper  = new THREE.PointLightHelper(this.object, 0.7, color);
            break;

            // Probably deal with it with another light type (RectAreaLight but couldn't get it to work for the moment)
            case Savane.SceneConstants.LightType.Plane:
                this.object       = new THREE.SpotLight(color, this.entity.intensity, 0, Math.PI / 6, 0.2, 2);
                this.lightHelper  = new THREE.SpotLightHelper(this.object, color);
                // Turn the cone of the helper by Math.PI otherwise it is inverted (obcure why)
                this.lightHelper.cone.rotation.y = Math.PI;
                // Create the light target
                this.target = new THREE.Object3D();
                this.object.target = this.target;
                // And compute the target position
                this.updateTarget();
            break;
        }

        // This object creates shadows
        this.object.castShadow = false;
    },

    // Update light color
    updateColor : function()
    {
        this.object.color.set(new THREE.Color(((this.entity.colorR * 255) << 16) + ((this.entity.colorG * 255) << 8) + (this.entity.colorB * 255)).convertSRGBToLinear());
        // Update the color of the helper to keep consistency
        switch (this.lightType)
        {
            case Savane.SceneConstants.LightType.Dome:
            case Savane.SceneConstants.LightType.Sphere:
                this.lightHelper.material.color = this.object.color;
            break;

            case Savane.SceneConstants.LightType.Plane:
                this.lightHelper.cone.material.color = this.object.color;
            break;
        }
    },

    // Place the target of the light direction in the scene
    updateTarget : function()
    {
        // Light
        var lookAtInViewSpace = Savane.math.vec3.create();
        // Forward vector (use 0, 0, -1 if you have a right-handed coordinate system)
        Savane.math.vec3.set(lookAtInViewSpace, 0, 1, 0);
        var lookAtInWorldSpace = Savane.math.vec3.create();
        var lightInvertedMatrix = this.entity.transform.invertedGlobalMatrix;

        lightInvertedMatrix[12] = 0;
        lightInvertedMatrix[13] = 0;
        lightInvertedMatrix[14] = 0;
        Savane.math.vec3.transformMat4(lookAtInWorldSpace, lookAtInViewSpace, lightInvertedMatrix);
        this.target.position.x = this.object.position.x - lookAtInWorldSpace[0];
        this.target.position.y = this.object.position.y + lookAtInWorldSpace[1];
        this.target.position.z = this.object.position.z - lookAtInWorldSpace[2];
    },

    // Update the light if it changes
    update : function()
    {
        // Light type has changed, then reload webGL (we can't change the light type on the fly)
        if (this.lightType !== this.entity.lightType)
        {
            Savane.eventsManager.dispatch(Savane.Events.PROJECT_RELOAD);
            return;
        }

        // Reset light intensity
        switch (this.lightType)
        {
            case Savane.SceneConstants.LightType.Dome:
            case Savane.SceneConstants.LightType.Sphere:
                    this.object.intensity = this.entity.intensity * 0.2;
            break;

            case Savane.SceneConstants.LightType.Plane:
                    this.object.intensity = this.entity.intensity;
            break;
        }

        // Update THREE matrix based on savane matrix datas
        var localMatrix = this.entity.transform.localMatrix;

        // Convert matrix to three space and apply to object
        var X = new THREE.Vector3().set(localMatrix[0], localMatrix[1], localMatrix[2]).normalize();
        var Y = new THREE.Vector3().set(localMatrix[4], localMatrix[5], localMatrix[6]).normalize();
        var Z = new THREE.Vector3().set(localMatrix[8], localMatrix[9], localMatrix[10]).normalize();

        var basis = new THREE.Matrix4().makeBasis(X, Y, Z);
        this.object.quaternion.setFromRotationMatrix(basis);
        this.object.position.set(localMatrix[12] / 100, localMatrix[13] / 100, localMatrix[14] / 100);

        // Light look at in case of plane light
        if (this.entity.lightType === Savane.SceneConstants.LightType.Plane)
        {
            this.updateTarget();
        }

        // Final update light color
        this.updateColor();
        this.scene.updateEnvs();
    },

    // Apply back changes done in WebGL view into savane antity
    applyToEntity: function()
    {
      //Make a safe copy
      var mat = new THREE.Matrix4();
      mat.copy(this.object.matrix);

      //Generate array
      var array = [];
      mat.toArray(array);

      //Generate Savane matrix
      var savaneMatrix = Savane.math.mat4.create();

      // Fill it with the matrix from above
      Savane.math.mat4.set(savaneMatrix,
          array[0], array[1], array[2],  array[3],
          array[4], array[5], array[6], array[7],
          array[8], array[9], array[10],  array[11],
          array[12] * 100, array[13] * 100, array[14] * 100, array[15]);

      //FIXME use command ?
      this.entity.transform.localMatrix = savaneMatrix;
    },

    dispose: function() {
        this.object.dispose();
        this.lightHelper.dispose();
    }
}
