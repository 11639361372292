(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.PLAN_WEBGL = f()}})(function(){var define,module,exports;return (function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

exports.__esModule = true;
var _webglController = require("./webgl-controller");
Object.keys(_webglController).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _webglController[key]) return;
  exports[key] = _webglController[key];
});
var _webglUi = require("./webgl-ui");
Object.keys(_webglUi).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _webglUi[key]) return;
  exports[key] = _webglUi[key];
});

},{"./webgl-controller":2,"./webgl-ui":3}],2:[function(require,module,exports){
"use strict";

exports.__esModule = true;
exports.WebGLController = void 0;
class WebGLController {
  // BEGIN: getters and setters

  get jsonScene() {
    return this._jsonScene;
  }
  set jsonScene(newValue) {
    const oldValue = this._jsonScene;
    this._jsonScene = newValue;
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      if (!this._jsonScene) {
        return;
      }
      if (this._scene) {
        this._scene = Savane.JSONImporter.importScene(this._jsonScene);
        if (!this._webglScene) {
          this._init();
        }
        this._getHulls();
        this._webglScene.updateScene(this._scene);
      }
    }
  }
  get activeCameraId() {
    return this._activeCameraId;
  }
  set activeCameraId(newValue) {
    const oldValue = this._activeCameraId;
    this._activeCameraId = newValue;
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      if (newValue !== undefined && this._webglScene) {
        this._webglScene.updateCamera(newValue);
        if (this._interactiveProjectHandler) {
          this._interactiveProjectHandler.UpdateCamera();
        }
        if (this._loadedEntity) {
          if (Array.isArray(this._loadedEntity)) {
            for (let i = 0; i < this._loadedEntity.length; ++i) {
              this._webglScene.fitCamera(this._loadedEntity[i], i);
            }
          } else {
            this._webglScene.fitCamera(this._loadedEntity);
          }
        }
      }
    }
  }
  get entity() {
    return this._entity;
  }
  set entity(newValue) {
    const oldValue = this._entity;
    this._entity = newValue;
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      if (!this._webglScene) {
        return;
      }
      this._webglScene.defaultCamera.near = 0.01;
      this._webglScene.defaultCamera.updateProjectionMatrix();
      if (Array.isArray(this._loadedEntity)) {
        for (var i = 0; i < this._loadedEntity.length; ++i) {
          this._scene.deleteEntity(this._loadedEntity[i]);
          this._webglScene.removeEntity(this._loadedEntity[i]);
        }
      } else if (this._loadedEntity) {
        this._scene.deleteEntity(this._loadedEntity);
        this._webglScene.removeEntity(this._loadedEntity);
      }
      if (newValue !== undefined) {
        this._loadEntity(newValue, this.config, this.lod);
      }
      this._delegate.render();
    }
  }
  get entities() {
    return this._entities;
  }
  set entities(newValue) {
    const oldValue = this._entities;
    this._entities = newValue;
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      if (!this._webglScene) {
        return;
      }
      if (this._loadedEntity) {
        if (Array.isArray(this._loadEntity)) {
          for (let i = 0; i < this._loadedEntity.length; ++i) {
            this._scene.deleteEntity(this._loadedEntity[i]);
            this._webglScene.removeEntity(this._loadedEntity[i]);
          }
        } else if (this._loadedEntity) {
          this._scene.deleteEntity(this._loadedEntity);
          this._webglScene.removeEntity(this._loadedEntity);
        }
      }
      if (newValue && Array.isArray(newValue)) {
        this._webglScene.defaultCamera.near = 0.01;
        this._webglScene.defaultCamera.updateProjectionMatrix();
        const cameras = [];
        for (let i = 0; i < newValue.length; ++i) {
          const camera = this._webglScene.defaultCamera.clone();
          this._webglScene.setLayer(camera, i);
          camera.up.set(0, 0, 1);
          cameras.push(camera);
        }
        this._webglScene.cameraArray = cameras;
        this._resizeCameras();
        for (let i = 0; i < newValue.length; ++i) {
          const entity = newValue[i];
          this._loadEntity(entity.id, entity.config, entity.lod, i);
        }
      }
      this._delegate.render();
    }
  }
  get config() {
    return this._config;
  }
  set config(newValue) {
    const oldValue = this._config;
    this._config = newValue;
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      if (Array.isArray(this._loadedEntity)) {
        for (let i = 0; i < this._loadedEntity.length; ++i) {
          this._scene.deleteEntity(this._loadedEntity[i]);
          this._webglScene.removeEntity(this._loadedEntity[i]);
        }
      } else if (this._loadedEntity) {
        this._scene.deleteEntity(this._loadedEntity);
        this._webglScene.removeEntity(this._loadedEntity);
      }
      if (newValue !== undefined && this._loadedEntity) {
        if (Array.isArray(this._loadedEntity)) {
          for (let i = 0; i < this._loadedEntity.length; ++i) {
            this._loadEntity(this._loadedEntity[i].objectId, newValue, this.lod);
          }
        } else {
          this._loadEntity(this._loadedEntity.objectId, newValue, this.lod);
        }
      }
    }
  }
  get lod() {
    return this._lod;
  }
  set lod(newValue) {
    const oldValue = this._lod;
    this._lod = newValue;
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      if (Array.isArray(this._loadedEntity)) {
        for (let i = 0; i < this._loadedEntity.length; ++i) {
          this._scene.deleteEntity(this._loadedEntity[i]);
          this._webglScene.removeEntity(this._loadedEntity[i]);
        }
      } else if (this._loadedEntity) {
        this._scene.deleteEntity(this._loadedEntity);
        this._webglScene.removeEntity(this._loadedEntity);
      }
      if (newValue !== undefined && this._loadedEntity) {
        if (Array.isArray(this._loadedEntity)) {
          for (let i = 0; i < this._loadedEntity.length; ++i) {
            this._loadEntity(this._loadedEntity[i].objectId, this.config, newValue);
          }
        } else {
          this._loadEntity(this._loadedEntity.objectId, this.config, newValue);
        }
      }
    }
  }
  get imgProject() {
    return this._imgProject;
  }
  set imgProject(newValue) {
    this._imgProject = newValue;
    if (this._webglScene) {
      if (this._webglScene.camera && this._webglScene.camera.entity.cameraType === Savane.SceneConstants.CameraType.PhotoRender) {
        this._photoFromCurrentProjectNb = this._webglScene.camera.entity.cameraNb;
        const texture = new THREE.TextureLoader().load(this._getPhotoUrl());
        texture.colorSpace = THREE.SRGBColorSpace;
        this._webglScene.threeScene.background = texture;
        this._webglScene.toggleSky(false);
      }
    }
  }
  get leftPanel() {
    return this._leftPanel;
  }
  set leftPanel(newValue) {
    this._leftPanel = newValue;
    this._resize();
  }
  get deliverable() {
    return this._deliverable;
  }
  set deliverable(newValue) {
    this._deliverable = newValue;
  }
  get deliverableId() {
    return this._deliverableId;
  }
  set deliverableId(newValue) {
    this._deliverableId = newValue;
  }
  get user() {
    return this._user;
  }
  set user(newValue) {
    this._user = newValue;
  }
  get controls() {
    return this._controls;
  }
  set controls(newValue) {
    const oldValue = this._controls;
    this._controls = newValue;
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      this._delegate.render();
    }
  }
  get useCameraRatio() {
    return this._useCameraRatio;
  }
  set useCameraRatio(newValue) {
    const oldValue = this._useCameraRatio;
    this._useCameraRatio = newValue;
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      if (this._webglScene) {
        this._webglScene.useCameraRatio = this._useCameraRatio;
        this._webglScene.updateCamera(this.activeCameraId);
      }
    }
  }
  get noFocus() {
    return this._noFocus;
  }
  set noFocus(newValue) {
    this._noFocus = newValue;
  }
  get settings() {
    return this._settings;
  }
  set settings(newValue) {
    const oldValue = this._settings;
    this._settings = newValue;
    if (newValue !== undefined && JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      if (oldValue === undefined || oldValue.display !== newValue.display) {
        this.display(newValue.display);
      }
      if (oldValue === undefined || oldValue.meshLevel !== newValue.meshLevel) {
        this.meshLevel = newValue.meshLevel;
      }
      this._delegate.render();
    }
  }
  get meshLevel() {
    return this._settings.meshLevel;
  }
  set meshLevel(newValue) {
    this._settings.meshLevel = newValue;
    if (newValue !== undefined) {
      if (Array.isArray(this._loadedEntity)) {
        for (let i = 0; i < this._loadedEntity.length; ++i) {
          this._scene.deleteEntity(this._loadedEntity[i]);
          this._webglScene.removeEntity(this._loadedEntity[i]);
        }
      } else if (this._loadedEntity) {
        this._scene.deleteEntity(this._loadedEntity);
        this._webglScene.removeEntity(this._loadedEntity);
      }
      if (newValue !== undefined && this._loadedEntity) {
        if (Array.isArray(this._loadedEntity)) {
          for (let i = 0; i < this._loadedEntity.length; ++i) {
            this._loadEntity(this._loadedEntity[i].objectId, this.config, this.lod);
          }
        } else {
          this._loadEntity(this._loadedEntity.objectId, this.config, this.lod);
        }
      }
    }
  }
  get loaded() {
    return this._loaded;
  }
  set loaded(newValue) {
    const oldValue = this._loaded;
    this._loaded = newValue;
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      this._delegate.render();

      // required for realtime update in interactive project
      if (this._webglScene) {
        this._webglScene.loaded = newValue;
      }
    }
  }
  get zoom() {
    return this._zoom;
  }
  set zoom(newValue) {
    const oldValue = this._zoom;
    this._zoom = newValue;
    if (newValue !== undefined && JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      this._delegate.render();
    }
  }
  get thirdGrid() {
    return this._thirdGrid;
  }
  set thirdGrid(newValue) {
    const oldValue = this._thirdGrid;
    this._thirdGrid = newValue;
    if (newValue !== undefined && JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      this._delegate.render();
    }
  }
  get staticHullDatas() {
    return this._staticHullDatas;
  }
  set staticHullDatas(newValue) {
    this._staticHullDatas = newValue;
    if (newValue && this._webglScene) {
      this._webglScene.loadStaticHull(newValue, this._scene);
      this._displayCurrentFloorChanged(this._displayCurrentFloor);
      Savane.eventsManager.dispatch(Savane.Events.HIDE_AXO);
      this._webglScene.updateEnvs();
      this._webglScene.restoreRoomVisibility();
    }
  }
  get dynamicHullDatas() {
    return this._dynamicHullDatas;
  }
  set dynamicHullDatas(newValue) {
    this._dynamicHullDatas = newValue;
    if (newValue && this._webglScene) {
      this._webglScene.loadDynamicHull(newValue, this._scene);
      const floor = this._displayCurrentFloor && typeof PlanManager !== 'undefined' ? PlanManager.getInstance().world.currentScene.currentFloor : null;
      if (this._webglScene.dynamicHull != null) {
        this._webglScene.dynamicHull.filterFloor(floor);
      }
      Savane.eventsManager.dispatch(Savane.Events.HIDE_AXO);
      this._webglScene.updateEnvs();
    }
  }
  get floorGeneratorHullDatas() {
    return this._floorGeneratorHullDatas;
  }
  set floorGeneratorHullDatas(newValue) {
    this._floorGeneratorHullDatas = newValue;
    if (newValue && this._webglScene) {
      this._webglScene.loadFloorGeneratorHull(newValue, this._scene);
      const floor = this._displayCurrentFloor && typeof PlanManager !== 'undefined' ? PlanManager.getInstance().world.currentScene.currentFloor : null;
      if (this._webglScene.floorGeneratorHull != null) {
        this._webglScene.floorGeneratorHull.filterFloor(floor);
      }
      Savane.eventsManager.dispatch(Savane.Events.HIDE_AXO);
      if (this._interactiveProjectHandler && this._webglScene.camera) {
        if (this._webglScene.camera.entity.cameraType === Savane.SceneConstants.CameraType.Axonomic) {
          this._webglScene.floorGeneratorHull.hideCeiling();
        }
      }
      this._webglScene.updateEnvs();
    }
  }
  get cameraHeight() {
    return this._cameraHeight;
  }
  set cameraHeight(newValue) {
    const oldValue = this._cameraHeight;
    this._cameraHeight = newValue;
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      this._delegate.render();
    }
  }
  get cameraFocal() {
    return this._cameraFocal;
  }
  set cameraFocal(newValue) {
    const oldValue = this._cameraFocal;
    this._cameraFocal = newValue;
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      this._delegate.render();
    }
  }
  get photoOpacity() {
    return this._photoOpacity;
  }
  set photoOpacity(newValue) {
    const oldValue = this._photoOpacity;
    this._photoOpacity = newValue;
    if (oldValue !== newValue) {
      this._delegate.render();
    }
  }
  get transformOrigin() {
    return this._transformOrigin;
  }
  set transformOrigin(newValue) {
    const oldValue = this._transformOrigin;
    this._transformOrigin = newValue;
    if (oldValue !== newValue) {
      this._delegate.render();
    }
  }

  // END: getters and setters

  _updateHulls(event) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    if (typeof PlanManager !== 'undefined' && PlanManager.getInstance().state.actionEditEnded !== undefined) {
      PlanManager.getInstance().state.actionEditEnded();
      Savane.eventsManager.dispatch(Savane.Events.HIDE_TULIP, null);
    }
    this._getHulls();
  }
  _getStaticHull() {
    if (!this._scene || !this._webglScene) {
      return;
    }
    try {
      if (this._staticHullCanceller) {
        this._staticHullCanceller.abort();
      }
    } catch {}
    this._staticHullCanceller = new AbortController();

    // Bench static hull  request
    this._requestStaticHullStartTime = new Date().getTime();
    if (this._webglScene !== null) {
      this._webglScene.cleanUpStaticHull();
    }
    const SMART_URI = typeof HP_CONFIG !== 'undefined' ? HP_CONFIG.SMART_URI : 'https://smart.rhinovplanner.com/';
    const filtered_rooms = this._webglScene._visibleRooms.join();
    const content = JSON.stringify({
      id: this.deliverableId ? parseInt(this.deliverableId) : -1,
      index: typeof PlanManager !== 'undefined' ? PlanManager.getInstance().sceneIndex : 0,
      filtered_rooms: filtered_rooms ? filtered_rooms : null,
      rhinov_format: KitchenTool.VersionHandler.toHullFormat(Savane.JSONSerializer.serializeEntityWithFilters(this._scene, [Savane.SceneConstants.EntityType.FunctionalityChip, Savane.SceneConstants.EntityType.Light, Savane.SceneConstants.EntityType.RenderCamera, Savane.SceneConstants.EntityType.ArrangementZone, Savane.SceneConstants.EntityType.ArrangementDecorated, Savane.SceneConstants.EntityType.SketchBlock, Savane.SceneConstants.EntityType.WorkTop, Savane.SceneConstants.EntityType.UserPicture, Savane.SceneConstants.EntityType.Sun], [Savane.ComponentConstants.ComponentType.CoatingArea, Savane.ComponentConstants.ComponentType.TemplateImage], true))
    });
    let riserPath = SMART_URI + 'rise/unreal/static/';
    if (this.settings.hullbin) {
      riserPath = SMART_URI + 'rise/unreal/static/bin/';
    }

    //  Static hull
    fetch(riserPath, {
      method: "POST",
      signal: this._staticHullCanceller.signal,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': undefined
      },
      body: content
    }).then(response => {
      // Bench static hull  request
      const deltaTime = new Date().getTime() - this._requestStaticHullStartTime;

      // Request valid
      if (deltaTime >= 0) {
        // Accumulate static hull requests and duration
        this._scene._nbStaticHullRequests++;
        this._scene._totalTimeStaticHullRequests += deltaTime;
      }
      if (!this.settings.hullbin) {
        response.json().then(data => {
          this.staticHullDatas = data.obj;
          if (this._webglScene) {
            this._webglScene.updateSettings();
            this._webglScene.updateEnvs();
          }
          this._loadedHullCount += 1;
          if (this._loadedHullCount >= 3) {
            Savane.eventsManager.dispatch(Savane.Events.HULL_LOADED);
            if (this._webglScene) {
              this._webglScene.restoreRoomVisibility();
            }
          }
        });
      } else {
        response.text().then(data => {
          this.staticHullDatas = data;
          if (this._webglScene) {
            this._webglScene.updateSettings();
            this._webglScene.updateEnvs();
          }
          this._loadedHullCount += 1;
          if (this._loadedHullCount >= 3) {
            Savane.eventsManager.dispatch(Savane.Events.HULL_LOADED);
            if (this._webglScene) {
              this._webglScene.restoreRoomVisibility();
            }
          }
        });
      }
    }).catch(_ => {
      console.log("error static hull");
    });
  }
  _getFloorGeneratorHull() {
    if (!this._scene || !this._webglScene) {
      return;
    }
    try {
      if (this._floorGeneratorCanceller) {
        this._floorGeneratorCanceller.abort();
      }
    } catch {}
    ;
    this._floorGeneratorCanceller = new AbortController();

    // Bench floor hull  request
    this._requestFloorHullStartTime = new Date().getTime();
    if (this._webglScene !== null) {
      this._webglScene.cleanUpFloorGeneratorHull();
    }
    const SMART_URI = typeof HP_CONFIG !== 'undefined' ? HP_CONFIG.SMART_URI : 'https://smart.rhinovplanner.com/';
    const filtered_rooms = this._webglScene._visibleRooms.join();
    const content = JSON.stringify({
      id: this.deliverableId ? parseInt(this.deliverableId) : -1,
      index: typeof PlanManager !== 'undefined' ? PlanManager.getInstance().sceneIndex : 0,
      filtered_rooms: filtered_rooms ? filtered_rooms : null,
      rhinov_format: KitchenTool.VersionHandler.toHullFormat(Savane.JSONSerializer.serializeEntityWithFilters(this._scene, [Savane.SceneConstants.EntityType.FunctionalityChip, Savane.SceneConstants.EntityType.Light, Savane.SceneConstants.EntityType.RenderCamera, Savane.SceneConstants.EntityType.ArrangementZone, Savane.SceneConstants.EntityType.ArrangementDecorated, Savane.SceneConstants.EntityType.SketchBlock, Savane.SceneConstants.EntityType.WorkTop, Savane.SceneConstants.EntityType.UserPicture, Savane.SceneConstants.EntityType.Sun], [Savane.ComponentConstants.ComponentType.TemplateImage], true))
    });
    let riserPath = SMART_URI + 'rise/unreal/floorgenerator/';
    if (this.settings.hullbin) {
      riserPath = SMART_URI + 'rise/unreal/floorgenerator/bin/';
    }
    fetch(riserPath, {
      method: "POST",
      signal: this._floorGeneratorCanceller.signal,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': undefined
      },
      body: content
    }).then(response => {
      // Bench floor hull  request
      const deltaTime = new Date().getTime() - this._requestFloorHullStartTime;

      // Request valid
      if (deltaTime >= 0) {
        // Accumulate floor hull requests and duration
        this._scene._nbFloorHullRequests++;
        this._scene._totalTimeFloorHullRequests += deltaTime;
      }
      if (!this.settings.hullbin) {
        response.json().then(data => {
          this.floorGeneratorHullDatas = data.obj;
          this._loadedHullCount += 1;
          if (this._loadedHullCount >= 3) {
            Savane.eventsManager.dispatch(Savane.Events.HULL_LOADED);
            if (this._webglScene) {
              this._webglScene.restoreRoomVisibility();
            }
          }
        });
      } else {
        response.text().then(data => {
          this.floorGeneratorHullDatas = data;
          this._loadedHullCount += 1;
          if (this._loadedHullCount >= 3) {
            Savane.eventsManager.dispatch(Savane.Events.HULL_LOADED);
            if (this._webglScene) {
              this._webglScene.restoreRoomVisibility();
            }
          }
        });
      }
    }).catch(_ => {
      console.log("error floorgenerator");
    });
  }
  _getDynamicHull() {
    if (!this._scene || !this._webglScene) {
      return;
    }
    try {
      if (this._dynamicHullCanceller) {
        this._dynamicHullCanceller.abort();
      }
    } catch {}
    ;
    this._dynamicHullCanceller = new AbortController();

    // Bench dynamic hull  request
    this._requestDynamicHullStartTime = new Date().getTime();
    if (this._webglScene !== null) {
      this._webglScene.cleanUpDynamicHull();
    }
    const SMART_URI = typeof HP_CONFIG !== 'undefined' ? HP_CONFIG.SMART_URI : 'https://smart.rhinovplanner.com/';
    const filtered_rooms = this._webglScene._visibleRooms.join();
    const content = JSON.stringify({
      id: this.deliverableId ? parseInt(this.deliverableId) : -1,
      index: typeof PlanManager !== 'undefined' ? PlanManager.getInstance().sceneIndex : 0,
      filtered_rooms: filtered_rooms ? filtered_rooms : null,
      rhinov_format: KitchenTool.VersionHandler.toHullFormat(Savane.JSONSerializer.serializeEntityWithFilters(this._scene, [Savane.SceneConstants.EntityType.Staircase, Savane.SceneConstants.EntityType.Light, Savane.SceneConstants.EntityType.RenderCamera, Savane.SceneConstants.EntityType.UserPicture, Savane.SceneConstants.EntityType.Sun], [Savane.ComponentConstants.ComponentType.Functionality, Savane.ComponentConstants.ComponentType.TemplateImage], true))
    });
    let riserPath = SMART_URI + 'rise/unreal/dynamic/';
    if (this.settings.hullbin) {
      riserPath = SMART_URI + 'rise/unreal/dynamic/bin/';
    }
    fetch(riserPath, {
      method: "POST",
      signal: this._dynamicHullCanceller.signal,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': undefined
      },
      body: content
    }).then(response => {
      // Bench dynamic hull  request
      const deltaTime = new Date().getTime() - this._requestDynamicHullStartTime;

      // Request valid
      if (deltaTime >= 0) {
        // Accumulate dynamic hull requests and duration
        this._scene._nbDynamicHullRequests++;
        this._scene._totalTimeDynamicHullRequests += deltaTime;
      }
      if (!this.settings.hullbin) {
        response.json().then(data => {
          this.dynamicHullDatas = data.obj;
          this._loadedHullCount += 1;
          if (this._loadedHullCount >= 3) {
            Savane.eventsManager.dispatch(Savane.Events.HULL_LOADED);
            if (this._webglScene) {
              this._webglScene.restoreRoomVisibility();
            }
          }
        });
      } else {
        response.text().then(data => {
          this.dynamicHullDatas = data;
          this._loadedHullCount += 1;
          if (this._loadedHullCount >= 3) {
            Savane.eventsManager.dispatch(Savane.Events.HULL_LOADED);
            if (this._webglScene) {
              this._webglScene.restoreRoomVisibility();
            }
          }
        });
      }
    }).catch(_ => {
      console.log("error dynamic hull");
    });
  }
  _getHulls() {
    this._loadedHullCount = 0;
    this._getStaticHull();
    this._getDynamicHull();
    this._getFloorGeneratorHull();
  }
  displayHeight() {
    if (this._webglScene) {
      return this._webglScene.displayHeight;
    }
    return false;
  }
  displayCameraParameters() {
    if (this.settings && this.settings.interactiveProject) {
      return false;
    }
    if (this._webglScene) {
      return !!this._webglScene.camera;
    }
    return false;
  }

  //*************CAMERAS***********
  //Set to cocos camera
  _setCocosCamera() {
    const webglCanvas = document.getElementById('webglcanvas');
    const planWebglRt = document.getElementById('plan-webgl-rt');

    // Get the current dimensions of the elements
    const heightBuffer = webglCanvas.offsetHeight;
    const widthBuffer = webglCanvas.offsetWidth;

    // Set the camera state
    this._cameraState = CAMERA_STATES.COCOS;

    // Update the camera
    this._webglScene.updateCamera(null);

    // Set the dimensions of the elements
    webglCanvas.style.height = heightBuffer + 'px';
    webglCanvas.style.width = widthBuffer + 'px';
    planWebglRt.style.height = heightBuffer + 'px';
    planWebglRt.style.width = widthBuffer + 'px';
  }
  _blockEventPropagation($event) {
    $event.stopPropagation();
  }
  _updateSettings() {
    // Assign it to the rhinov cookie as a string
    Savane.SavaneCookie.setCookie("Rhinov-PlanWebGL-Settings", JSON.stringify(this.settings));
    if (this._webglScene) {
      this._webglScene.settings = this.settings;
      // Quality has changed, refresh the view to apply the change
      this._webglScene.updateSettings();
      if (this._loadedEntity) {
        if (Array.isArray(this._loadedEntity)) {
          for (var i = 0; i < this._loadedEntity.length; ++i) {
            this._webglScene.fitCamera(this._loadedEntity[i], i);
          }
        } else {
          this._webglScene.fitCamera(this._loadedEntity);
        }
      }
    }
  }
  _setFreeCamera($event) {
    $event.stopPropagation();
    this._cameraState = CAMERA_STATES.FREE;
    this._webglScene.updateCamera(null);
    Savane.eventsManager.dispatch(Savane.Events.CAMERA_RESIZED);
  }
  _gizmoSpace() {
    if (this._webglScene) {
      return this._webglScene.gizmo.space;
    }
    return 'world';
  }
  _gizmoEnabled() {
    if (this._webglScene) {
      return this._webglScene.gizmo.enabled;
    }
    return false;
  }
  _changeGizmoSpace(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this._webglScene) {
      if (this._webglScene.gizmo.mode === 'scale') {
        return;
      }
      if (this._webglScene.gizmo.space === 'world') {
        this._webglScene.gizmo.space = 'local';
      } else {
        this._webglScene.gizmo.space = 'world';
      }
    }
  }
  _togglePhysics(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this._webglScene) {
      this._webglScene.physicsEnabled = !this._webglScene.physicsEnabled;
    }
  }
  _physicEnabled() {
    if (this._webglScene) {
      return this._webglScene.physicsEnabled;
    }
    return false;
  }
  _fullScreen($event) {
    $event.stopPropagation();
    const divWebgl = document.getElementById("plan-webgl-rt");
    const canvas = document.getElementById("webglcanvas");
    if (divWebgl.offsetWidth == document.documentElement.clientWidth) {
      this._isFullscreen = false;
      if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      divWebgl.style.height = 0 + 'px';
      canvas.style.height = 0 + 'px';
    } else {
      this._isFullscreen = true;
      this._ratio = divWebgl.offsetWidth / divWebgl.offsetHeight;
      divWebgl.style.height = "100%";
      divWebgl.style.width = "100%";
      canvas.style.height = "100%";
      canvas.style.width = "100%";
      if (divWebgl.webkitRequestFullScreen) {
        divWebgl.webkitRequestFullScreen();
      } else if (divWebgl.mozRequestFullScreen) {
        divWebgl.mozRequestFullScreen();
      } else if (divWebgl.webkitRequestFullScreen) {
        divWebgl.webkitRequestFullScreen();
      } else if (divWebgl.msRequestFullscreen) {
        divWebgl.msRequestFullscreen();
      }
    }
    this._resize();
  }
  _init() {
    var divWebgl = document.getElementById("plan-webgl-rt");
    if (divWebgl) {
      this._webglScene = new WebglScene(this._scene, this.settings, this.controls, this.useCameraRatio, this.leftPanel);
      if (this.settings.interactiveProject) {
        this._interactiveProjectHandler = new InteractiveProjectHandler(this._webglScene);
      }
      this._webglScene.render();
    }
  }

  /**
  *
  * Reset scene to webgl, remove all items and recreate
  **/
  _refresh() {
    if (typeof PlanManager === 'undefined') {
      return;
    }

    //Notify reload
    Savane.eventsManager.dispatch(WebGLEvents.RELOAD);
    this._scene = PlanManager.getInstance().world.currentScene;
    if (this._webglScene) {
      this._webglScene.cleanUpDynamicHull();
      this._webglScene.updateScene(this._scene);
    }
    this._getHulls();
  }
  _displayCurrentFloorChanged(value) {
    if (typeof PlanManager === 'undefined') {
      return;
    }
    if (!this._webglScene) {
      return;
    }
    this._webglScene.displayCurrentFloor(value);
  }
  _replaceWithObject(oldEntity, arrayObj, index, oldObjects, newObjectsAndParents, callback) {
    AssetManagerServices.createAssetEntity(AssetManagerServices._ASSET_TYPE.ARRANGEMENTS, arrayObj[index], true, newEntity => {
      if (oldEntity.parent && oldEntity.floor) {
        Savane.math.mat4.copy(newEntity.transform.localMatrix, oldEntity.transform.globalMatrix);
        // Save group altervatives
        newEntity.closeObjects = arrayObj;
        // Save current alternative number
        newEntity.closeCurrentObject = index;
        // Disable anchoring to prevent updateFloorHeight
        newEntity.isAnchorActive = false;

        // Adapt floor height according to object height change
        var position = oldEntity.position;
        if (oldEntity.anchor && oldEntity.anchor[1] === -3) {
          position[2] += (oldEntity.realHeight - newEntity.realHeight) / 2;
        } else {
          position[2] -= (oldEntity.realHeight - newEntity.realHeight) / 2;
        }
        newEntity.position = position;
        oldObjects.push(oldEntity);
        newObjectsAndParents.push({
          entity: newEntity,
          parent: oldEntity.parent
        });
      }
      callback();
    });
  }
  _loadCloseObjects(entity, add, oldObjects, newObjectsAndParents, callback) {
    var minlength;
    var maxlength;
    var percent = 10;
    if (entity.length > 400) {
      percent = 50;
    }
    minlength = entity.length - entity.length * percent / 100;
    maxlength = entity.length + entity.length * percent / 100;
    var url = AssetManagerServices.getUrl() + 'api/v1/assets?q.objectType.eq=' + entity.objectType + '&fields=marketName,price,genericName,name,dimensions,sketchBlock,_id,marketLink,configs,stretchability,objectType,coatingType,manufacturer,retailer,pipeline,styles' + '&q.dimensions.width.bt=' + minlength + ',' + maxlength + '&q.pipeline.state.in=available,archived&sort=dimensions.width&q.pipeline.modelState.eq=validated';
    if (entity._objectStyles && entity._objectStyles.length > 0) {
      url += "&q.styles.in=";
      for (let i = 0; i < entity._objectStyles.length; i++) {
        url += entity._objectStyles[i]._id;
        if (i < entity._objectStyles.length - 1) {
          url += ",";
        }
      }
    }
    url += '&apikey=' + AssetManagerServices.getToken();
    Savane.HttpService.get(url).then(response => {
      //success
      if (response.status === 200 && response.data && response.data.resources && response.data.resources.length > 0) {
        // We search for the current resource within the current returned data
        for (var i = 0; i < response.data.resources.length; i++) {
          if (entity.objectId === response.data.resources[i]._id) {
            // Found
            break;
          }
        }

        // Not found search the closest
        if (i >= response.data.resources.length) {
          for (var i = 0; i < response.data.resources.length; i++) {
            if (entity.length === response.data.resources[i].dimensions.width) {
              // Found
              break;
            }
            if (entity.length < response.data.resources[i].dimensions.width) {
              // Found
              if (add < 0) {
                i += add;
              }
              break;
            }
          }
        } else {
          i += add;
        }
        if (i >= response.data.resources.length) {
          i = response.data.resources.length - 1;
        }
        if (i < 0) {
          i = 0;
        }
        this._replaceWithObject(entity, response.data.resources, i, oldObjects, newObjectsAndParents, callback);
      } else {
        this._displayWarning = true;
        callback();
      }
    }).catch(() => {
      callback();
    });
  }
  _switchCloseObjects(entity, add, oldObjects, newObjectsAndParents, callback) {
    if (entity.closeObjects !== undefined) {
      if (entity.closeObjects.length > 1) {
        var configNb = entity.closeCurrentObject + add;
        if (configNb >= entity.closeObjects.length) {
          configNb >= entity.closeObjects.length - 1;
        }
        if (configNb < 0) {
          configNb = 0;
        }
        this._replaceWithObject(entity, entity.closeObjects, configNb, oldObjects, newObjectsAndParents, callback);
      }
    }
  }
  _changeCamera(keyCode) {
    if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
      const entity = this._webglScene.camera.entity;
      entity.startTemporary();
      if (entity.cameraType !== Savane.SceneConstants.CameraType.Perspective) {
        entity.fov = Savane.SceneConstants.CameraPerspectiveOriginalFOV;
      }
      switch (keyCode) {
        case 65:
          //a
          entity.cameraType = Savane.SceneConstants.CameraType.Axonomic;
          break;
        case 80:
          //p
          entity.cameraType = Savane.SceneConstants.CameraType.Panoramic;
          break;
        case 67:
          //h
          entity.cameraType = Savane.SceneConstants.CameraType.Perspective;
          entity.format = Savane.SceneConstants.CameraPreset.NormalSquare;
          break;
        case 72:
          //h
          entity.cameraType = Savane.SceneConstants.CameraType.Perspective;
          entity.format = Savane.SceneConstants.CameraPreset.NormalPaysage;
          break;
        case 86:
          //v
          entity.cameraType = Savane.SceneConstants.CameraType.Perspective;
          entity.format = Savane.SceneConstants.CameraPreset.NormalPortrait;
          break;
      }
      if (entity.cameraType !== entity._cameraType) {
        entity.updateCameraNb(PlanManager.getInstance().world);
      }
      PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(entity));
      this._webglScene.updateCamera(entity.id);
    }
  }
  _videoPreviewStep() {
    const allArrangements = PlanManager.getInstance().world.currentScene.arrangementObjects;
    const sortedArrangements = allArrangements.sort(function (a, b) {
      return a.id - b.id;
    });
    let i;
    const nbObjectsToUnhide = (sortedArrangements.length - this._videoPreviewNbUnhiddenObjects) / (this._webglScene.camera.entity.nbImages - 1 - this._videoPreviewCurrentImage);
    for (i = this._videoPreviewNbUnhiddenObjects; i < this._videoPreviewNbUnhiddenObjects + nbObjectsToUnhide; i++) {
      const entity = this._webglScene.getPlanEntity(sortedArrangements[i].id);
      if (!entity) {
        continue;
      }
      this._webglScene.setLayer(entity.object, 0);
    }
    this._webglScene.render();
    this._videoPreviewNbUnhiddenObjects = i;
    this._videoPreviewCurrentImage++;
    if (this._videoPreviewCurrentImage >= this._webglScene.camera.entity.nbImages - 1) {
      clearInterval(this._videoPreviewIntervalId);
      this._videoPreviewIntervalId = null;
    }
  }
  _setCurrentPhoto(photoNb) {
    if (this._webglScene.camera !== null) {
      this._photoFromCurrentProjectNb = photoNb;
      setTimeout(() => {
        this._displayPhoto = true;
        this._delegate.render();
      });
    }
  }
  _getOriginalPhotoUrl() {
    if (this._photoFromCurrentProjectNb === -1) {
      return "";
    }
    if (this.imgProject !== undefined) {
      var photoId = this._getPhotoId(this._webglScene.camera);

      // Photo id not found
      if (photoId === -1) {
        return "";
      }
      for (let i = 0; i < this.imgProject.length; i++) {
        if (this.imgProject[i].id === photoId) {
          return this.imgProject[i].original_path;
        }
      }
      if (this.imgProject.length > this._photoFromCurrentProjectNb) {
        return this.imgProject[this._photoFromCurrentProjectNb].original_path;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  _getPhotoUrl() {
    if (this._photoFromCurrentProjectNb === -1) {
      return "";
    }
    if (this.imgProject !== undefined) {
      var photoId = this._getPhotoId(this._webglScene.camera);

      // Photo id not found
      if (photoId === -1) {
        return "";
      }
      for (let i = 0; i < this.imgProject.length; i++) {
        if (this.imgProject[i].id === photoId) {
          return this.imgProject[i].before_post_prod_path + "?" + performance.now();
        }
      }
      if (this.imgProject.length > this._photoFromCurrentProjectNb) {
        return this.imgProject[this._photoFromCurrentProjectNb].before_post_prod_path + "?" + performance.now();
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  _getPhotoId(camera) {
    if (!camera) return -1;
    const photoName = camera.entity.name;
    // If no photo name then it was a fake draw photo self created then return invalid id -1
    if (photoName) {
      const photoNameParse = photoName.split('_');
      return photoNameParse[photoNameParse.length - 1] - 0;
    } else {
      return -1;
    }
  }
  //INITILIAZE
  initialize() {
    if (this.jsonScene) {
      this._scene = Savane.JSONImporter.importScene(this.jsonScene);
    } else if (typeof PlanManager !== 'undefined') {
      // the variable is defined
      this._scene = PlanManager.getInstance().world.currentScene;
    }
    if (this.activeCameraId) {
      this._cameraState = CAMERA_STATES.COCOS;
    }
    this._init();
    this._getHulls();
    this._webglScene.updateScene(this._scene);
    if (this.jsonScene) {
      this._webglScene.updateCamera(this.activeCameraId);
    }
  }
  destroy() {
    if (!this.bindedElement) return;
    console.log("Destroy WebGL");
    // Destroy
    if (this._webglScene !== null) {
      this._webglScene.destroy();
      this._webglScene = null;
    }
    //destroy
    this.bindedElement.removeEventListener("mousedown", this.mousedownHandler);
    this.bindedElement.removeEventListener("dblclick", this.dblclickHandler);
    this.bindedElement.removeEventListener("mouseup", this.mouseupHandler);
    this.bindedElement.removeEventListener("mousemove", this.mousemoveHandler);
    this.bindedElement.removeEventListener("mouseleave", this.mouseleaveHandler);
    this.bindedElement.removeEventListener("mouseover", this.mouseoverHandler);
    this.bindedElement.removeEventListener("wheel", this.wheelHandler);
    document.removeEventListener("keydown", this.keydownHandler);
    document.removeEventListener("keyup", this.keyupHandler);
    window.removeEventListener('resize', this._resize);
    //Destroy listeners
    Savane.eventsManager.removeListener(this.coatingRotationListener);
    Savane.eventsManager.removeListener(this.coatingDragListener);
    Savane.eventsManager.removeListener(this.entityDragListener);
    Savane.eventsManager.removeListener(this.setFilters);
    Savane.eventsManager.removeListener(this.assetsUpdated);
    Savane.eventsManager.removeListener(this.stopEnvUpdate);
    Savane.eventsManager.removeListener(this.updateEnvs);
    Savane.eventsManager.removeListener(this.computeCameraShoppingList);
    Savane.eventsManager.removeListener(this.planStateChangedListener);
    Savane.eventsManager.removeListener(this.projectLoaded);
    Savane.eventsManager.removeListener(this.projectUpdated);
    Savane.eventsManager.removeListener(this.refreshListener);
    Savane.eventsManager.removeListener(this.hullLoadedListener);
    if (this.actionCountChange) {
      Savane.eventsManager.removeListener(this.actionCountChange);
    }
    Savane.eventsManager.removeListener(this.floorListener);
    Savane.eventsManager.removeListener(this.reloadScene);
    Savane.eventsManager.removeListener(this.fieldEditChange);
    Savane.eventsManager.removeListener(this.resizeListener);
    Savane.eventsManager.removeListener(this.sceneUpdated);
    Savane.eventsManager.removeListener(this.fullscreenListener);
    Savane.eventsManager.removeListener(this.displaySizeChanged);
    Savane.eventsManager.removeListener(this.updateArrangementCoating);
    Savane.eventsManager.removeListener(this.floorGeneratorUpdated);
    Savane.eventsManager.removeListener(this.frontViewFloorGeneratorUpdated);
    Savane.eventsManager.removeListener(this.hideAxo);
    Savane.eventsManager.removeListener(this.hideArrangements);
  }
  _addTarget(parent, index) {
    AssetManagerServices.getAsset("assets", "5f49124f6c44d36fc0f1fb56", null, result => {
      AssetManagerServices.createAssetEntity("assets", result, false, entity => {
        entity.position = Savane.math.vec3.fromValues(0, 0, -parent.position[2]);
        parent.addChild(entity);
        this._webglScene.addEntity(entity, null, null, () => {
          var webglEntity = this._webglScene.getPlanEntity(entity.id);
          var traversing = child => {
            child.receiveShadow = false;
            child.keepMaterial = true;
          };
          webglEntity.object.traverse(traversing);
          this._webglScene.setLayer(webglEntity.object, index);
        });
        this._webglScene.render();
      });
    });
  }
  _loadEntity(id, config, lod, index) {
    if (index !== undefined) {
      this._loadedEntity = [];
    } else {
      this._loadedEntity = null;
      index = 0;
    }
    AssetManagerServices.getAsset("assets", id, null, result => {
      var configIndex = config;
      if (config.startsWith && config.startsWith('lod')) {
        configIndex = config.split('_')[1];
      }
      configIndex = parseInt(configIndex);
      var height = result.configs[configIndex - 1].dimensions.height;
      AssetManagerServices.createAssetEntity("assets", result, false, entity => {
        var position = Savane.math.vec3.fromValues(0, Math.max(Math.max(entity.height, entity.width), entity.length) * 10 * index, height / 2);
        entity.position = position;
        if (Array.isArray(this._loadedEntity)) {
          this._loadedEntity.push(entity);
        } else {
          this._loadedEntity = entity;
        }
        this._addTarget(entity, index);
        this._webglScene.settings.meshLevel = this.meshLevel;
        this._webglScene.meshLevel = this.meshLevel;
        this._webglScene.forceProbes = true;
        this._scene.addEntity(entity);
        this._webglScene.addEntity(entity, config, lod, () => {
          var webglEntity = this._webglScene.getPlanEntity(entity.id);
          this._webglScene.setLayer(webglEntity.object, index);
          Savane.eventsManager.dispatch(Savane.Events.AM_ENTITY_STATS, {
            index: index,
            statistics: webglEntity.statistics
          });
          // this.display(this.settings.display);
        });
        this._webglScene.updateSettings();
        this._webglScene.fitCamera(entity, index);
        this._webglScene.render();
      });
    });
  }
  _resizeCameras() {
    if (!this._webglScene) {
      return;
    }
    if (!this._webglScene.cameraArray) {
      return;
    }
    const divWebgl = document.getElementById("plan-webgl-rt");
    const cameras = this._webglScene.cameraArray;
    const lines = Math.floor(cameras.length / 3);
    const columns = 3;
    const width = divWebgl.parentElement.offsetWidth / columns;
    const height = divWebgl.offsetHeight / lines;
    for (let i = 0; i < cameras.length; ++i) {
      const camera = cameras[i];
      const line = Math.floor(i / 3);
      const column = Math.floor(i % 3);
      camera.viewport = new THREE.Vector4(Math.floor(column * width), Math.floor(line * height), Math.ceil(width), Math.ceil(height));
      camera.target = new THREE.Vector3();
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    }
  }
  display(value) {
    if (!this._webglScene) {
      return;
    }
    switch (value) {
      case 0:
        if (Array.isArray(this._loadedEntity)) {
          for (let i = 0; i < this._loadedEntity.length; ++i) {
            this._webglScene.getPlanEntity(this._loadedEntity[i].id).updateCoating();
          }
        } else {
          this._webglScene.getPlanEntity(this._loadedEntity.id).updateCoating();
        }
        break;
      case 1:
        if (Array.isArray(this._loadedEntity)) {
          for (let i = 0; i < this._loadedEntity.length; ++i) {
            this._webglScene.getPlanEntity(this._loadedEntity[i].id).wireframe();
          }
        } else {
          this._webglScene.getPlanEntity(this._loadedEntity.id).wireframe();
        }
        break;
      case 2:
        if (Array.isArray(this._loadedEntity)) {
          for (let i = 0; i < this._loadedEntity.length; ++i) {
            this._webglScene.getPlanEntity(this._loadedEntity[i].id).normal();
          }
        } else {
          this._webglScene.getPlanEntity(this._loadedEntity.id).normal();
        }
        break;
      case 3:
        if (Array.isArray(this._loadedEntity)) {
          for (let i = 0; i < this._loadedEntity.length; ++i) {
            this._webglScene.getPlanEntity(this._loadedEntity[i].id).uvs('numbers');
          }
        } else {
          this._webglScene.getPlanEntity(this._loadedEntity.id).uvs('numbers');
        }
        break;
      case 4:
        if (Array.isArray(this._loadedEntity)) {
          for (let i = 0; i < this._loadedEntity.length; ++i) {
            this._webglScene.getPlanEntity(this._loadedEntity[i].id).uvs('checker');
          }
        } else {
          this._webglScene.getPlanEntity(this._loadedEntity.id).uvs('checker');
        }
        break;
      case 5:
        if (Array.isArray(this._loadedEntity)) {
          for (let i = 0; i < this._loadedEntity.length; ++i) {
            this._webglScene.getPlanEntity(this._loadedEntity[i].id).uvs('lines');
          }
        } else {
          this._webglScene.getPlanEntity(this._loadedEntity.id).uvs('lines');
        }
        break;
    }
    this._webglScene.render();
  }
  _resetZoom() {
    this.zoom = 1;
    this.transformOrigin = "center";
    this._transformOriginMouseDown = [0, 0];
    this._transformOriginOffset = [0, 0];
    this._webglScene.renderer.domElement.style = "transform: scale(" + this.zoom + "); transform-origin: " + this.transformOrigin;
    this._delegate.render();
  }
  constructor(delegate) {
    this._resize = updateCamera => {
      if (updateCamera === undefined) updateCamera = true;
      if (this._webglScene !== null) {
        if (this._interactiveProjectHandler) {
          this._interactiveProjectHandler.Resize();
          this._interactiveProjectHandler.UpdateCamera();
        } else {
          this._resizeCameras();
          this._webglScene.leftPanel = this.leftPanel;
          this._webglScene.updateEnvs();
          if (!this._webglScene.camera) {
            this._cameraState = CAMERA_STATES.FREE;
          }
          if (this._cameraState === CAMERA_STATES.COCOS && updateCamera) {
            this._webglScene.updateCamera(this._webglScene.camera.entity.id);
          } else if (this._cameraState === CAMERA_STATES.FREE) {
            this._webglScene.updateCamera(null);
          }
          if (this._webglScene.camera) {
            this.cameraHeight = (this._webglScene.camera.entity.transform.localPosition[2] / 10).toFixed(1);
            this.cameraFocal = this._webglScene.camera.entity.fov.toFixed(1);
            this._delegate.render();
          }
          Savane.eventsManager.dispatch(Savane.Events.CAMERA_CHANGED);
        }
      }
    };
    this._scene = null;
    this._webglScene = null;
    this._isFullscreen = false;
    this._displayPhoto = false;
    this._photoOpacity = 0.5;
    this._photoFromCurrentProjectNb = -1;
    this._transformOrigin = "center";
    this._transformOriginMouseDown = [0, 0];
    this._transformOriginOffset = [0, 0];
    this._videoPreviewIntervalId = null;
    this._videoPreviewNbUnhiddenObjects = 0;
    this._videoPreviewCurrentImage = 0;
    this._enable_additionals_afters = true;
    this._bPressed = false;
    this._vPressed = false;
    this._delegate = delegate;
    this.thirdGrid = false;
    this.loaded = false;
    this.zoom = 1;
    this._cameraState = CAMERA_STATES.FREE;
  }
  run(bindedElement, callback) {
    this.bindedElement = bindedElement;
    ENABLE3D.PhysicsLoader(PLAN_WEBGL_MODULE_PATH + '/enable3d/ammo', () => {
      // Check if owner needs additional cameras or not (to hide the add camera button)
      if (this.user && this.user.platform.enable_additionals_afters !== undefined && this.user.platform.enable_additionals_afters === 0) {
        this._enable_additionals_afters = false;
      }
      this.settings = this.settings || JSON.parse(Savane.SavaneCookie.getCookie("Rhinov-PlanWebGL-Settings", '{\
                        "meshLevel": 1,\
                        "hullbin": true,\
                        "environement": true,\
                        "areas": true,\
                        "mirrors": true,\
                        "fxaa": true\
                    }'));
      if (this.meshLevel === undefined) {
        this.meshLevel = 1;
      }
      if (!this.settings.interactiveProject) {
        this.loaded = true;
      } else {
        this.meshLevel = 0;
      }
      this.settings.display = 0;
      this._dragState = null;
      this._interactiveProjectHandler = null;
      this._mouseDownEvent = null;
      this._mouseMoveEvent = null;
      this._mouseOver = false;
      this._loadedEntity = null;
      this.updateFloorGeneratorTimeout = null;
      this.updateDynamicHullTimeout = null;
      this.editRenderCameraCommandTimeout = null;
      this.currentKeyPressed = null;
      this._loadedHullCount = 0;
      this.projectLoaded = Savane.eventsManager.addListener(Savane.Events.PROJECT_DID_LOAD, this._getHulls.bind(this));
      this.projectUpdated = Savane.eventsManager.addListener(Savane.Events.UPDATE_RHINOV_FORMAT, this._getHulls.bind(this));
      this.refreshListener = Savane.eventsManager.addListener(Savane.Events.PROJECT_REFRESH_HULL, this._getDynamicHull.bind(this));
      this.hullLoadedListener = Savane.eventsManager.addListener(Savane.Events.HULL_LOADED, () => {
        if (this._webglScene !== null) {
          this.loaded = this._loadedHullCount >= 3;
          this._webglScene.render();
        }
      });

      // Listener for COCOS Camera movement
      var cocosPosition = new THREE.Vector3();
      this.cocosCameraDragListener = Savane.eventsManager.addListener(Savane.Events.POS_WIN_CHANGED, event => {
        if (this._webglScene !== null) {
          cocosPosition.set(event.userData.x / 10, event.userData.y / 10, 150 / event.userData.zoom);
          if (!this._webglScene.defaultCameraMoved) {
            cocosPosition.z = this._webglScene.getDefaultCameraDistanceFit();
            this._webglScene.defaultCamera.position.copy(cocosPosition);
            this._webglScene.defaultCamera.target = new THREE.Vector3(cocosPosition.x, cocosPosition.y, 0);
            this._webglScene.render();
          }
        }
      });

      // Listen to floor change and display current floor if asked
      this.floorListener = Savane.eventsManager.addListener(Savane.Events.PLAN_FLOOR_CHANGED, event => {
        if (this._webglScene) {
          this._webglScene.detachSelection();
          this._webglScene._visibleRooms = [];
          this._displayCurrentFloorChanged(this._displayCurrentFloor);
          Savane.eventsManager.dispatch(Savane.Events.HIDE_ARRANGEMENTS);
          if (this._webglScene.camera) {
            if (this._webglScene.camera.entity.cameraType !== Savane.SceneConstants.CameraType.PhotoRender) {
              this._cameraState = CAMERA_STATES.FREE;
              this._webglScene.updateCamera(null);
              Savane.eventsManager.dispatch(Savane.Events.CAMERA_RESIZED);
            } else {
              this._webglScene.updateCamera(this._webglScene.camera.entity.id);
            }
          }
        }
      });

      // Listen to floor change and reload the entire webgl scene
      this.coatingRotationListener = Savane.eventsManager.addListener(Savane.Events.COATING_ROTATION_CHANGED, event => {
        this._webglScene.updateCoatingParameters(event.userData);
      });
      this.coatingDragListener = Savane.eventsManager.addListener(Savane.Events.COATING_DRAG, data => {
        this.bindedElement.style.cursor = 'grabbing';
        this._dragState = {
          state: DRAG_STATE.COATING,
          data: data.userData,
          value: data.userData.coating
        };
      });
      this.entityDragListener = Savane.eventsManager.addListener(Savane.Events.ENTITY_EDITION, data => {
        this.bindedElement.style.cursor = 'grabbing';
        this._dragState = {
          state: DRAG_STATE.ENTITY,
          data: data.userData,
          value: data.userData.entity
        };
      });
      this.planStateChangedListener = Savane.eventsManager.addListener(Savane.Events.PLAN_STATE_CHANGED, state => {
        if (state.userData !== 36 && state.userData !== 31 && state.userData !== 41 && state.userData !== 42 && !this._mouseOver) {
          this.bindedElement.style.cursor = 'default';
          this._dragState = null;
        }
      });
      this.setFilters = Savane.eventsManager.addListener(Savane.Events.SET_FILTERS, event => {
        //resize();
      });
      this.assetsUpdated = Savane.eventsManager.addListener('results_update_datas', event => {
        if (this._webglScene !== null) {
          this._webglScene.updateEnvs();
        }
      });
      this.stopEnvUpdate = Savane.eventsManager.addListener(Savane.Events.STOP_UPDATING_ENVS, event => {
        if (this._webglScene !== null) {
          this._webglScene.stopEnvUpdate();
        }
      });
      this.updateEnvs = Savane.eventsManager.addListener(Savane.Events.START_UPDATING_ENVS, event => {
        if (this._webglScene !== null) {
          this._webglScene.updateEnvs();
        }
      });
      this.computeCameraShoppingList = Savane.eventsManager.addListener(Savane.Events.COMPUTE_CAMERA_SHOPPING_LIST, event => {
        if (this._webglScene !== null) {
          this._webglScene.computeCameraShoppingList(event.userData);
        }
      });
      if (!this.settings.interactiveProject) {
        // Listener for command proceed in COCOS that will cause an update into the webGL view (move an arrangement objet, group arrangement objects or groups)
        this.actionCountChange = Savane.eventsManager.addListener(Savane.Events.COMMAND_EXECUTED, event => {
          // If the webGL scene exists
          if (this._webglScene !== null) {
            // Depending on the command name (see Command.js from CocosPlanModule/src/cocos/scripts/command)
            switch (event.userData.name) {
              // Light edition command
              case Savane.Commands.CommandEnum.EditLightCommand:
                // Get webGL entity
                var webglEntity = this._webglScene.getPlanEntity(event.userData.datas.id);
                // If it exists
                if (webglEntity !== null) {
                  // And we update the whole tree underneath so all positions are updated
                  this._webglScene.updateTree(webglEntity.entity);
                }
                break;
              case Savane.Commands.CommandEnum.EditRenderCameraCommand:
                this._webglScene.showExcludedObject();
                this._webglScene.hideExcludedObject(this._webglScene.getPlanCamera(event.userData.datas.id));
                this._webglScene.updateCamera(event.userData.datas.id);
                this._resize(false);
                break;

              // Edit entity command : edition of cameras, lights, arrangement object and group
              case Savane.Commands.CommandEnum.EditGeometryPrimitiveCommand:
              case Savane.Commands.CommandEnum.EditTechnicalElementCommand:
              case Savane.Commands.CommandEnum.EditJoineryCommand:
              case Savane.Commands.CommandEnum.EditStaircaseCommand:
              case Savane.Commands.CommandEnum.EditRoomPropertiesCommand:
              case Savane.Commands.CommandEnum.EditEntityCommand:
                // We do this code for both execute and undo

                // If we edit a camera
                if (event.userData.datas.type === Savane.SceneConstants.EntityType.RenderCamera) {
                  // The camera is now used for the preview
                  this._cameraState = CAMERA_STATES.COCOS;
                  if (event.userData.datas.select) {
                    this._webglScene.updateCamera(event.userData.datas.id);
                    if (this._webglScene.camera) {
                      if (this._webglScene.camera.entity.cameraType === Savane.SceneConstants.CameraType.PhotoRender) {
                        if (this._webglScene.sun.entity.exterior === 'defaut') {
                          this._photoFromCurrentProjectNb = this._webglScene.camera.entity.cameraNb;
                          const texture = new THREE.TextureLoader().load(this._getPhotoUrl());
                          texture.colorSpace = THREE.SRGBColorSpace;
                          this._webglScene.threeScene.background = texture;
                          this._webglScene.toggleSky(false);
                        } else {
                          this._webglScene.loadBackground(this._webglScene.sun.entity, false);
                        }
                      } else if (this._webglScene.camera && this._webglScene.camera.entity.cameraType === Savane.SceneConstants.CameraType.Axonomic) {
                        this._webglScene.background = null;
                        this._webglScene.toggleSky(true);
                      } else {
                        var additional = this._webglScene.camera.entity.cameraType === Savane.SceneConstants.CameraType.Perspective && this.user && this.user.platforms_id !== 1;
                        this._webglScene.loadBackground(this._webglScene.sun.entity, additional);
                      }
                    }
                  } else {
                    if (this._webglScene.camera) {
                      this._webglScene.camera.targetInitialized = false;
                    }
                    this._webglScene.updateCamera(event.userData.datas.id);
                  }
                  this._resize(false);
                } else {
                  // Get webGL entity
                  var webglEntity = this._webglScene.getPlanEntity(event.userData.datas.id);
                  // If it exists
                  if (webglEntity !== null) {
                    //  We will go up the hierarchy
                    var rootArrangementEntity = webglEntity.entity;

                    // Until we find the root arrangement
                    if (rootArrangementEntity.parent) {
                      while (rootArrangementEntity.parent && (rootArrangementEntity.parent.entityType === Savane.SceneConstants.EntityType.ArrangementGroup || rootArrangementEntity.parent.entityType === Savane.SceneConstants.EntityType.ArrangementObject)) {
                        rootArrangementEntity = rootArrangementEntity.parent;
                      }
                    }

                    // And we update the whole tree underneath so all positions are updated
                    this._webglScene.updateTree(rootArrangementEntity);
                  }
                }
                break;

              // Entity addition to the scene, we need to create the new entity into the webGL scene
              case Savane.Commands.CommandEnum.AddEntityCommand:
                // Undo or execute ?
                if (event.userData.undo) {
                  // Undo, we remove the entity tree from the webGL scene (undo addition = destruction)
                  this._webglScene.removeEntityTree(event.userData.datas.entity);
                } else {
                  // Execute, we add the entity tree to the webGL scene
                  this._webglScene.addEntityTree(event.userData.datas.entity);

                  // Get webGL entity
                  var webglEntity = this._webglScene.getPlanEntity(event.userData.datas.id);
                  // If it exists
                  if (webglEntity !== null) {
                    //  We will go up the hierarchy
                    var rootArrangementEntity = webglEntity.entity;

                    // Until we find the root arrangement
                    if (rootArrangementEntity.parent) {
                      while (rootArrangementEntity.parent && (rootArrangementEntity.parent.entityType === Savane.SceneConstants.EntityType.ArrangementGroup || rootArrangementEntity.parent.entityType === Savane.SceneConstants.EntityType.ArrangementObject)) {
                        rootArrangementEntity = rootArrangementEntity.parent;
                      }
                    }

                    // And we update the whole tree underneath so all positions are updated
                    this._webglScene.updateTree(rootArrangementEntity);
                  }
                }
                break;
              case Savane.Commands.CommandEnum.AddEntitiesCommand:
                // Undo or execute ?
                if (event.userData.undo) {} else {
                  for (var i = 0; i < event.userData.datas.entitiesAndParents.length; i++) {
                    // And we update the whole tree underneath so all positions are updated
                    this._webglScene.updateTree(event.userData.datas.entitiesAndParents[i].entity);
                  }
                }
                break;

              // Entity deletion from the scene
              case Savane.Commands.CommandEnum.DeleteEntityCommand:
              case Savane.Commands.CommandEnum.DeleteTechnicalElementCommand:
              case Savane.Commands.CommandEnum.DeleteStaircaseCommand:
                // Undo or execute ?
                if (event.userData.undo) {
                  // Undo, we add the entity tree to the webGL scene (undo deletion = addition)
                  this._webglScene.addEntityTree(event.userData.datas.entity);

                  // Get webGL entity
                  var webglEntity = this._webglScene.getPlanEntity(event.userData.datas.id);
                  // If it exists
                  if (webglEntity !== null) {
                    //  We will go up the hierarchy
                    var rootArrangementEntity = webglEntity.entity;

                    // Until we find the root arrangement
                    if (rootArrangementEntity.parent) {
                      while (rootArrangementEntity.parent && (rootArrangementEntity.parent.entityType === Savane.SceneConstants.EntityType.ArrangementGroup || rootArrangementEntity.parent.entityType === Savane.SceneConstants.EntityType.ArrangementObject)) {
                        rootArrangementEntity = rootArrangementEntity.parent;
                      }
                    }

                    // And we update the whole tree underneath so all positions are updated
                    this._webglScene.updateTree(rootArrangementEntity);
                  }
                } else {
                  // Execute, we delete the entity tree from the webGL scene
                  this._webglScene.removeEntityTree(event.userData.datas.entity);
                }
                break;

              // Arrangement group creation
              case Savane.Commands.CommandEnum.CreateGroupCommand:
                // Undo or execute ?
                if (event.userData.undo) {
                  // Undo, we remove all grouped objects from the webGL scene and add them again because their parent has changed (we need to remove them so they are removed from
                  // their original webGL parent and we add them back to attach them to their new parent)
                  // Warning, we don't reove the group entity tree because it has been emptied before the execution arrives here so we use the arrangement field of the execDatas parameter
                  for (let i = 0; i < event.userData.datas.arrangements.length; i++) {
                    this._webglScene.removeEntityTree(event.userData.datas.arrangements[i]);
                    this._webglScene.addEntityTree(event.userData.datas.arrangements[i]);
                  }
                  // Remove the created group from the scene
                  this._webglScene.removeEntity(event.userData.datas.group);
                } else {
                  // Execute, we remove all grouped objects from the webGL scene and we will add them back along with the created group at the end (this will be done recursively by
                  // the addEntityTree function)
                  for (let i = 0; i < event.userData.datas.arrangements.length; i++) {
                    this._webglScene.removeEntityTree(event.userData.datas.arrangements[i]);
                  }
                  this._webglScene.addEntityTree(event.userData.datas.group);
                }
                break;

              // Arrangement group deletion
              case Savane.Commands.CommandEnum.DeleteGroupCommand:
                // Undo or execute ?
                if (event.userData.undo) {
                  // Undo, we add the group back to the scene, we will remove the children trees from the webGL scene
                  for (let i = 0; i < event.userData.datas.group.iArrangements.length; i++) {
                    this._webglScene.removeEntityTree(event.userData.datas.group.iArrangements[i]);
                  }
                  // And we add the group entity tree to the webGL scene (this is done recursively by the addEntityTree function)
                  this._webglScene.addEntityTree(event.userData.datas.group);
                } else {
                  // Execute, we remove the groupe entity tree from the scene (done recursively)
                  this._webglScene.removeEntityTree(event.userData.datas.group);
                  // And we add back all children to the webGL scene
                  for (let i = 0; i < event.userData.datas.group.iArrangements.length; i++) {
                    this._webglScene.addEntityTree(event.userData.datas.group.iArrangements[i]);
                  }
                }
                break;

              // Entities leaving a group
              case Savane.Commands.CommandEnum.LeaveGroupCommand:
                // We simply remove and add the entity leaving the group for both execute and undo
                this._webglScene.removeEntityTree(event.userData.datas.entity);
                this._webglScene.addEntityTree(event.userData.datas.entity);
                break;

              // Merge or multiple arrangement groups
              case Savane.Commands.CommandEnum.MergeGroupCommand:
                // Undo or execute ?
                if (event.userData.undo) {
                  // Undo the groups are unmerged, remove the merged group
                  this._webglScene.removeEntityTree(event.userData.datas.group);

                  // And re-create original groups
                  for (let i = 0; i < event.userData.datas.arrangements.length; i++) {
                    this._webglScene.removeEntityTree(event.userData.datas.arrangements[i]);
                    this._webglScene.addEntityTree(event.userData.datas.arrangements[i]);
                  }
                } else {
                  // Execute, remove each merged entity tree from the scene
                  for (let i = 0; i < event.userData.datas.arrangements.length; i++) {
                    this._webglScene.removeEntityTree(event.userData.datas.arrangements[i]);
                  }

                  // And add the created group tree (already containing the entities above as children) to the webGL scene
                  this._webglScene.addEntityTree(event.userData.datas.group);
                }
                break;
              case Savane.Commands.CommandEnum.AddComponentCommand:
              case Savane.Commands.CommandEnum.ChangeCoatingCommand:
              case Savane.Commands.CommandEnum.EditComponentCommand:
                if (typeof PlanManager !== 'undefined') {
                  this._webglScene.detachSelection();
                  this._scene = PlanManager.getInstance().world.currentScene;
                  if (event.userData.datas.component.componentType === Savane.ComponentConstants.ComponentType.CoatingArea || event.userData.datas.component.componentType === Savane.ComponentConstants.ComponentType.Area) {
                    Savane.eventsManager.dispatch(Savane.Events.FRONTVIEW_FLOOR_GENERATOR_CHANGED);
                  } else if (event.userData.datas.component.componentType === Savane.ComponentConstants.ComponentType.FloorCoatingArea) {
                    Savane.eventsManager.dispatch(Savane.Events.FLOOR_GENERATOR_CHANGED);
                  } else {
                    var entity = event.userData.datas.entity || event.userData.datas.component.entity;
                    if (entity && entity.isArrangementObjectEntity()) {
                      var glEntity = this._webglScene.getPlanEntity(entity.id);
                      if (glEntity) {
                        glEntity.updateCoating();
                      }
                    } else {
                      this._webglScene.updateHull(entity);
                    }
                  }
                  this._webglScene.attachSelection();
                }
                break;
              case Savane.Commands.CommandEnum.DeleteComponentCommand:
                if (event.userData.datas.component.componentType === Savane.ComponentConstants.ComponentType.CoatingArea || event.userData.datas.component.componentType === Savane.ComponentConstants.ComponentType.Area) {
                  this._getDynamicHull();
                } else if (event.userData.datas.component.componentType === Savane.ComponentConstants.ComponentType.FloorCoatingArea) {
                  this._getFloorGeneratorHull();
                }
                break;
              case Savane.Commands.CommandEnum.ApplySmartDesignerSolutionCommand:
                if (typeof PlanManager !== 'undefined') {
                  this._scene = PlanManager.getInstance().world.currentScene;
                  for (let i = 0; i < event.userData.datas.coatings.length; i++) {
                    var entity = this._scene.getDeepChild(event.userData.datas.coatings[i].forEntityId);
                    if (entity) {
                      this._webglScene.updateHull(entity);
                    }
                  }
                }
                this._getDynamicHull();
                this._getFloorGeneratorHull();
                break;
              case Savane.Commands.CommandEnum.ApplySmartRestylerSolutionCommand:
                if (typeof PlanManager !== 'undefined') {
                  this._scene = PlanManager.getInstance().world.currentScene;
                  for (let i = 0; i < event.userData.datas.coatings.length; i++) {
                    var entity = this._scene.getDeepChild(event.userData.datas.coatings[i].forEntityId);
                    if (entity) {
                      this._webglScene.updateHull(entity);
                    }
                  }
                }
                this._getDynamicHull();
                this._getFloorGeneratorHull();
                break;
            }
            if (event.userData.datas && event.userData.datas.entity && event.userData.datas.entity.isRenderCameraEntity() === false) {
              //this._webglScene.updateHull(event.userData.datas.entity);
            }
            this._webglScene.updateEnvs();
            this._webglScene.render();
          } else {
            this._refresh();
          }
        });
      }
      this.fieldEditChange = Savane.eventsManager.addListener(Savane.Events.ENTITY_PARAM_EDITED, event => {
        if (this._webglScene === null) {
          return;
        }
        if (event.userData.type === Savane.SceneConstants.EntityType.RenderCamera) {
          this._cameraState = CAMERA_STATES.COCOS;
          this._webglScene.updateCamera(event.userData.id);
          this._resize(false);
          if (event.userData.key === 'cameraType' || event.userData.key === undefined) {
            if (this._webglScene.camera && this._webglScene.camera.entity.cameraType === Savane.SceneConstants.CameraType.Axonomic) {
              this._webglScene.background = null;
              this._webglScene.toggleSky(true);
            } else {
              if (this._webglScene.sun.entity.exterior === 'defaut') {
                this._photoFromCurrentProjectNb = this._webglScene.camera.entity.cameraNb;
                const texture = new THREE.TextureLoader().load(this._getPhotoUrl());
                texture.colorSpace = THREE.SRGBColorSpace;
                this._webglScene.threeScene.background = texture;
                this._webglScene.toggleSky(false);
              } else {
                var additional = this._webglScene.camera && this._webglScene.camera.entity.cameraType === Savane.SceneConstants.CameraType.Perspective && this.user && this.user.platforms_id !== 1;
                this._webglScene.loadBackground(this._webglScene.sun.entity, additional);
              }
            }
          }
        } else if (event.userData.type === Savane.SceneConstants.EntityType.Sun) {
          if (event.userData.key === 'exterior' || event.userData.key === 'additionalExterior' || event.userData.key === 'decorsRotation' && this._webglScene.camera) {
            if (this._webglScene.camera.entity.cameraType === Savane.SceneConstants.CameraType.PhotoRender) {
              if (this._webglScene.sun.entity.exterior === 'defaut') {
                this._photoFromCurrentProjectNb = this._webglScene.camera.entity.cameraNb;
                const texture = new THREE.TextureLoader().load(this._getPhotoUrl());
                texture.colorSpace = THREE.SRGBColorSpace;
                this._webglScene.threeScene.background = texture;
                this._webglScene.toggleSky(false);
              } else {
                this._webglScene.loadBackground(this._webglScene.sun.entity, false);
              }
            } else {
              var additional = this._webglScene.camera.entity.cameraType === Savane.SceneConstants.CameraType.Perspective && this.user && this.user.platforms_id !== 1;
              this._webglScene.loadBackground(this._webglScene.sun.entity, additional);
            }
          }
          this._webglScene.sun.update();
        } else {
          var webglEntity = this._webglScene.getPlanEntity(event.userData.id);
          if (webglEntity !== null) {
            var attached = false;
            if (this._webglScene.interactionWithItemAllowed(webglEntity.entity)) {
              attached = this._webglScene.detachFromSelection(webglEntity);
            }
            webglEntity.update();
            if (attached && this._webglScene.interactionWithItemAllowed(webglEntity.entity)) {
              this._webglScene.attachToSelection(webglEntity);
            }
          }
        }
        this._webglScene.updateEnvs();
        this._webglScene.render();
      });
      this.updateArrangementCoating = Savane.eventsManager.addListener(Savane.Events.UPDATE_ARRANGEMENT_COATING, event => {
        if (this._webglScene === null) {
          return;
        }
        var webglEntity = this._webglScene.getPlanEntity(event.userData.id);
        if (!webglEntity) {
          return;
        }
        webglEntity.updateCoating();
      });
      this.floorGeneratorUpdated = Savane.eventsManager.addListener(Savane.Events.FLOOR_GENERATOR_CHANGED, () => {
        if (this.updateFloorGeneratorTimeout) {
          clearTimeout(this.updateFloorGeneratorTimeout);
        }
        this.updateFloorGeneratorTimeout = setTimeout(() => {
          this.updateFloorGeneratorTimeout = null;
          this._getFloorGeneratorHull();
        }, 1500);
      });
      this.cancelFloorGeneratorUpdate = Savane.eventsManager.addListener(Savane.Events.CANCEL_FLOOR_GENERATOR_CHANGED, () => {
        if (this.updateFloorGeneratorTimeout) {
          clearTimeout(this.updateFloorGeneratorTimeout);
          this.updateFloorGeneratorTimeout = null;
        }
      });
      this.frontViewFloorGeneratorUpdated = Savane.eventsManager.addListener(Savane.Events.FRONTVIEW_FLOOR_GENERATOR_CHANGED, () => {
        if (this.updateDynamicHullTimeout) {
          clearTimeout(this.updateDynamicHullTimeout);
        }
        this.updateDynamicHullTimeout = setTimeout(() => {
          this.updateDynamicHullTimeout = null;
          this._getDynamicHull();
        }, 1500);
      });
      this.sceneUpdated = Savane.eventsManager.addListener(Savane.Events.RHINOV_FORMAT_UPDATED, event => {
        this._scene = event.userData.scene;
      });
      this.resizeListener = Savane.eventsManager.addListener(Savane.Events.CAMERA_RESIZED, () => {
        this._resize();
      });
      this.fullscreenListener = Savane.eventsManager.addListener(Savane.Events.FULLSCREEN_CHANGED, () => {
        this._resize();
      });
      this.displaySizeChanged = Savane.eventsManager.addListener('display_size_changed', () => {
        this._resize();
      });
      this.reloadScene = Savane.eventsManager.addListener(Savane.Events.PROJECT_RELOAD, this._refresh.bind(this));
      this._displayCurrentFloor = parseInt(Savane.SavaneCookie.getCookie("Rhinov-WebGL-displayCurrentFloor", "0")) === 0 ? false : true;
      this.hideAxo = Savane.eventsManager.addListener(Savane.Events.HIDE_AXO, () => {
        if (!this._webglScene) {
          return;
        }
        if (typeof PlanManager === 'undefined') {
          return;
        }
        if (this._webglScene.staticHull) {
          if (PlanManager.getInstance()._hideAxo) {
            this._webglScene.staticHull.hideCeiling();
          } else {
            this._webglScene.staticHull.showCeiling();
          }
        }
        if (this._webglScene.dynamicHull) {
          if (PlanManager.getInstance()._hideAxo) {
            this._webglScene.dynamicHull.hideCeiling();
          } else {
            this._webglScene.dynamicHull.showCeiling();
          }
        }
        if (this._webglScene.floorGeneratorHull) {
          if (PlanManager.getInstance()._hideAxo) {
            this._webglScene.floorGeneratorHull.hideCeiling();
          } else {
            this._webglScene.floorGeneratorHull.showCeiling();
          }
        }
        this._webglScene.render();
      });
      this.hideArrangements = Savane.eventsManager.addListener(Savane.Events.HIDE_ARRANGEMENTS, () => {
        if (!this._webglScene) {
          return;
        }
        if (typeof PlanManager === 'undefined') {
          return;
        }
        if (PlanManager.getInstance()._hideArrangements) {
          this._webglScene.hideArrangements();
        } else {
          this._webglScene.showArrangements(this._displayCurrentFloor);
        }
        this._webglScene.render();
      });
      this.mousedownHandler = event => {
        event.stopPropagation();
        if (this._webglScene && this._webglScene.renderer) {
          if (event.srcElement !== this._webglScene.renderer.domElement && this.zoom === 1) {
            return;
          }
        }

        // Hide tulip will commit current changes
        Savane.eventsManager.dispatch(Savane.Events.HIDE_TULIP, null);
        if (event.button === 1) {
          event.preventDefault();
        }
        if (!this._webglScene) {
          return;
        }
        if (this.settings.interactiveProject) {
          this._interactiveProjectHandler.HandleMouseDown(event);
        }
        this._mouseDownEvent = {};
        for (let item in event) {
          this._mouseDownEvent[item] = event[item];
        }
        if (!this.controls) {
          return;
        }
        switch (this._mouseDownEvent.button) {
          case 1:
            if (this._mouseDownEvent.altKey && this._webglScene && this.zoom !== 1) {
              this._transformOriginMouseDown = [event.clientX, event.clientY];
              return;
            }
          case 2:
            if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
              this._webglScene.camera.entity.startTemporary();
            }
            break;
        }
        this._webglScene.stopEnvUpdate();
        if (!this.settings.interactiveProject) {
          this._webglScene.selectionBoxHelper.isDown = false;
          if (typeof PlanManager !== 'undefined') {
            var selection = PlanManager.getInstance().selection.slice();
            selection = selection.filter(function (item) {
              return this._webglScene.interactionWithItemAllowed(item);
            }.bind(this));
            if (event.ctrlKey && selection.length === 0) {
              this._webglScene.selectionBox.startPoint.set(event.offsetX / event.target.clientWidth * 2 - 1, (event.target.clientHeight - event.offsetY) / event.target.clientHeight * 2 - 1, 0.5);
              this._webglScene.selectionBoxHelper.isDown = true;
              return;
            } else {
              this._webglScene.selectionBoxHelper.onSelectOver();
            }
          }
        }
        Savane.eventsManager.dispatch(MOUSE_EVENTS.DOWN, {
          x: event.offsetX / event.target.clientWidth * 2 - 1,
          y: (event.target.clientHeight - event.offsetY) / event.target.clientHeight * 2 - 1,
          offsetX: event.offsetX,
          offsetY: event.offsetY,
          button: event.button,
          ctrl: event.ctrlKey,
          alt: event.altKey,
          shift: event.shiftKey
        });
        this._webglScene.render();
      };
      this.bindedElement.addEventListener("mousedown", this.mousedownHandler);
      this.dblclickHandler = event => {
        event.stopPropagation();
        if (this._webglScene && this._webglScene.renderer) {
          if (event.srcElement !== this._webglScene.renderer.domElement) {
            return;
          }
        }
        Savane.eventsManager.dispatch(MOUSE_EVENTS.DOUBLE_CLICK, {
          x: event.offsetX / event.target.clientWidth * 2 - 1,
          y: (event.target.clientHeight - event.offsetY) / event.target.clientHeight * 2 - 1,
          offsetX: event.offsetX,
          offsetY: event.offsetY,
          button: event.button,
          ctrl: event.ctrlKey,
          alt: event.altKey,
          shift: event.shiftKey
        });
        this._webglScene.render();
      };
      this.bindedElement.addEventListener("dblclick", this.dblclickHandler);
      this.mousemoveHandler = event => {
        event.stopPropagation();
        if (!this._webglScene) {
          return;
        }
        if (this._webglScene && this._webglScene.renderer) {
          if (event.srcElement !== this._webglScene.renderer.domElement && this.zoom === 1) {
            return;
          }
        }
        if (!this.loaded) {
          return;
        }
        this._mouseMoveEvent = {};
        for (let item in event) {
          this._mouseMoveEvent[item] = event[item];
        }
        if (this.settings.interactiveProject) {
          this._interactiveProjectHandler.Hovering(event);
        }
        if (!this._mouseDownEvent) {
          return;
        }
        const dx = event.offsetX - this._mouseDownEvent.offsetX;
        const dy = event.offsetY - this._mouseDownEvent.offsetY;
        this._mouseDownEvent.offsetX = event.offsetX;
        this._mouseDownEvent.offsetY = event.offsetY;
        if (!this.controls) {
          return;
        }
        this._webglScene.updateEnvs();
        switch (this._mouseDownEvent.button) {
          case 1:
            if (this._mouseDownEvent.altKey && this._webglScene && this.zoom !== 1) {
              this._webglScene.selectionBoxHelper.isDown = false;
              this._transformOriginOffset = [this._transformOriginOffset[0] + this.zoom * (this._transformOriginMouseDown[0] - event.clientX), this._transformOriginOffset[1] + this.zoom * (this._transformOriginMouseDown[1] - event.clientY)];
              this._transformOriginMouseDown = [event.clientX, event.clientY];
              this.transformOrigin = this._transformOriginOffset[0] + this._mouseDownEvent.target.clientWidth / 2 + "px " + (this._transformOriginOffset[1] + this._mouseDownEvent.target.clientHeight / 2) + "px";
              this._webglScene.renderer.domElement.style = "transform: scale(" + this.zoom + "); transform-origin: " + this.transformOrigin;
              this._delegate.render();
              return;
            }
            if (event.altKey) {
              this._webglScene.rotateCamera(-dx / event.target.clientWidth, dy / event.target.clientHeight, 400);
            } else if (event.ctrlKey) {
              this._webglScene.dollyCamera(dy / event.target.clientHeight * 400, {
                x: event.offsetX / event.target.clientWidth * 2 - 1,
                y: (event.target.clientHeight - event.offsetY) / event.target.clientHeight * 2 - 1
              });
            } else {
              this._webglScene.panCamera(this._mouseDownEvent.offsetX, this._mouseDownEvent.offsetY, dx, dy);
            }
            break;
          case 2:
            this._webglScene.focalCamera(dy / event.target.clientHeight * 60);
        }
        if (this._webglScene.camera) {
          this.cameraHeight = (this._webglScene.camera.entity.transform.localPosition[2] / 10).toFixed(1);
          this.cameraFocal = this._webglScene.camera.entity.fov.toFixed(1);
          this._delegate.render();
        }
        if (!this.settings.interactiveProject) {
          if (event.shiftKey && this._webglScene.selectionBoxHelper.isDown) {
            this._webglScene.selectionBox.endPoint.set(event.offsetX / event.target.clientWidth * 2 - 1, (event.target.clientHeight - event.offsetY) / event.target.clientHeight * 2 - 1, 0.5);
          }
        }
        Savane.eventsManager.dispatch(MOUSE_EVENTS.MOVE, {
          x: event.offsetX / event.target.clientWidth * 2 - 1,
          y: (event.target.clientHeight - event.offsetY) / event.target.clientHeight * 2 - 1,
          dx: dx / event.target.clientWidth,
          dy: dy / event.target.clientHeight,
          offsetX: event.offsetX,
          offsetY: event.offsetY,
          button: this._mouseDownEvent ? this._mouseDownEvent.button : null
        });
        this._webglScene.render();
      };
      this.bindedElement.addEventListener("mousemove", this.mousemoveHandler);
      this.mouseleaveHandler = event => {
        if (this._mouseOver) {
          if (this._mouseDownEvent) {
            switch (this._mouseDownEvent.button) {
              case 1:
              case 2:
                if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
                  PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this._webglScene.camera.entity));
                }
                break;
            }
          }
          this.bindedElement.style.cursor = 'default';
          this._mouseDownEvent = null;
          this._mouseOver = false;
          this.currentKeyPressed = null;
          if (this._dragState) {
            if (this._dragState.state === DRAG_STATE.COATING) {
              Savane.eventsManager.dispatch(Savane.Events.COATING_DRAG, this._dragState.data);
            } else if (this._dragState.state === DRAG_STATE.ENTITY) {
              Savane.eventsManager.dispatch(Savane.Events.ENTITY_EDITION, this._dragState.data);
            }
          }
          if (this._webglScene) {
            this._webglScene.keyPressed = {};
            if (this.settings.interactiveProject) {
              this._interactiveProjectHandler._clearHovering();
            }
            this._webglScene.render();
          }
        }
      };
      this.bindedElement.addEventListener("mouseleave", this.mouseleaveHandler);
      this.mouseoverHandler = event => {
        if (this.noFocus) {
          return;
        }
        if (!this._mouseOver) {
          this._mouseOver = true;
          document.getElementById("plan-webgl-rt").focus();
        }
        if (this._dragState) {
          Savane.eventsManager.dispatch(Savane.Events.DRAG_OVER_WEBGL);
        }
      };
      this.bindedElement.addEventListener("mouseover", this.mouseoverHandler);
      this.mouseupHandler = event => {
        event.stopPropagation();
        this._mouseDownEvent = null;
        if (!this._webglScene) {
          return;
        }
        if (this._webglScene && this._webglScene.renderer) {
          if (event.srcElement !== this._webglScene.renderer.domElement && this.zoom === 1) {
            return;
          }
        }
        if (!this._mouseOver) {
          return;
        }
        if (!this.controls) {
          return;
        }
        switch (event.button) {
          case 1:
          case 2:
            if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
              PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this._webglScene.camera.entity));
            }
            break;
        }
        this.bindedElement.style.cursor = 'default';
        switch (event.button) {
          case 0:
            if (this._dragState) {
              switch (this._dragState.state) {
                case DRAG_STATE.COATING:
                  this._webglScene.setCoatingOnDrop(event.offsetX / event.target.clientWidth * 2 - 1, (event.target.clientHeight - event.offsetY) / event.target.clientHeight * 2 - 1, this._dragState.value, this.currentKeyPressed);
                  break;
                case DRAG_STATE.ENTITY:
                  this._webglScene.setEntityOnDrop(event.offsetX / event.target.clientWidth * 2 - 1, (event.target.clientHeight - event.offsetY) / event.target.clientHeight * 2 - 1, this._dragState.value);
                  break;
              }
              this._dragState = null;
            }
          case 2:
            this._dragState = null;
            break;
        }
        if (typeof PlanManager !== 'undefined') {
          var selection = PlanManager.getInstance().selection.slice();
          selection = selection.filter(function (item) {
            return this._webglScene.interactionWithItemAllowed(item);
          }.bind(this));
          if (event.ctrlKey && selection.length === 0) {
            this._webglScene.selectionBox.endPoint.set(event.offsetX / event.target.clientWidth * 2 - 1, (event.target.clientHeight - event.offsetY) / event.target.clientHeight * 2 - 1, 0.5);
            this._webglScene.setBoxSelection();
            return;
          }
        }
        Savane.eventsManager.dispatch(MOUSE_EVENTS.UP, {
          x: event.offsetX / event.target.clientWidth * 2 - 1,
          y: (event.target.clientHeight - event.offsetY) / event.target.clientHeight * 2 - 1,
          offsetX: event.offsetX,
          offsetY: event.offsetY,
          button: event.button
        });
        this._webglScene.updateEnvs();
        this._webglScene.render();
      };
      this.bindedElement.addEventListener("mouseup", this.mouseupHandler);
      this.wheelHandler = event => {
        event.preventDefault();
        if (!this._webglScene) {
          return;
        }
        if (!this.controls) {
          return;
        }
        if (!this.loaded) {
          return;
        }
        var deltaY = event.deltaY;
        if (!deltaY && event.originalEvent) {
          deltaY = event.originalEvent.deltaY;
        }
        if (!deltaY) {
          return;
        }
        deltaY /= 100;
        if (event.altKey && this._webglScene && this._webglScene.camera && this._webglScene.camera.entity.cameraType === Savane.SceneConstants.CameraType.PhotoRender) {
          this.zoom -= deltaY * 0.1;
          if (this.zoom < 1) {
            this.zoom = 1;
          }
          this._webglScene.renderer.domElement.style = "transform: scale(" + this.zoom + "); transform-origin: " + this.transformOrigin;
          this._delegate.render();
          return;
        }

        // start temporary for camera update
        if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
          this._webglScene.camera.entity.startTemporary();
        }
        this._webglScene.stopEnvUpdate();
        this._webglScene.dollyCamera(deltaY / event.target.clientHeight * 4000, {
          x: event.offsetX / event.target.clientWidth * 2 - 1,
          y: (event.target.clientHeight - event.offsetY) / event.target.clientHeight * 2 - 1
        });
        this._webglScene.render();

        // end temporary after 800ms for camera update
        if (this.editRenderCameraCommandTimeout) {
          clearTimeout(this.editRenderCameraCommandTimeout);
        }
        this.editRenderCameraCommandTimeout = setTimeout(() => {
          if (this._webglScene && this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
            PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this._webglScene.camera.entity));
          }
          this.editRenderCameraCommandTimeout = null;
        }, 800);
      };
      this.bindedElement.addEventListener("wheel", this.wheelHandler);
      this.keydownHandler = event => {
        this.currentKeyPressed = event.keyCode;
        if (!this._webglScene) {
          return;
        }
        this._webglScene.keyPressed.shift = event.shiftKey;
        this._webglScene.keyPressed.ctrl = event.ctrlKey;
        this._webglScene.keyPressed.x = this.currentKeyPressed === 88;
        if (this.settings.interactiveProject) {
          this._interactiveProjectHandler.HandleKeyboard(event);
        }
        if (!this.controls) {
          return;
        }
        if (!this._mouseOver) {
          return;
        }
        if (!this.loaded) {
          return;
        }
        this._webglScene.stopEnvUpdate();

        // Camera shortcuts
        if ((event.keyCode === 65 || event.keyCode === 80 || event.keyCode === 72 || event.keyCode === 86 || event.keyCode === 67) && event.shiftKey) {
          this._changeCamera(event.keyCode);
        }
        switch (event.keyCode) {
          case 13:
            if (this._webglScene.camera && typeof PlanManager !== 'undefined') {
              if (this._webglScene.camera.entity.cameraType === Savane.SceneConstants.CameraType.Video && this._videoPreviewIntervalId === null) {
                const allArrangements = PlanManager.getInstance().world.currentScene.arrangementObjects;
                for (let i = 0; i < allArrangements.length; ++i) {
                  const entity = this._webglScene.getPlanEntity(allArrangements[i].id);
                  if (!entity) {
                    continue;
                  }
                  this._webglScene.setLayer(entity.object, 1);
                }
                this._videoPreviewNbUnhiddenObjects = 0;
                this._videoPreviewCurrentImage = 0;
                this._videoPreviewIntervalId = setInterval(this._videoPreviewStep, 200);
              }
            }
            break;
          case 90:
            //z
            if (!event.ctrlKey) {
              if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
                this._webglScene.camera.entity.startTemporary();
              }
              this._webglScene.targetCamera(this._mouseMoveEvent.offsetX / this._mouseMoveEvent.target.clientWidth * 2 - 1, (this._mouseMoveEvent.target.clientHeight - this._mouseMoveEvent.offsetY) / this._mouseMoveEvent.target.clientHeight * 2 - 1);
              if (this._webglScene.camera && this._webglScene.camera.entity.locked === false && typeof PlanManager !== 'undefined') {
                PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this._webglScene.camera.entity));
              }
            }
            if (event.ctrlKey) {
              this._webglScene.undoAction();
            }
            break;
          case 89:
            //y
            if (event.ctrlKey) {
              this._webglScene.redoAction();
            }
            break;
          case 40:
            //down
            if (this._bPressed) {
              if (typeof PlanManager !== 'undefined') {
                const selection = PlanManager.getInstance().selectedEntities;
                const newObjectsAndParents = [];
                const oldObjects = [];
                for (let i = 0; i < selection.length; i++) {
                  if (selection[i].isArrangementGroupEntity()) {
                    if (selection[i].masterObjectId) {
                      for (let j = 0; j < selection[i].children.length; j++) {
                        if (selection[i].children[j].objectId === selection[i].masterObjectId) {
                          oldObjects.push(selection[i]);
                          newObjectsAndParents.push({
                            entity: selection[i].children[j],
                            parent: selection[i].parent
                          });
                          break;
                        }
                      }
                    }
                  }
                }
                if (oldObjects.length > 0) {
                  PlanManager.getInstance().executeCommand(new Savane.Commands.ReplaceEntitiesCommand(oldObjects, newObjectsAndParents));
                }
              }
            } else {
              if (event.ctrlKey) {
                this._webglScene.snapSelectionDown();
              }
              if (this._webglScene.gizmo.enabled) {
                if (this._webglScene.gizmoPosition === "bottom") {
                  this._webglScene.gizmoPosition = "center";
                } else {
                  this._webglScene.gizmoPosition = "bottom";
                }
                this._webglScene.setGizmoPosition(true);
              }
            }
            break;
          case 38:
            //up
            if (event.ctrlKey) {
              this._webglScene.snapSelectionUp();
            }
            if (this._webglScene.gizmo.enabled) {
              if (this._webglScene.gizmoPosition === "top") {
                this._webglScene.gizmoPosition = "center";
              } else {
                this._webglScene.gizmoPosition = "top";
              }
              this._webglScene.setGizmoPosition(true);
            }
            break;
          case 68:
            //d
            if (event.ctrlKey && event.altKey) {
              if (typeof PlanManager !== 'undefined') {
                CocosUtils.cleanWorldTemplates();
              }
            } else {
              if (!event.ctrlKey && !event.altKey) {
                this._webglScene.setCameraDof(this._mouseMoveEvent.offsetX / this._mouseMoveEvent.target.clientWidth * 2 - 1, (this._mouseMoveEvent.target.clientHeight - this._mouseMoveEvent.offsetY) / this._mouseMoveEvent.target.clientHeight * 2 - 1);
                if (this._webglScene.camera && typeof PlanManager !== 'undefined') {
                  PlanManager.getInstance().executeCommand(new Savane.Commands.EditRenderCameraCommand(this._webglScene.camera.entity, true));
                }
              }
            }
            break;
          case 39:
            //right
            if (this._bPressed) {
              if (typeof PlanManager !== 'undefined') {
                const selection = PlanManager.getInstance().selectedEntities;
                const newObjectsAndParents = [];
                const oldObjects = [];
                async.eachSeries(selection, function (entity, endCallback) {
                  if (entity.isArrangementObjectEntity()) {
                    AssetManagerServices.loadPopulatedObjects(entity, oldObjects, newObjectsAndParents, function (displayWarning) {
                      this._displayWarning = displayWarning;
                      endCallback();
                    }.bind(this));
                  } else if (entity.isArrangementGroupEntity()) {
                    AssetManagerServices.switchPopulatedObjects(entity, 1, oldObjects, newObjectsAndParents, function (displayWarning) {
                      this._displayWarning = displayWarning;
                      endCallback();
                    }.bind(this));
                  }
                }.bind(this), function () {
                  if (oldObjects.length > 0) {
                    PlanManager.getInstance().executeCommand(new Savane.Commands.ReplaceEntitiesCommand(oldObjects, newObjectsAndParents));
                  }
                  if (this._displayWarning) {
                    Savane.eventsManager.dispatch(Savane.Events.DISPLAY_TOASTER, {
                      message: 'L\'opération demandée n\'a pas pu être effectuée sur au moins un des objets sélectionnés.'
                    });
                  }
                }.bind(this));
              }
            } else {
              if (this._vPressed) {
                if (typeof PlanManager !== 'undefined') {
                  const selection = PlanManager.getInstance().selectedEntities;
                  const newObjectsAndParents = [];
                  const oldObjects = [];
                  this._displayWarning = false;
                  async.eachSeries(selection, function (entity, endCallback) {
                    if (entity.isArrangementObjectEntity()) {
                      if (entity.closeObjects === undefined) {
                        this._loadCloseObjects(entity, 1, oldObjects, newObjectsAndParents, function () {
                          endCallback();
                        });
                      } else {
                        this._switchCloseObjects(entity, 1, oldObjects, newObjectsAndParents, function () {
                          endCallback();
                        });
                      }
                    }
                  }.bind(this), function (err) {
                    if (oldObjects.length > 0) {
                      PlanManager.getInstance().executeCommand(new Savane.Commands.ReplaceEntitiesCommand(oldObjects, newObjectsAndParents));
                    }
                    if (this._displayWarning) {
                      Savane.eventsManager.dispatch(Savane.Events.DISPLAY_TOASTER, {
                        message: 'L\'opération demandée n\'a pas pu être effectuée sur au moins un des objets sélectionnés.'
                      });
                    }
                  }.bind(this));
                }
              } else {
                if (this._webglScene.gizmo.enabled) {
                  if (this._webglScene.gizmoPosition === "center" || this._webglScene.gizmoPosition === "xleft" || this._webglScene.gizmoPosition === "yleft") {
                    this._webglScene.gizmoPosition = "xright";
                  } else if (this._webglScene.gizmoPosition === "xright") {
                    this._webglScene.gizmoPosition = "yright";
                  } else {
                    this._webglScene.gizmoPosition = "center";
                  }
                  this._webglScene.setGizmoPosition(true);
                }
              }
            }
            break;
          case 37:
            //left
            if (this._bPressed) {
              if (typeof PlanManager !== 'undefined') {
                const selection = PlanManager.getInstance().selectedEntities;
                const newObjectsAndParents = [];
                const oldObjects = [];
                async.eachSeries(selection, function (entity, endCallback) {
                  if (entity.isArrangementObjectEntity()) {
                    AssetManagerServices.loadPopulatedObjects(entity, oldObjects, newObjectsAndParents, function (displayWarning) {
                      this._displayWarning = displayWarning;
                      endCallback();
                    }.bind(this));
                  } else {
                    if (entity.isArrangementGroupEntity()) {
                      AssetManagerServices.switchPopulatedObjects(entity, -1, oldObjects, newObjectsAndParents, function (displayWarning) {
                        this._displayWarning = displayWarning;
                        endCallback();
                      }.bind(this));
                    }
                  }
                }.bind(this), function () {
                  if (oldObjects.length > 0) {
                    PlanManager.getInstance().executeCommand(new Savane.Commands.ReplaceEntitiesCommand(oldObjects, newObjectsAndParents));
                  }
                  if (this._displayWarning) {
                    Savane.eventsManager.dispatch(Savane.Events.DISPLAY_TOASTER, {
                      message: 'L\'opération demandée n\'a pas pu être effectuée sur au moins un des objets sélectionnés.'
                    });
                  }
                }.bind(this));
              }
            } else {
              if (this._vPressed) {
                if (typeof PlanManager !== 'undefined') {
                  const selection = PlanManager.getInstance().selectedEntities;
                  const newObjectsAndParents = [];
                  const oldObjects = [];
                  this._displayWarning = false;
                  async.eachSeries(selection, function (entity, endCallback) {
                    if (entity.isArrangementObjectEntity()) {
                      if (entity.closeObjects === undefined) {
                        this._loadCloseObjects(entity, -1, oldObjects, newObjectsAndParents, function () {
                          endCallback();
                        });
                      } else {
                        this._switchCloseObjects(entity, -1, oldObjects, newObjectsAndParents, function () {
                          endCallback();
                        });
                      }
                    }
                  }.bind(this), function (err) {
                    if (oldObjects.length > 0) {
                      PlanManager.getInstance().executeCommand(new Savane.Commands.ReplaceEntitiesCommand(oldObjects, newObjectsAndParents));
                    }
                    if (this._displayWarning) {
                      Savane.eventsManager.dispatch(Savane.Events.DISPLAY_TOASTER, {
                        message: 'L\'opération demandée n\'a pas pu être effectuée sur au moins un des objets sélectionnés.'
                      });
                    }
                  }.bind(this));
                }
              } else {
                if (this._webglScene.gizmo.enabled) {
                  if (this._webglScene.gizmoPosition === "center" || this._webglScene.gizmoPosition === "xright" || this._webglScene.gizmoPosition === "yright") {
                    this._webglScene.gizmoPosition = "xleft";
                  } else if (this._webglScene.gizmoPosition === "xleft") {
                    this._webglScene.gizmoPosition = "yleft";
                  } else {
                    this._webglScene.gizmoPosition = "center";
                  }
                  this._webglScene.setGizmoPosition(true);
                }
              }
            }
            break;
          case 46:
            //delete
            this._webglScene.deleteSelectedEntities();
            break;
          case 83:
            //s
            this._webglScene.toggleSnap();
            break;
          case 82:
            //r
            if (!this._dragState) {
              if (event.shiftKey) {
                this._webglScene.duplicateEntitiesOffset();
              } else if (this._webglScene.gizmo.mode === 'scale' && this._webglScene.gizmo.enabled) {
                this._webglScene.gizmo.enabled = false;
                this._webglScene.gizmo.visible = false;
                this._webglScene.gizmo.space = 'world';
              } else {
                this._webglScene.gizmo.setMode("scale");
                this._webglScene.gizmo.enabled = true;
                this._webglScene.gizmo.visible = true;
                this._webglScene.gizmo.space = 'local';
              }
              this._delegate.render();
            }
            break;
          case 70:
            //f
            if (this._loadedEntity) {
              if (Array.isArray(this._loadedEntity)) {
                for (let i = 0; i < this._loadedEntity.length; ++i) {
                  this._webglScene.fitCamera(this._loadedEntity[i], i);
                }
              } else {
                this._webglScene.fitCamera(this._loadedEntity);
              }
            }
            break;
          case 48: // zero
          case 96:
            // zero numpad
            this._webglScene.resetCameraPitch();
            break;
          case 71:
            // g
            if (typeof PlanManager !== 'undefined' && PlanManager.getInstance().selectedEntities.length > 1) {
              Savane.eventsManager.dispatch(Savane.Events.GROUP_ENTITIES);
            } else {
              this.thirdGrid = !this.thirdGrid;
            }
            this._delegate.render();
            break;
          case 81:
            // q
            const value = parseInt(Savane.SavaneCookie.getCookie("Rhinov-DesignerFilter-ObjectFilterEnabled", "" + 0));
            if (value === 0) {
              Savane.SavaneCookie.setCookie("Rhinov-DesignerFilter-ObjectFilterEnabled", "" + 1);
            } else {
              Savane.SavaneCookie.setCookie("Rhinov-DesignerFilter-ObjectFilterEnabled", "" + 0);
            }
            Savane.eventsManager.dispatch(Savane.Events.ENABLE_OBJECT_FILTERS_CHANGED, {
              forceUpdate: true
            });
            break;
          case 80:
            // p
            if (!event.shiftKey && this._webglScene.camera) {
              this._webglScene.toggleFreeCamera();
              this._cameraState = CAMERA_STATES.FREE;
              this._delegate.render();
            } else if (typeof PlanManager !== 'undefined' && PlanManager.getInstance().selectedEntities.length > 1) {
              PlanManager.getInstance().executeCommand(new Savane.Commands.SnapEntitiesCommand(PlanManager.getInstance().selectedEntities, Savane.Commands.SnapEntitiesCommand.SnapEnum.CenterOnTop));
            }
            break;
          case 86:
            // v
            if (!event.shiftKey && !event.ctrlKey) {
              this._vPressed = true;
            }
            if (event.ctrlKey) {
              this._webglScene.pasteCoating(this._mouseMoveEvent.offsetX / this._mouseMoveEvent.target.clientWidth * 2 - 1, (this._mouseMoveEvent.target.clientHeight - this._mouseMoveEvent.offsetY) / this._mouseMoveEvent.target.clientHeight * 2 - 1);
            }
            break;
          case 66:
            // b
            this._bPressed = true;
            break;
          case 84:
            //t
            cocosPosition.z = this._webglScene.getDefaultCameraDistanceFit();
            this._webglScene.defaultCamera.position.copy(cocosPosition);
            this._webglScene.defaultCamera.target = new THREE.Vector3(cocosPosition.x, cocosPosition.y, 0);
            this._webglScene.defaultCamera.up = new THREE.Vector3(0, 1, 0);
            this._webglScene.defaultCamera.lookAt(this._webglScene.defaultCamera.target);
            this._webglScene.defaultCameraMoved = false;
            this._webglScene.updateCamera(null);
            this._cameraState = CAMERA_STATES.FREE;
            this._delegate.render();
            break;
          case 67:
            //c
            if (event.ctrlKey) {
              this._webglScene.copyCoating(this._mouseMoveEvent.offsetX / this._mouseMoveEvent.target.clientWidth * 2 - 1, (this._mouseMoveEvent.target.clientHeight - this._mouseMoveEvent.offsetY) / this._mouseMoveEvent.target.clientHeight * 2 - 1);
            } else {
              if (!event.shiftKey) {
                if (this._enable_additionals_afters) {
                  this._webglScene.clearExcludedObject();
                  this._webglScene.createCameraFromFreeCamera(this.deliverable);
                }
              }
            }
            break;
          case 65:
            //a
            if (!event.shiftKey && !event.ctrlKey) {
              this._webglScene.toggleMagnet();
            }
            if (event.ctrlKey) {
              if (typeof PlanManager !== 'undefined') {
                PlanManager.getInstance().selectAll();
              }
            }
            break;
          case 85:
            //u
            Savane.eventsManager.dispatch(Savane.Events.UNGROUP_ENTITIES);
            break;
          case 32:
            //space
            if (typeof PlanManager !== 'undefined') {
              const selection = PlanManager.getInstance().selectedEntities;
              let doUpdateHull = true;
              for (let i = 0; i < selection.length; ++i) {
                const entity = selection[i];
                if (entity.isArrangementObjectEntity() || entity.isArrangementGroupEntity()) {
                  doUpdateHull = false;
                  entity.locked = !entity.locked;
                }
              }
              if (doUpdateHull) {
                this._updateHulls();
              }
            } else {
              this._updateHulls();
            }
            break;
          case 9:
            //tab
            this._changeGizmoSpace(event);
            this._delegate.render();
            break;
          case 87:
            //w
            if (event.ctrlKey && event.altKey) {
              if (typeof PlanManager !== 'undefined') {
                const selection = PlanManager.getInstance().selectedEntities;
                this._webglScene.detachSelection();
                for (let i = 0; i < selection.length; ++i) {
                  const entity = selection[i];
                  if (entity.isArrangementObjectEntity() && entity.locked !== true && entity.stretchability !== undefined && entity._excludeFromShoppingList === true) {
                    entity.disableStretchability();
                    const node = EntityManager.getNode(entity);
                    if (node !== null) {
                      node.addStretchability(false);
                      node.updateSprite();
                      node.needRedraw = true;
                    }
                    const webglEntity = this._webglScene.getPlanEntity(entity.id);
                    if (webglEntity) {
                      webglEntity.update();
                    }
                  }
                }
                this._webglScene.attachSelection();
              }
            } else {
              if (this._webglScene.gizmo.mode === 'translate' && this._webglScene.gizmo.enabled) {
                this._webglScene.gizmo.enabled = false;
                this._webglScene.gizmo.visible = false;
              } else {
                this._webglScene.gizmo.setMode("translate");
                this._webglScene.gizmo.enabled = true;
                this._webglScene.gizmo.visible = true;
              }
              this._delegate.render();
            }
            break;
          case 88:
            //x
            if (event.ctrlKey && event.altKey) {
              if (typeof PlanManager !== 'undefined') {
                const selection = PlanManager.getInstance().selectedEntities;
                this._webglScene.detachSelection();
                for (let i = 0; i < selection.length; ++i) {
                  const entity = selection[i];
                  if (entity.isArrangementObjectEntity() && entity.locked !== true && entity.stretchability === undefined) {
                    entity.enableStretchability();
                    const node = EntityManager.getNode(entity);
                    if (node !== null) {
                      node.addStretchability(true);
                      node.updateSprite();
                      node.needRedraw = true;
                    }
                    const webglEntity = this._webglScene.getPlanEntity(entity.id);
                    if (webglEntity) {
                      webglEntity.update();
                    }
                  }
                }
                this._webglScene.attachSelection();
              }
            }
            break;
          case 69:
            //e
            if (this._webglScene.gizmo.mode === 'rotate' && this._webglScene.gizmo.enabled) {
              this._webglScene.gizmo.enabled = false;
              this._webglScene.gizmo.visible = false;
            } else {
              this._webglScene.gizmo.setMode("rotate");
              this._webglScene.gizmo.enabled = true;
              this._webglScene.gizmo.visible = true;
            }
            this._delegate.render();
            break;
          case 79:
            //o
            // Toggle Holdout
            if (typeof PlanManager !== 'undefined') {
              const selection = PlanManager.getInstance().selectedEntities;
              for (var i = 0; i < selection.length; ++i) {
                var entity = selection[i];
                entity.holdout = !entity.holdout;
                if (this._webglScene) {
                  this._webglScene.updateHull(entity);
                }
              }
            }
            break;
          case 112:
            //F1
            if (typeof PlanManager !== 'undefined') {
              PlanManager.getInstance()._hideArrangements = !PlanManager.getInstance()._hideArrangements;
              Savane.eventsManager.dispatch(Savane.Events.HIDE_ARRANGEMENTS);
            }
            event.preventDefault();
            break;
          case 113:
            //F2
            if (typeof PlanManager !== 'undefined') {
              PlanManager.getInstance()._hideAxo = !PlanManager.getInstance()._hideAxo;
              Savane.eventsManager.dispatch(Savane.Events.HIDE_AXO);
            }
            break;
          case 114:
            //F3
            if (this._webglScene) {
              this._webglScene.hullTransparency = !this._webglScene.hullTransparency;
              this._webglScene.render();
            }
            event.preventDefault();
            break;
          case 115:
            //F4
            this._togglePhysics(event);
            this._delegate.render();
            break;
          case 120:
            //F9
            if (this._webglScene) {
              this._webglScene.renderPass.enabled = !this._webglScene.renderPass.enabled;
              this._webglScene.outlinePass.enabled = !this._webglScene.outlinePass.enabled;
              this._webglScene.composer.reset();
            }
            break;
          case 121:
            //F10
            if (this._webglScene) {
              const strMime = "image/png";
              const strDownloadMime = "image/octet-stream";
              const imgData = this._webglScene.renderer.domElement.toDataURL(strMime);
              const data = imgData.replace(strMime, strDownloadMime);
              const link = document.createElement('a');
              document.body.appendChild(link); //Firefox requires the link to be in the body
              link.download = "capture.png";
              link.href = data;
              link.click();
              document.body.removeChild(link);
            }
            break;
          case 72:
            //h
            if (this._webglScene && !event.shiftKey) {
              this._webglScene.displayHeight = !this._webglScene.displayHeight;
              this._delegate.render();
            }
            break;
          case 75:
            //k
            break;
          case 123:
            //F12
            if (this._webglScene) {
              this._webglScene.stats.setMode(0);
            }
            break;
          case 187:
            // +
            if (this.photoOpacity < 0.8) {
              this.photoOpacity += 0.1;
              this._delegate.render();
            }
            break;
          case 219:
            // -
            if (this.photoOpacity > 0.2) {
              this.photoOpacity -= 0.1;
              this._delegate.render();
            }
            break;
          default:
            event.preventDefault();
        }

        //number 1 -> 9
        if (event.keyCode >= 49 && event.keyCode <= 57) {
          if (this._displayPhoto) {
            setTimeout(() => {
              this._displayPhoto = false;
              this._delegate.render();
            });
          } else {
            this._setCurrentPhoto(event.keyCode - 49);
          }
        }
        this._webglScene.render();
      };
      this.keyupHandler = event => {
        this.currentKeyPressed = null;
        if (!this._webglScene) {
          return;
        }
        if (!this.loaded) {
          return;
        }
        this._webglScene.keyPressed = {};
        switch (event.keyCode) {
          case 66:
            this._bPressed = false;
            break;
          case 86:
            this._vPressed = false;
            break;
        }
        if (!this.controls) {
          return;
        }
        if (!this._mouseOver) {
          return;
        }
        this._webglScene.updateEnvs();
      };
      document.addEventListener("keydown", this.keydownHandler);
      document.addEventListener("keyup", this.keyupHandler);
      this.initialize();
      window.addEventListener('resize', this._resize);
      var destroyObserver = new MutationObserver((mutationsList, observer) => {
        mutationsList.forEach(mutation => {
          if (mutation.type === 'childList' && Array.from(mutation.removedNodes).includes(this.bindedElement)) {
            this.destroy();
          }
        });
      });
      var observerConfig = {
        childList: true
      };
      destroyObserver.observe(document.body, observerConfig);
      if (callback) {
        callback();
      }
    });
  }
}
exports.WebGLController = WebGLController;

},{}],3:[function(require,module,exports){
"use strict";

exports.__esModule = true;
exports.WebGLUi = exports.ElementWrapper = void 0;
var _webglController = require("./webgl-controller");
class ElementWrapper {
  constructor(element) {
    this.element = element;
  }
  appendChild(childElement) {
    this.element.appendChild(childElement);
    return this;
  }
  after(siblingElement) {
    this.element.parentNode.insertBefore(siblingElement, this.element.nextSibling);
    return this;
  }
  getValue() {
    return this.element;
  }
}
exports.ElementWrapper = ElementWrapper;
class WebGLUi {
  // BEGIN: getters and setters

  get dom() {
    return this._dom;
  }
  set dom(value) {
    this._dom = value;
  }
  get thirdGrid() {
    return this._controller.thirdGrid;
  }
  set thirdGrid(newValue) {
    this._controller.thirdGrid = newValue;
  }
  get loaded() {
    return this._controller.loaded;
  }
  set loaded(newValue) {
    this._controller.loaded = newValue;
  }
  get zoom() {
    return this._controller.zoom;
  }
  set zoom(newValue) {
    this._controller.zoom = newValue;
  }
  get jsonScene() {
    return this._controller.jsonScene;
  }
  set jsonScene(value) {
    this._controller.jsonScene = value;
  }
  get activeCameraId() {
    return this._controller.activeCameraId;
  }
  set activeCameraId(value) {
    this._controller.activeCameraId = value;
  }
  get entity() {
    return this._controller.entity;
  }
  set entity(value) {
    this._controller.entity = value;
  }
  get entities() {
    return this._controller.entities;
  }
  set entities(value) {
    this._controller.entities = value;
  }
  get config() {
    return this._controller.config;
  }
  set config(value) {
    this._controller.config = value;
  }
  get lod() {
    return this._controller.lod;
  }
  set lod(value) {
    this._controller.lod = value;
  }
  get imgProject() {
    return this._controller.imgProject;
  }
  set imgProject(value) {
    this._controller.imgProject = value;
  }
  get leftPanel() {
    return this._controller.leftPanel;
  }
  set leftPanel(value) {
    this._controller.leftPanel = value;
  }
  get deliverable() {
    return this._controller.deliverable;
  }
  set deliverable(value) {
    this._controller.deliverable = value;
  }
  get deliverableId() {
    return this._controller.deliverableId;
  }
  set deliverableId(value) {
    this._controller.deliverableId = value;
  }
  get user() {
    return this._controller.user;
  }
  set user(value) {
    this._controller.user = value;
  }
  get controls() {
    return this._controller.controls;
  }
  set controls(value) {
    this._controller.controls = value;
  }
  get useCameraRatio() {
    return this._controller.useCameraRatio;
  }
  set useCameraRatio(value) {
    this._controller.useCameraRatio = value;
  }
  get noFocus() {
    return this._controller.noFocus;
  }
  set noFocus(newValue) {
    this._controller.noFocus = newValue;
  }
  get settings() {
    return this._controller.settings;
  }
  set settings(value) {
    this._controller.settings = value;
  }

  // END: getters and setters

  constructor() {
    this._elementsDisplayRules = {};
    const delegate = {
      render: this._render.bind(this)
    };
    this._controller = new _webglController.WebGLController(delegate);
  }
  destroy() {
    this._controller.destroy();
    if (this._bindedElement) {
      this._bindedElement.replaceChildren();
    }
  }
  resize() {
    if (this._bindedElement) {
      this._controller._resize();
    }
  }
  run(parentElement, callback) {
    this._bindedElement = parentElement;
    this._bindedElement.appendChild(this._buildStyles());
    this._buildHtml();
    this._bindedElement.appendChild(this.dom);
    this._controller.run(this._bindedElement, callback);
    this._render();
  }
  _buildHtml() {
    this._buildContainerHtml();
    this._buildThirdGridHtml();
    this._buildLoadedHtml();
    this._buildTextCanvasHtml();
    this._buildCameraParametersHtml();
    this._buildDetailsSliderHtml();
    this._buildDisplayQualityHtml();
    this._buildAmSwitchHtml();
    this._buildFullScreenHtml();
    this._buildFreeCameraHtml();
    this._buildGizmoSpaceHtml();
    this._buildTogglePhysicsHtml();
    this._buildUpdateHullsHtml();
    this._buildPhotoFromProjectHtml();
  }
  _buildStyles() {
    const style = this._createElement('style', null, null, {
      type: 'text/css'
    });
    style.appendChild(document.createTextNode(`
            .webgl-camera {
                color: black;
            }
        
            .webgl-camera.webgl-camera-active {
                color: yellow;
            }
        
            .webgl-quality {
                color: black;
            }
        
            .webgl-quality.webgl-quality-active {
                color: yellow;
            }

            .selectBox {
                border: 1px solid #55aaff;
                background-color: rgba(75, 160, 255, 0.3);
                position: fixed;
            }

            #plan-webgl-rt > * {
                display: none;
            }

            #plan-webgl-rt {

                #webglcanvas {
                    display: block !important;
                }

                &.third-grid-html {
                    #third-grid-html {
                        display: block !important;
                    }
                }

                &.loaded-html {
                    #loaded-html {
                        display: block;
                    }
                }

                &.text-canvas-html {
                    #text-canvas-html {
                        display: block !important;
                    }
                }
                
                &.camera-parameters-html {
                    #camera-parameters-html {
                        display: block !important;
                    }
                }
                
                &.details-slider-html {
                    #details-slider-html {
                        display: block !important;
                    }
                }
                
                &.display-quality-html {
                    #display-quality-html {
                        display: block !important;
                    }
                }

                &.am-switch-html {
                    #am-switch-html {
                        display:flex;
                    }
                }

                &.full-screen1-html {
                    #full-screen1-html {
                        display: block !important;
                    }
                }
                
                &.full-screen2-html {
                    #full-screen2-html {
                        display: block !important;
                    }
                }

                &.free-camera-html {
                    #free-camera-html {
                        display: flex !important;
                    }
                }

                &.gizmo-space-html {
                    #gizmo-space-html {
                        display: block !important;
                    }
                }

                &.toggle-physics-html {
                    #toggle-physics-html {
                        display: block !important;
                    }
                }

                &.update-hulls-html {
                    #update-hulls-html {
                        display: block !important;
                    }
                }

                &.photo-from-project-html {
                    #photo-from-project-html {
                        display: block !important;
                    }
                }
            }
        `));
    return style;
  }
  _buildContainerHtml() {
    this.dom = this._createElement('div', 'plan-webgl-rt', "width:100%;height:100%;position:relative;margin:auto;outline:none;user-select:none;overflow: hidden;");
    this.dom.tabIndex = '1';
  }
  _buildThirdGridHtml() {
    const id = 'third-grid-html';
    const wrapper = new ElementWrapper(this._createElement('div', id, "position: absolute; width: 100%; height: 100%; pointer-events: none;"));
    this.dom.appendChild(wrapper.getValue());
    this._elementsDisplayRules[id] = 'this.thirdGrid';
    wrapper.appendChild(this._createElement('div', null, "position: absolute; top: 33.3333%; border-top: 1.1px solid black; width: 100%")).appendChild(this._createElement('div', null, "position: absolute; top: 50%; border-top: 1.1px solid rgb(80, 80, 80); width: 100%")).appendChild(this._createElement('div', null, "position: absolute; top: 66.6666%; border-top: 1.1px solid black; width: 100%")).appendChild(this._createElement('div', null, "position: absolute; left: 33.3333%; border-left: 1.1px solid black; height: 100%")).appendChild(this._createElement('div', null, "position: absolute; left: 50%; border-left: 1.1px solid rgb(80, 80, 80); height: 100%")).appendChild(this._createElement('div', null, "position: absolute; left: 66.6666%; border-left: 1.1px solid black; height: 100%"));
  }
  _buildLoadedHtml() {
    const id = 'loaded-html';
    const element = this._createElement('div', id, "position: absolute; width: 100%; height: 100%; pointer-events: all; background-color: rgba(0, 0, 0, 0.8);");
    this.dom.appendChild(element);
    this._elementsDisplayRules[id] = 'this._controller.loaded === false';
    const lastAddedElement = element.appendChild(this._createElement('div', null, "margin: 0; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%;")).appendChild(this._createElement('div', null, "text-align: center;"));
    const imageTag = this._createElement('img');
    imageTag.src = PLAN_WEBGL_MODULE_PATH + '/medias/loader.gif';
    lastAddedElement.appendChild(imageTag);
    const firstLoadingText = this._createElement('div');
    const secondLoadingText = this._createElement('div');
    firstLoadingText.classList.add("fabrikat");
    secondLoadingText.classList.add("valentine");
    firstLoadingText.textContent = "Votre projet est en cours de chargement...";
    secondLoadingText.textContent = "Cela peut prendre quelques minutes, merci de patienter";
    new ElementWrapper(lastAddedElement).after(firstLoadingText).after(secondLoadingText);
  }
  _buildTextCanvasHtml() {
    const id = 'text-canvas-html';
    const wrapper = new ElementWrapper(this._createElement('div', id, "position: absolute; width: 100%; height: 100%; pointer-events: none;z-index: 2;"));
    this.dom.appendChild(wrapper.getValue());
    this._elementsDisplayRules[id] = 'this._controller.displayHeight() && !(this.entity || this.entities) && this.zoom === 1';
    wrapper.appendChild(this._createElement('canvas', 'plan-webgl-rt-text-canvas', "width: 100%; height: 100%; pointer-events: none;"));
  }
  _buildCameraParametersHtml() {
    const id = 'camera-parameters-html';
    const wrapper = new ElementWrapper(this._createElement('div', id, "position: absolute; right: 6px; top: 0px; pointer-events: none; font-weight: 600;z-index: 2;"));
    this.dom.appendChild(wrapper.getValue());
    this._elementsDisplayRules[id] = 'this._controller.displayCameraParameters() && !(this.entity || this.entities) && this.zoom === 1';
    const firstSpan = this._createElement('span', null, "color: black;");
    firstSpan.dynTextContent = 'Hauteur: {{this._controller.cameraHeight}}cm ';
    const secondSpan = this._createElement('span', null, "color: black;");
    secondSpan.dynTextContent = 'Focal: {{this._controller.cameraFocal}}°';
    wrapper.appendChild(firstSpan).appendChild(secondSpan);
  }
  _buildDetailsSliderHtml() {
    const id = 'details-slider-html';
    const wrapper = new ElementWrapper(this._createElement('div', id, "position:absolute;right:40px;bottom:4px;display:flex;z-index: 2;"));
    this.dom.appendChild(wrapper.getValue());
    this._elementsDisplayRules[id] = 'this.controls && !(this.entity || this.entities) && this.zoom === 1';
    this._displaySettings = false;
    const icon = this._createElement('i', null, "color:black;cursor:pointer;");
    icon.addEventListener("click", () => {
      this._displaySettings = !this._displaySettings;
      this._render();
    });
    icon.classList.add("fas");
    icon.classList.add("fa-cogs");
    wrapper.appendChild(icon);
  }
  _buildDisplayQualityHtml() {
    const id = 'display-quality-html';
    const wrapper = new ElementWrapper(this._createElement('div', id, "position: absolute; bottom: 0; padding: 10px; left: 25%; width: 50%; z-index: 10; background-color: rgba(0, 0, 0, 0.75);"));
    this.dom.appendChild(wrapper.getValue());
    this._elementsDisplayRules[id] = 'this._displaySettings';
    const firstContainer = this._createElement('div', null, "width: 50%; float: left; font-size: 12px");
    const secondContainer = this._createElement('div', null, "width: 50%; float: right; font-size: 12px");
    wrapper.appendChild(firstContainer).appendChild(secondContainer);
    const firstIds = [{
      id: 'settings-uvlp',
      text: 'Qualité basse'
    }, {
      id: 'settings-vlp',
      text: 'Qualité normale'
    }, {
      id: 'settings-ls',
      text: 'Qualité haute'
    }];
    for (let i = 0; i < firstIds.length; i++) {
      const radioWrapper = this._createElement('div');
      firstContainer.appendChild(radioWrapper);
      const radioLabel = this._createElement('label', null, "color: white;", {
        'for': firstIds[i].id
      });
      radioLabel.textContent = firstIds[i].text;
      const radioInput = this._createElement('input', firstIds[i].id, null, {
        'type': 'radio',
        'name': 'meshLevel',
        'value': i.toString(),
        'bind-model': 'settings.meshLevel'
      });
      radioInput.addEventListener('change', event => {
        this._controller.meshLevel = parseInt(event.target.value);
        this._controller._updateSettings();
        this._render();
      });
      new ElementWrapper(radioWrapper).appendChild(radioInput).appendChild(radioLabel);
    }
    const secondIds = [{
      id: 'settings-hullbin',
      model: 'settings.hullbin',
      text: 'Coque rapide'
    }, {
      id: 'settings-aliasing',
      model: 'settings.fxaa',
      text: 'Anti-aliasing'
    }, {
      id: 'settings-env',
      model: 'settings.environement',
      text: 'Calcul des environements'
    }, {
      id: 'settings-mirrors',
      model: 'settings.mirrors',
      text: 'Miroir'
    }];
    for (let i = 0; i < secondIds.length; i++) {
      const checkboxWrapper = this._createElement('div');
      secondContainer.appendChild(checkboxWrapper);
      const checkboxLabel = this._createElement('label', null, "color: white;", {
        'for': secondIds[i].id
      });
      checkboxLabel.textContent = secondIds[i].text;
      const checkboxInput = this._createElement('input', secondIds[i].id, null, {
        'type': 'checkbox',
        'bind-model': secondIds[i].model
      });
      checkboxInput.addEventListener('change', event => {
        eval('this.' + secondIds[i].model + ' = event.target.checked');
        this._controller._updateSettings();
        this._render();
      });
      new ElementWrapper(checkboxWrapper).appendChild(checkboxInput).appendChild(checkboxLabel);
    }
  }
  _buildAmSwitchHtml() {
    const id = 'am-switch-html';
    const element = this._createElement('div', id, "position:absolute;right:10px;bottom:10px;flex-direction: column;background-color: rgba(0, 0, 0, 0.75); padding: 5px; border-radius: 5px;");
    this.dom.appendChild(element);
    this._elementsDisplayRules[id] = 'this.entity || this.entities';

    // Add radio buttons

    const radioIds = ['lit', 'wireframe', 'normals', 'uvs', 'uvs checker', 'uvs lines'];
    for (let i = 0; i < radioIds.length; i++) {
      const radioWrapper = this._createElement('div');
      element.appendChild(radioWrapper);
      const radioLabel = this._createElement('label', null, "color: white;", {
        'for': radioIds[i]
      });
      radioLabel.textContent = radioIds[i].charAt(0).toUpperCase() + radioIds[i].slice(1);
      const radioInput = this._createElement('input', radioIds[i], null, {
        'type': 'radio',
        'name': 'display',
        'value': i.toString(),
        'bind-model': 'settings.display'
      });
      radioInput.addEventListener('change', event => {
        this.settings = {
          ...this.settings,
          display: parseInt(event.target.value)
        };
      });
      new ElementWrapper(radioWrapper).appendChild(radioInput).appendChild(radioLabel);
    }
  }
  _buildFullScreenHtml() {
    let id = 'full-screen1-html';
    const element = this._createElement('div', id, 'position:absolute;top:2px;left:8px;z-index: 2;');
    this.dom.appendChild(element);
    this._elementsDisplayRules[id] = "this.controls && !(this.entity || this.entities) && this.zoom === 1";
    const icon = this._createElement('i', null, "color:black;cursor:pointer;");
    icon.addEventListener("click", event => {
      this._controller._fullScreen(event);
      this._render();
    });
    icon.classList.add("glyphicon");
    icon.classList.add("glyphicon-fullscreen");
    element.appendChild(icon);
    id = 'full-screen2-html';
    const wrapper = new ElementWrapper(this._createElement('div', id, "position:absolute;top:2px;left:30px;z-index: 2;"));
    this.dom.appendChild(wrapper.getValue());
    this._elementsDisplayRules[id] = "this.controls && !(this.entity || this.entities) && this.zoom === 1";
    id = 'glDisplayCurrentFloor';
    const input = this._createElement('input', id, null, {
      'type': 'checkbox',
      'bind-model': '_controller._displayCurrentFloor'
    });
    input.addEventListener('change', event => {
      this._controller._displayCurrentFloor = event.target.checked;
      this._controller._displayCurrentFloorChanged(this._controller._displayCurrentFloor);
      this._render();
    });
    const label = this._createElement('label', null, null, {
      'for': id
    });
    label.textContent = "Afficher l'étage courant";
    wrapper.appendChild(input).appendChild(label);
  }
  _buildFreeCameraHtml() {
    const id = 'free-camera-html';
    const wrapper = new ElementWrapper(this._createElement('div', id, "position:absolute;top:24px;left:8px;flex-direction:row;z-index: 2;"));
    this.dom.appendChild(wrapper.getValue());
    this._elementsDisplayRules[id] = "this._controller._cameraState !== CAMERA_STATES.FRONT && !this._controller._isFullscreen && this.controls && !(this.entity || this.entities) && this.zoom === 1";
    const element = this._createElement('div', null, "font-weight:800;cursor:pointer;", {
      'dyn-class': "{ 'webgl-camera-active': this._controller._cameraState === CAMERA_STATES.FREE}"
    });
    element.addEventListener('mousedown', event => {
      this._controller._setFreeCamera(event);
      this._render();
    });
    element.classList.add("webgl-camera");
    element.textContent = "FREE";
    wrapper.appendChild(element);
  }
  _buildGizmoSpaceHtml() {
    const id = 'gizmo-space-html';
    const element = this._createElement('div', id, "position:absolute;top:24px;right:14px;display:flex;flex-direction:row;z-index: 2;");
    this.dom.appendChild(element);
    this._elementsDisplayRules[id] = "this._controller._cameraState !== CAMERA_STATES.FRONT && this.controls && this._controller._gizmoEnabled() && this.zoom === 1";
    const icon = this._createElement('i', null, null, {
      'dyn-style': "color: {{this._controller._gizmoSpace() === 'world' ? 'green' : 'black'}};cursor:pointer;"
    });
    icon.classList.add("fas");
    icon.classList.add("fa-lg");
    icon.classList.add("fa-globe-europe");
    icon.addEventListener("mousedown", event => {
      this._controller._changeGizmoSpace(event);
      this._render();
    });
    element.appendChild(icon);
  }
  _buildTogglePhysicsHtml() {
    const id = 'toggle-physics-html';
    const element = this._createElement('div', id, "position:absolute;top:24px;right:42px;display:flex;flex-direction:row;z-index: 2;");
    this.dom.appendChild(element);
    this._elementsDisplayRules[id] = "this.controls && this._controller._gizmoEnabled() && this.zoom";
    const icon = this._createElement('i', null, null, {
      'dyn-style': "color: {{this._controller._physicEnabled() ? 'green' : 'red'}};cursor:pointer;"
    });
    icon.classList.add("fas");
    icon.classList.add("fa-lg");
    icon.classList.add("fa-car-crash");
    icon.addEventListener("mousedown", event => {
      this._controller._togglePhysics(event);
      this._render();
    });
    element.appendChild(icon);
  }
  _buildUpdateHullsHtml() {
    const id = "update-hulls-html";
    const element = this._createElement('div', id, "position:absolute;right:8px;bottom:4px;display:flex;z-index: 2;");
    this.dom.appendChild(element);
    this._elementsDisplayRules[id] = "this.controls && !(this.entity || this.entities) && this.zoom === 1";
    const icon = this._createElement('i', null, "color:black;cursor:pointer;");
    icon.classList.add("fas");
    icon.classList.add("fa-sync-alt");
    icon.addEventListener("mousedown", event => {
      this._controller._updateHulls(event);
      this._render();
    });
    element.appendChild(icon);
  }
  _buildPhotoFromProjectHtml() {
    const id = "photo-from-project-html";
    const element = this._createElement('div', id, "position:absolute;top:0px;left:0px;bottom:0px;width:100%;pointer-events: none;z-index: 1;");
    this.dom.appendChild(element);
    this._elementsDisplayRules[id] = "this._controller._displayPhoto";
    element.appendChild(this._createElement('img', 'photoDisplay', null, {
      'dyn-style': "opacity: {{'' + this._controller.photoOpacity}};width: 100%;height:100%;transform: scale({{this.zoom}}); transform-origin: {{this._controller.transformOrigin}};",
      'dyn-src': "{{this._controller._getOriginalPhotoUrl()}}"
    }));
  }
  _buildResetZoomHtml() {
    const id = "reset-zoom-html";
    const element = this._createElement('div', id, "position:absolute;top:2px;left:8px;z-index: 2;");
    this.dom.appendChild(element);
    this._elementsDisplayRules[id] = "this.zoom !== 1";
    const icon = thic._createElement('i', null, "color:black;cursor:pointer;");
    icon.classList.add("glyphicon");
    icon.classList.add("glyphicon-refresh");
    icon.addEventListener("mousedown", () => {
      this._controller._resetZoom();
    });
    element.appendChild(icon);
  }
  _createElement(tagName, id, styles, attributes) {
    if (!tagName) {
      return;
    }
    const res = document.createElement(tagName);
    if (id) {
      res.id = id;
    }
    if (styles) {
      res.style.cssText = styles;
    }
    if (attributes) {
      Object.entries(attributes).forEach(attribute => {
        res.setAttribute(attribute[0], attribute[1]);
      });
    }
    return res;
  }
  _render() {
    if (!this.dom) {
      return;
    }

    // Hide or show elements
    Object.entries(this._elementsDisplayRules).forEach(entry => {
      if (eval(entry[1])) {
        this.dom.classList.add(entry[0]);
      } else {
        this.dom.classList.remove(entry[0]);
      }
    });

    // Trigger models
    this.dom.classList.forEach(id => {
      const element = document.getElementById(id);
      if (element) {
        const inputNodes = element.querySelectorAll('input');
        const inputList = [...inputNodes];
        inputList.forEach(input => {
          const value = input.getAttribute('bind-model');
          if (value) {
            if (input.getAttribute('type') === 'radio') {
              if (eval('this.' + value) == input.getAttribute('value')) {
                input.checked = true;
              }
            } else if (input.getAttribute('type') === 'checkbox') {
              input.checked = !!eval("this." + value);
            }
          }
        });
      }
    });

    // Replace {{everything between}}
    this._executeOnHtml(this.dom, element => {
      const dynClass = element.getAttribute && element.getAttribute('dyn-class');
      let dynStyle = element.getAttribute && element.getAttribute('dyn-style');
      let dynSrc = element.getAttribute && element.getAttribute('dyn-src');
      if (element.dynTextContent) {
        const matches = element.dynTextContent.match(/\{\{(.*?)\}\}/gs);
        if (matches) {
          matches.forEach(toEval => {
            element.textContent = element.dynTextContent.replace(toEval, eval(toEval.slice(2, -2)));
          });
        }
      }
      // 'dyn-class' attribute alows to add css classes dynamicaly
      if (dynClass) {
        const computedClasses = eval('(' + dynClass + ')');
        Object.keys(computedClasses).forEach(key => {
          if (computedClasses[key]) {
            element.classList.add(key);
          } else {
            element.classList.remove(key);
          }
        });
      }
      // 'dyn-style' attribute alows to set styles dynamicaly
      if (dynStyle) {
        const matches = dynStyle.match(/\{\{(.*?)\}\}/gs);
        if (matches) {
          matches.forEach(toEval => {
            dynStyle = dynStyle.replace(toEval, eval(toEval.slice(2, -2)));
          });
          element.style.cssText = dynStyle;
        }
      }
      // 'dyn-src' attribute sets source dynamicaly
      if (dynSrc) {
        const matches = dynSrc.match(/\{\{(.*?)\}\}/gs);
        if (matches) {
          matches.forEach(toEval => {
            dynSrc = dynSrc.replace(toEval, eval(toEval.slice(2, -2)));
          });
          element.src = dynSrc;
        }
      }
    });
  }
  _executeOnHtml(node, callback) {
    let child = node.firstChild;
    while (child) {
      callback(child);
      if (child.nodeType === Node.ELEMENT_NODE) {
        this._executeOnHtml(child, callback);
      }
      child = child.nextSibling;
    }
  }
}
exports.WebGLUi = WebGLUi;

},{"./webgl-controller":2}]},{},[1])(1)
});


