/**
 * Created by rhinov?? on ??/??/20??.
 *
 * WebGL camera management
 */

function WebglCamera(cameraEntity)
{
    // Savane entity
    this.entity = cameraEntity;

    // Three entity
    this.group = new THREE.Group();
    this.object = new THREE.PerspectiveCamera(this.entity.fov, 1, 1, 1000);
    this.object.up.set(0, 0, 1);

    // Add this camera to the group
    this.group.add(this.object);

    // Div Containing the webGL scene
    // var divWebgl = $("#plan-webgl-rt");
    var divWebgl = document.getElementById("plan-webgl-rt");

    // Activate/ Deactivate to process ratio
    this.activate(divWebgl.clientWidth / divWebgl.clientHeight);
};

WebglCamera.prototype =
{
    constructor: WebglCamera,

    // Activate the camera with a screen ratio
    activate: function(ratio)
    {
        // If the screen ratio is given, update the camera screen ratio otherwise use the previous one
        if (ratio !== undefined )
        {
          this.object.aspect = ratio;
        }

        this.object.fov = this.entity.fov;
        // The projection matrix is defined by Photo2Savane - Use it
        this.updateProjectionMatrix();

        // Update THREE matrix based on savane matrix datas
        var localMatrix = this.entity.transform.localMatrix;

        // Convert matrix to three space and apply to object
        var X = new THREE.Vector3().set(localMatrix[0], localMatrix[1], localMatrix[2]).normalize();
        var Y = new THREE.Vector3().set(localMatrix[8], localMatrix[9], localMatrix[10]).normalize();//.applyAxisAngle(X, Math.PI / 2);
        var Z = new THREE.Vector3().set(-localMatrix[4], -localMatrix[5], -localMatrix[6]).normalize();//.applyAxisAngle(X, Math.PI / 2);

        var basis = new THREE.Matrix4().makeBasis(X, Y, Z);
        this.object.quaternion.setFromRotationMatrix(basis);
        this.object.position.set(localMatrix[12] / 100, localMatrix[13] / 100, localMatrix[14] / 100);
        this.object.updateMatrix();
    },

    updateProjectionMatrix: function() {
        if (this.entity.projection)
        {
            var projection = this.entity.projection;
            this.object.projectionMatrix.set(
                projection[0], projection[4], projection[8], projection[12],
                projection[1], projection[5], projection[9], projection[13],
                projection[2], projection[6], projection[10], projection[14],
                projection[3], projection[7], projection[11], projection[15]);
            this.object.projectionMatrixInverse.copy(this.object.projectionMatrix).invert();
        } else {
            if (this.entity.cameraType === Savane.SceneConstants.CameraType.Axonomic) {
                this.object.far = 5000;
            }

            this.object.updateProjectionMatrix();
            
            //apply vertical shift
            var shift = this.entity.verticalShift / 100;
            var shear = new THREE.Matrix4().makeShear(0, 0, 0, shift, 0, 0);
            this.object.projectionMatrix.multiply(shear);

            this.object.projectionMatrixInverse.copy(this.object.projectionMatrix).invert();
        }
    },

    // Apply back the changes of the camera on the corresponding Savane entity
    applyToEntity: function()
    {
        // Make a safe copy
        var mat = new THREE.Matrix4();
        mat.copy(this.object.matrix);
        // Generate array
        var array = [];
        mat.toArray(array);
        // Generate Savane matrix
        var savaneMatrix = Savane.math.mat4.create();
        Savane.math.mat4.set(savaneMatrix,
          array[0], array[1], array[2], array[3],
          -array[8], -array[9], -array[10], array[11],
          array[4], array[5], array[6], array[7],
          array[12] * 100, array[13] * 100, array[14] * 100, array[15]);
        // FIXME use command ?
        this.entity.transform.localMatrix = savaneMatrix;
    }
};
