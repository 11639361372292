/**
 * Loads a Wavefront .mtl file specifying materials
 * This importer is based on the threejs one but have some specific modifications for our use
 * DO NOT REPLACE IT
 *
 * @author angelxuanchang
 */

var SAVANE = SAVANE || {};
SAVANE.MTLLoader = function(manager) {

    this.manager = (manager !== undefined) ? manager : THREE.DefaultLoadingManager;
    // Added to standard code to manage level detail
    this.quality = '';

};

SAVANE.MTLLoader.prototype = {

    constructor: SAVANE.MTLLoader,

	/**
	 * Loads and parses a MTL asset from a URL.
	 *
	 * @param {String} url - URL to the MTL file.
	 * @param {Function} [onLoad] - Callback invoked with the loaded object.
	 * @param {Function} [onProgress] - Callback for download progress.
	 * @param {Function} [onError] - Callback for download errors.
	 *
	 * @see setPath setTexturePath
	 *
	 * @note In order for relative texture references to resolve correctly
	 * you must call setPath and/or setTexturePath explicitly prior to load.
	 */
    load: function(url, onLoad, onProgress, onError) {

        var scope = this;

        var loader = new THREE.FileLoader(this.manager);
        loader.setPath(this.path);
        loader.load(url, function(text) {

            onLoad(scope.parse(text));

        }, onProgress, onError);

    },

	/**
	 * Set base path for resolving references.
	 * If set this path will be prepended to each loaded and found reference.
	 *
	 * @see setTexturePath
	 * @param {String} path
	 * @return {THREE.MTLLoader}
	 *
	 * @example
	 *     mtlLoader.setPath( 'assets/obj/' );
	 *     mtlLoader.load( 'my.mtl', ... );
	 */
    setPath: function(path) {

        this.path = path;
        return this;

    },

	/**
	 * Set base path for resolving texture references.
	 * If set this path will be prepended found texture reference.
	 * If not set and setPath is, it will be used as texture base path.
	 *
	 * @see setPath
	 * @param {String} path
	 * @return {THREE.MTLLoader}
	 *
	 * @example
	 *     mtlLoader.setPath( 'assets/obj/' );
	 *     mtlLoader.setTexturePath( 'assets/textures/' );
	 *     mtlLoader.load( 'my.mtl', ... );
	 */
    setTexturePath: function(path) {

        this.texturePath = path;
        return this;

    },

    setBaseUrl: function(path) {

        console.warn('THREE.MTLLoader: .setBaseUrl() is deprecated. Use .setTexturePath( path ) for texture path or .setPath( path ) for general base path instead.');

        return this.setTexturePath(path);

    },

    setCrossOrigin: function(value) {

        this.crossOrigin = value;
        return this;

    },

    // This will give the quality of the textures to load into memory (string with the file suffixe to load, can be '' to get original texture)
    setQuality: function(value) {
        this.quality = value;
    },

    setMaterialOptions: function(value) {

        this.materialOptions = value;
        return this;

    },

	/**
	 * Parses a MTL file.
	 *
	 * @param {String} text - Content of MTL file
	 * @return {THREE.MTLLoader.MaterialCreator}
	 *
	 * @see setPath setTexturePath
	 *
	 * @note In order for relative texture references to resolve correctly
	 * you must call setPath and/or setTexturePath explicitly prior to parse.
	 */
    parse: function(text) {

        var lines = text.split('\n');
        var info = {};
        var delimiter_pattern = /\s+/;
        var materialsInfo = {};

        for (var i = 0; i < lines.length; i++) {

            var line = lines[i];
            line = line.trim();

            if (line.length === 0 || line.charAt(0) === '#') {

                // Blank line or comment ignore
                continue;

            }

            var pos = line.indexOf(' ');

            var key = (pos >= 0) ? line.substring(0, pos) : line;
            key = key.toLowerCase();

            var value = (pos >= 0) ? line.substring(pos + 1) : '';
            value = value.trim();

            if (key === 'newmtl') {

                // New material

                info = { name: value };
                materialsInfo[value] = info;

            } else if (info) {

                if (key === 'ka' || key === 'kd' || key === 'ks' || key === 'ke') {

                    var ss = value.split(delimiter_pattern, 3);
                    info[key] = [parseFloat(ss[0]), parseFloat(ss[1]), parseFloat(ss[2])];

                } else {

                    info[key] = value;

                }

            }

        }

        var materialCreator = new SAVANE.MTLLoader.MaterialCreator(this.texturePath || this.path, this.materialOptions);
        materialCreator.setCrossOrigin(this.crossOrigin);
        materialCreator.setManager(this.manager);
        materialCreator.setMaterials(materialsInfo);
        // Added to manage material quality
        materialCreator.setMaterialQuality(this.quality)
        return materialCreator;

    }

};

/**
 * Create a new THREE-MTLLoader.MaterialCreator
 * @param baseUrl - Url relative to which textures are loaded
 * @param options - Set of options on how to construct the materials
 *                  side: Which side to apply the material
 *                        THREE.FrontSide (default), THREE.BackSide, THREE.DoubleSide
 *                  wrap: What type of wrapping to apply for textures
 *                        THREE.RepeatWrapping (default), THREE.ClampToEdgeWrapping, THREE.MirroredRepeatWrapping
 *                  normalizeRGB: RGBs need to be normalized to 0-1 from 0-255
 *                                Default: false, assumed to be already normalized
 *                  ignoreZeroRGBs: Ignore values of RGBs (Ka,Kd,Ks) that are all 0's
 *                                  Default: false
 * @constructor
 */

SAVANE.MTLLoader.MaterialCreator = function(baseUrl, options) {

    this.baseUrl = baseUrl || '';
    this.options = options;
    this.materialsInfo = {};
    this.materials = {};
    this.materialsArray = [];
    this.nameLookup = {};

    this.side = (this.options && this.options.side) ? this.options.side : THREE.FrontSide;
    this.wrap = (this.options && this.options.wrap) ? this.options.wrap : THREE.RepeatWrapping;

};

SAVANE.MTLLoader.MaterialCreator.prototype = {

    constructor: SAVANE.MTLLoader.MaterialCreator,

    crossOrigin: 'Anonymous',

    setCrossOrigin: function(value) {

        this.crossOrigin = value;

    },

    setManager: function(value) {

        this.manager = value;

    },

    setMaterials: function(materialsInfo) {

        this.materialsInfo = this.convert(materialsInfo);
        this.materials = {};
        this.materialsArray = [];
        this.nameLookup = {};

    },

    // Added function to manager material quality
    setMaterialQuality: function(materialQuality) {
        this.materialQuality = materialQuality;
    },

    convert: function(materialsInfo) {

        if (!this.options) return materialsInfo;

        var converted = {};

        for (var mn in materialsInfo) {

            // Convert materials info into normalized form based on options

            var mat = materialsInfo[mn];

            var covmat = {};

            converted[mn] = covmat;

            for (var prop in mat) {

                var save = true;
                var value = mat[prop];
                var lprop = prop.toLowerCase();

                switch (lprop) {

                    case 'kd':
                    case 'ka':
                    case 'ks':

                        // Diffuse color (color under white light) using RGB values

                        if (this.options && this.options.normalizeRGB) {

                            value = [value[0] / 255, value[1] / 255, value[2] / 255];

                        }

                        if (this.options && this.options.ignoreZeroRGBs) {

                            if (value[0] === 0 && value[1] === 0 && value[2] === 0) {

                                // ignore

                                save = false;

                            }

                        }

                        break;

                    default:

                        break;

                }

                if (save) {

                    covmat[lprop] = value;

                }

            }

        }

        return converted;

    },

    preload: function(images) {

        for (var mn in this.materialsInfo) {

            this.create(mn, images);

        }

    },

    getIndex: function(materialName) {

        return this.nameLookup[materialName];

    },

    getAsArray: function() {

        var index = 0;

        for (var mn in this.materialsInfo) {

            this.materialsArray[index] = this.create(mn);
            this.nameLookup[mn] = index;
            index++;

        }

        return this.materialsArray;

    },

    create: function(materialName, images) {

        if (this.materials[materialName] === undefined) {

            this.createMaterial_(materialName, images);

        }

        return this.materials[materialName];

    },

    createMaterial_: function(materialName, images) {

        // Create material

        var scope = this;
        var mat = this.materialsInfo[materialName];
        var params = {
            name: materialName,
            side: this.side,
            dithering: true,
            reflectivity: 0
        };

        function resolveURL(baseUrl, url, materialQuality) {

            // Added by ETT for detection chrome/firefox
            var supportWebp = false;

            // Chrome
            if (window.chrome) {
                supportWebp = true;
            }
            // Firefox
            if (typeof InstallTrigger !== 'undefined') {
                supportWebp = true;
            }
            // App electron (i.e. designerApp)
            if (navigator.userAgent.toLowerCase().indexOf('electron') > -1) {
                supportWebp = true;
            }
            // End ETT Addition

            if (typeof url !== 'string' || url === '')
                return '';

            // Absolute URL
            if (/^https?:\/\//i.test(url)) return url;

            // ETT get final string to concat with baseURL to ignore full PC path and add BaseURL instead
            // return baseUrl + url;
            var urlSplit = url.split('\\');
            var filename = urlSplit[urlSplit.length - 1];
            var filenameSplit = filename.split('.');
            var suffixe = '';
            var finalFilename = baseUrl;

            if (materialQuality !== '') {
                suffixe = '_' + materialQuality;
            }

            for (var i = 0; i < filenameSplit.length - 1; i++) {
                finalFilename += filenameSplit[i];
                if (i !== filenameSplit.length - 2) {
                    finalFilename += '.';
                }
            }
            finalFilename += suffixe + '.';

            // If the browser supports webp we redirect to webp ressources
            if (!supportWebp) {
                return (finalFilename + filenameSplit[filenameSplit.length - 1]);
            }
            else {
                return (finalFilename + 'webp');
            }
        }

        function isCompressed(filename) {
            var filenameSplit = filename.split('.');

            if (filenameSplit[filenameSplit.length - 1] === 'dds') {
                return(true);
            }

            return(false);
        }

        function setMapForType(mapType, value, images) {

            if (params[mapType]) return; // Keep the first encountered texture

            var texParams = scope.getTextureParams(value, params);
            var map = null;

            // Search image in image table if available
            if (images && images.length !== 0) {

                for (var i = 0; i < images.length; i++) {
                    // Found, load it from memory
                    if (images[i].name === texParams.url) {
                        if (!isCompressed(images[i].name)) {
                            var image = new Image();

                            map = new THREE.Texture();

                            image.src = images[i].data;

                            image.onload = function() {
                                map.image = image;
                                map.needsUpdate = true;
                                scope.textureLoaded();
                            };
                        }
                        else {
                            var texture = new THREE.DDSLoader().parse(images[i].data);

                            map = new THREE.CompressedTexture();
                            map.width = texture.width;
                            map.height = texture.height;
                            map.format = texture.format;
                            map.mipmaps = texture.mipmaps;
                            map.needsUpdate = true;
                            // Force not to use any mipmap
                            map.minFilter = THREE.LinearFilter;
                            scope.textureLoaded();
                        }
                        break;
                    }
                }

                // Not found, create it from distant server
                if (map == null) {
                    map = scope.loadTexture(resolveURL(scope.baseUrl, texParams.url, scope.materialQuality), undefined, scope.textureLoaded);
                }
            }
            else {
                // No image table, create the texture from distant server
                map = scope.loadTexture(resolveURL(scope.baseUrl, texParams.url, scope.materialQuality), undefined, scope.textureLoaded);
            }

            map.repeat.copy(texParams.scale);
            map.offset.copy(texParams.offset);

            map.wrapS = scope.wrap;
            map.wrapT = scope.wrap;

            if (mapType === "map") {
                map.colorSpace = THREE.SRGBColorSpace;
            }
            params[mapType] = map;
        }

        for (var prop in mat) {

            var value = mat[prop];
            var n;

            if (value === '') continue;

            switch (prop.toLowerCase()) {

                // Ns is material specular exponent

                case 'kd':

                    if (!params['map']) {
                        // Diffuse color (color under white light) using RGB values (3DS export color as linear values)
                        params.color = new THREE.Color().fromArray(value);/*.convertSRGBToLinear();*/
                    }

                    break;

                case 'map_kd':
                case 'map_ka':

                    // Diffuse texture map
                    setMapForType("map", value, images);
                    params.color = new THREE.Color(1, 1, 1);

                    break;

                case 'map_ks':

                    setMapForType("specularMap", value, images);

                    break;

                case 'norm':
                case 'map_bump':
                case 'bump':

                    // Bump texture map

                    setMapForType("bumpMap", value, images);

                    break;

                case 'map_d':

                    // Alpha texture map

                    setMapForType("alphaMap", value, images);
                    params.transparent = true;

                    break

                case 'map_ke':

                    // Emissive texture map

                    setMapForType("emissiveMap", value, images);

                    break

                case 'ns':

                    // The specular exponent (defines the focus of the specular highlight)
					// A high exponent results in a tight, concentrated highlight. Ns values normally range from 0 to 1000.

					params.reflectivity = Math.min(parseFloat( value ), 100) / 100;
                    params.shininess = Math.min(parseFloat( value ), 100);

                    break;

                case 'ni':

                    // IOR - refraction
                    params.refractionRatio = 1.0 / parseFloat(value);
                    break;

                case 'd':
                    n = parseFloat(value);

                    if (n < 1) {

                        params.opacity = Math.max(0.25, n);
                        params.transparent = true;
                        params.depthWrite = false;

                    }

                    break;

                case 'tr':
                    n = parseFloat(value);

                    if (this.options && this.options.invertTrProperty) n = 1 - n;

                    if (n > 0) {
                        // ELMEDHI if ( n < 1 && n!=0 ) {

                        params.opacity = Math.max(0.25, 1 - n);
                        params.transparent = true;
                        params.depthWrite = false;

                    }

                    break;

                default:
                    break;

            }

        }

        this.materials[materialName] = new THREE.MeshPhongMaterial(params);
        if (params.transparent) {
            this.materials[materialName].alwaysTransparent = true;
        }
        return this.materials[materialName];

    },

    getTextureParams: function(value, matParams) {

        var texParams = {

            scale: new THREE.Vector2(1, 1),
            offset: new THREE.Vector2(0, 0),
            bumpScale: new THREE.Vector2(1, 1)

        };

        var items = value.split(/\s+/);
        var pos;

        pos = items.indexOf('-bm');

        if (pos >= 0) {

            matParams.bumpScale = parseFloat(items[pos + 1]);
            items.splice(pos, 2);

        }

        pos = items.indexOf('-s');

        if (pos >= 0) {

            texParams.scale.set(parseFloat(items[pos + 1]), parseFloat(items[pos + 2]));
            items.splice(pos, 4); // we expect 3 parameters here!

        }

        pos = items.indexOf('-o');

        if (pos >= 0) {

            texParams.offset.set(parseFloat(items[pos + 1]), parseFloat(items[pos + 2]));
            items.splice(pos, 4); // we expect 3 parameters here!

        }

        texParams.url = items.join(' ').trim();
        return texParams;

    },

    loadTexture: function(url, mapping, onLoad, onProgress, onError) {

        var texture;
        var manager = (this.manager !== undefined) ? this.manager : THREE.DefaultLoadingManager;
        var loader = manager.getHandler(url);

        if (loader === null) {

            loader = new THREE.TextureLoader(manager);

        }

        if (loader.setCrossOrigin) loader.setCrossOrigin(this.crossOrigin);
        texture = loader.load(url, onLoad, onProgress, onError);

        if (mapping !== undefined) texture.mapping = mapping;

        return texture;

    }
};
